import React from 'react';

class PrivacyAndCookiePolicyPage extends React.Component {

    render() {

        return (

            <div>
                <h5>What personally identifiable information is collected?</h5>
                <p align="justify">
                    No personally identifiable information is collected and stored on the Divolgo mobile app.
                    <br/>
                    Visitors may supply personally identifiable information (such as name, address, email address or
                    telephone numbers) for various purposes on the sports.divolgodigital.com website. These purposes
                    include registering to bid on auctions, requesting further information about our products, or simply
                    asking a question about the Divolgo website at sports.divolgodigital.com. We do not collect personal
                    information from you unless you provide it to us. This means that you can visit our site without
                    telling us who you are or revealing any personally identifiable information about yourself.
                </p>
                <h5>How does this site store your credit card information?</h5>
                <p align="justify">
                    Divolgo will never store your credit card information. All credit card transactions are done through
                    a secure link to an external payment gateway service provider.
                </p>
                <h5>What anonymous information is collected on this site?</h5>
                <p align="justify">
                    Anonymous clickstream information is NOT collected from any visitor to this site. This includes pages
                    viewed, date and time, and browser type. IP numbers are also not stored.
                </p>
                <h5>How does this site use cookies?</h5>
                <p align="justify">
                    Divolgo uses session cookies for its shopping cart when using the website.
                </p>
                <h5>E-mail Links</h5>
                <p align="justify">
                    We use e-mail links located on this site to allow you to contact us directly via e-mail. We use the
                    information provided in your e-mail to respond to your questions or comments. We may also store your
                    comments for future reference. Your personal information is only shared with third parties with your
                    explicit permission.
                </p>
                <h5>For more information</h5>
                <p align="justify">
                    If you have any questions, concerns or comments, please e-mail <t/>
                    <u><a href={"mailto:support@divolgodigital.com"}>
                        support@divolgodigital.com
                    </a></u>
                    . Our mailing address is:<br/><br/>
                    Techlabs Global Private Limited<br/>
                    9B Horton Place<br/>
                    Colombo 7, 00700<br/>
                    Sri Lanka
                </p>
            </div>

        );
    }
}

export default PrivacyAndCookiePolicyPage;