import React from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

const AuctionNotifications = (props) => {

    return (
        <div>
            <Form method="post">
                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={2}/>
                    <Form.Label column sm={1}/>
                    <Form.Label column sm={2}>Web Notification</Form.Label>
                    <Form.Label column sm={2}>Email Notification</Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={2}>Placing a bid</Form.Label>
                    <Form.Label column sm={1}>:</Form.Label>
                    <Col sm={2}>
                        <span className="d-inline-block" data-tip data-for="bidWebTip">
                            <Form.Check
                                type="switch"
                                label=''
                                name="bidWeb"
                                checked
                                disabled
                            />
                        </span>
                        <ReactTooltip id="bidWebTip" place="top" effect="solid">
                            {props.disableNotification}
                        </ReactTooltip>
                    </Col>
                    <Col sm={1}>
                        <span className="d-inline-block" data-tip data-for="bidEmailTip">
                            <Form.Check
                                type="switch"
                                label=''
                                name="bidEmail"
                                disabled
                            />
                        </span>
                        <ReactTooltip id="bidEmailTip" place="top" effect="solid">
                            {props.enableNotification}
                        </ReactTooltip>
                    </Col>
                    <Col>
                        <FontAwesomeIcon icon="info-circle" data-tip data-for="bidEmailTip2"/>
                        <ReactTooltip id="bidEmailTip2" place="top" effect="solid">
                            By enabling this, You will receive an email when you place a bid on a product.
                        </ReactTooltip>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>Placing an auto bid</Form.Label>
                    <Form.Label column sm={1}>:</Form.Label>
                    <Col sm={2}>
                        <span className="d-inline-block" data-tip data-for="autoBidWebTip">
                            <Form.Check
                                type="switch"
                                label=''
                                name="autoBidWeb"
                                disabled
                            />
                        </span>
                        <ReactTooltip id="autoBidWebTip" place="top" effect="solid">
                            {props.enableNotification}
                        </ReactTooltip>
                    </Col>
                    <Col sm={1}>
                        <span className="d-inline-block" data-tip data-for="autoBidEmailTip">
                            <Form.Check
                                type="switch"
                                label=''
                                name="autoBidEmail"
                                disabled
                            />
                        </span>
                        <ReactTooltip id="autoBidEmailTip" place="top" effect="solid">
                            {props.enableNotification}
                        </ReactTooltip>
                    </Col>
                    <Col>
                        <FontAwesomeIcon icon="info-circle" data-tip data-for="autoBidEmailTip2"/>
                        <ReactTooltip id="autoBidEmailTip2" place="top" effect="solid">
                            By enabling this, You will receive an email when placing an auto bid on a product.
                        </ReactTooltip>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={2}>OverBid</Form.Label>
                    <Form.Label column sm={1}>:</Form.Label>
                    <Col sm={2}>
                        <span className="d-inline-block" data-tip data-for="overBidWebTip">
                            <Form.Check
                                type="switch"
                                label=''
                                name="overBidWeb"
                                disabled
                            />
                        </span>
                        <ReactTooltip id="overBidWebTip" place="top" effect="solid">
                            {props.enableNotification}
                        </ReactTooltip>
                    </Col>
                    <Col sm={1}>
                        <span className="d-inline-block" data-tip data-for="overBidEmailTip">
                           <Form.Check
                               type="switch"
                               label=''
                               name="overBidEmail"
                               disabled
                           />
                        </span>
                        <ReactTooltip id="overBidEmailTip" place="top" effect="solid">
                            {props.enableNotification}
                        </ReactTooltip>
                    </Col>
                    <Col>
                        <FontAwesomeIcon icon="info-circle" data-tip data-for="overBidEmailTip2"/>
                        <ReactTooltip id="overBidEmailTip2" place="top" effect="solid">
                            By enabling this, You will receive an email when there is a bid over your bid.
                        </ReactTooltip>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={2}>Over bid for an auto bid</Form.Label>
                    <Form.Label column sm={1}>:</Form.Label>
                    <Col sm={2}>
                        <span className="d-inline-block" data-tip data-for="overBidAutoWebTip">
                            <Form.Check
                                type="switch"
                                label=''
                                name="overBidAutoWeb"
                                disabled
                            />
                        </span>
                        <ReactTooltip id="overBidAutoWebTip" place="top" effect="solid">
                            {props.enableNotification}
                        </ReactTooltip>
                    </Col>
                    <Col sm={1}>
                        <span className="d-inline-block" data-tip data-for="overBidAutoEmailTip">
                            <Form.Check
                                type="switch"
                                label=''
                                name="overBidAutoEmail"
                                disabled
                            />
                        </span>
                        <ReactTooltip id="overBidAutoEmailTip" place="top" effect="solid">
                            {props.enableNotification}
                        </ReactTooltip>
                    </Col>
                    <Col>
                        <FontAwesomeIcon icon="info-circle" data-tip data-for="overBidAutoEmailTip2"/>
                        <ReactTooltip id="overBidAutoEmailTip2" place="top" effect="solid">
                            By enabling this, You will receive an email when there is a bid over your auto bid.
                        </ReactTooltip>
                    </Col>
                </Form.Group>
            </Form>
        </div>
    );
}

export default AuctionNotifications;