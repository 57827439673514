import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {NotificationManager} from 'react-notifications';
import BlockUi from '../common/BlockUi';

const Popup = (props) => {
    const {
        opener,
        className,
        title,
        body,
        size,
        centered,
        backdrop,
        popup,
        hidePrimaryBtn
    } = props;

    const [modal, setModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const toggle = () => setModal(!modal);

    const isPromise = (p) => {
        return p && Object.prototype.toString.call(p) === "[object Promise]";
    }

    const primaryAction = () => {
        let p;
        if(title === "Change Password") {
           p = popup.primaryBtn.action && popup.primaryBtn.action(body.props.formData);
        } else if (title === "Forgot Password") {
            p = popup.primaryBtn.action && popup.primaryBtn.action(body.props.userEmail);
        } else {
            p = popup.primaryBtn.action && popup.primaryBtn.action();
        }

        isPromise(p) && setIsLoading(true);

        isPromise(p) && p.then(result => {
            if (!result.error) {
                NotificationManager.success(result.message || "Successfully saved.");
                if(popup.activeTab === '3') {
                    popup.getPendingImages();
                    popup.setPrimaryBtnText('');
                    popup.setActiveTab('2');
                }
                else {
                    toggle();
                }
            }
        }).catch(err => {
            console.error(err.response || err);
            NotificationManager.error(err.response && err.response.data.message || err || 'An error occurred.');
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const handleEnterKeyPress = (event) => {
        if(event.which === 13) {
            primaryAction();
        }
    }

    return (
        <>
            <span onClick={toggle}>{opener}</span>

            <div>
                <Modal size={size || 'lg'}  isOpen={modal} toggle={toggle} className={className}  centered={centered} backdrop={backdrop}>
                    <ModalHeader toggle={toggle}>{title}</ModalHeader>
                    <ModalBody onKeyPress={handleEnterKeyPress}>
                        {body}
                    </ModalBody>
                    <BlockUi className="" tag="div" blocking={isLoading} message={"Please wait"}>
                        <ModalFooter>
                            {hidePrimaryBtn ? '' : popup.primaryBtnText !== '' && (<Button color="primary" onClick={() => {primaryAction();toggle()}}>{popup.primaryBtnText}</Button>)}{' '}
                            <Button color="secondary" onClick={toggle}>{popup.secondaryBtnText}</Button>
                        </ModalFooter>
                    </BlockUi>
                </Modal>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        popup: state.popup
    }
}

export default connect(mapStateToProps, null)(Popup);