import React, {useEffect, useState} from 'react';
import {Col, Card, CardImg, CardBody, CardTitle, Label} from "reactstrap";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { RiDeleteBinLine } from "react-icons/ri";
import { ROUTE_BRANDS } from '../../../constants/RouteConstants';
import { useLocation } from 'react-router-dom'; 
import './BrandImageCard.scss';

const BrandImageCard = (props) => {
    const location = useLocation();
    const [showConfirm, setShowConfirm] = useState(false); 

    useEffect(() => {
        props.defaultImage > 0 && props.handleChange(props.defaultImage);
    }, [props.defaultImage]);

    return (
        <Col xs="6" sm="4" md="3">
            <Card  color={props.defaultImage == props.value ? "info" : ''} style={{ border: '1px solid #4B5563' }} >
                <CardImg top width="100%" src={props.imgPath}  style={{ borderBottom: '1px solid #4B5563' }}/>
                {
                    <CardBody>
                        <CardTitle>
                            <div className='select-as-default' style={{ display: 'inline-flex', alignItems: 'center' }}>
                                {props.flag == 'approved' &&
                                    <FormControlLabel value="female"
                                                control={<Radio  name="test" value={props.value}
                                                            checked={props.value == props.defaultImage}
                                                            onChange = {() => props.handleChange(props.value)}/>}
                                                label="Select as default"
                                    />
                                }
                                {props.flag !== 'rejected' && showConfirm === false && location.pathname == ROUTE_BRANDS &&
                                    <button className='new_icon' type="button" onClick={() => setShowConfirm(true)}>
                                        <RiDeleteBinLine className='remove' />
                                    </button>
                                }
                            </div>
                        {props.flag == 'rejected' && props.rejectReason &&
                            <Label className="reject-reason">Reject reason: <br/><span className="text-danger">{props.rejectReason.label}</span></Label>
                        }
                        {props.flag === 'rejected' && showConfirm === false && location.pathname == ROUTE_BRANDS &&
                            <button className='new_icon' type="button" onClick={() => setShowConfirm(true)}>
                                <RiDeleteBinLine className='remove' />
                            </button>
                        }
                         {showConfirm === true && location.pathname == ROUTE_BRANDS && (
                            <div className="confirm-container">
                                <div className="confirm-container-text">Confirm to remove this image</div>
                                <div className="confirm-buttons-container">
                                    <button className='new_icon' type="button" 
                                        onClick={() => {
                                            props.handleDelete(props.value)
                                            setShowConfirm(false)
                                        }}>
                                        Confirm
                                    </button>
                                    <button className='new_icon' type="button" onClick={() => setShowConfirm(false)}>
                                        Cancel
                                    </button>
                                </div> 
                            </div>
                        )}
                        </CardTitle>
                    </CardBody>
                }
            </Card>
        </Col>
    )
}

export default BrandImageCard;