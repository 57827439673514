import initialState from './InitialState';
import * as types from '../../actions/ActionTypes';

const PaginationReducer = (state =  initialState.pagination, action) => {
    switch (action.type) {

        case types.PAGINATE_PRODUCT:
            return {...state, ...action.data}

        default:
            return state;
    }
}

export default PaginationReducer;