import React, {useState} from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import BusinessInformation from "./BusinessInformation";
import AuthorizedContact from "./AuthorizedContact";

const   AccountInformation = (props) => {

    const [key, setKey] = useState(props.location.activeTab || 1);

    return (
        <>
            <Tabs
                id="accountInformation"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="1" title="Business Information">
                    <BusinessInformation/>
                </Tab>
                <Tab eventKey="2" title="Authorized Contact Information">
                    <AuthorizedContact/>
                </Tab>
            </Tabs>
        </>
            )
}

export default AccountInformation;