import React from 'react';
import {Form, Col, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

const Preferences = (props) => {

    return (
        <Form method="post">

            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>Display Currency</Form.Label>
                <Form.Label column sm={1}>:</Form.Label>
                <Col sm={3} data-tip data-for="currencyTip">
                    <Form.Control as="select" disabled name="currency" type="select">
                        <option value="usd">United States Dollar ($)</option>
                    </Form.Control>
                    <ReactTooltip id="currencyTip" place="top" effect="solid">
                        Changing the display currency is not available at the moment    
                    </ReactTooltip>
                </Col>
                <Col>
                    <FontAwesomeIcon icon="info-circle" data-tip data-for="currencyTip2"/>
                    <ReactTooltip id="currencyTip2" place="top" effect="solid">
                        Display currency is the currency you can see in the Products, Cart, Orders, Invoices, Emails, etc. But all the transactions will be done using USD.
                    </ReactTooltip>
                </Col>

            </Form.Group>

            {/*please don't remove this.*/}
            {/*Commented this block because we don't need this option until we change the language to english-us in the whole application.*/}

            {/*<Form.Group className="form-group" className="mb-3">*/}
            {/*    <Form.Label column sm={2}>Language</Form.Label>*/}
            {/*    <Form.Label column sm={1}>:</Form.Label>*/}
            {/*    <Col sm={3} data-tip data-for="languageTip">*/}
            {/*        <Form.Control as="select" disabled name="language" type="select">*/}
            {/*            <option value="en-us">English (US)</option>*/}
            {/*        </Form.Control>*/}
            {/*        <ReactTooltip id="languageTip" place="top" effect="solid">*/}
            {/*            Changing the Language is not available at the moment.*/}
            {/*        </ReactTooltip>*/}
            {/*    </Col>*/}
            {/*    <Col>*/}
            {/*        <FontAwesomeIcon icon="info-circle" data-tip data-for="languageTip2"/>*/}
            {/*        <ReactTooltip id="languageTip2" place="top" effect="solid">*/}
            {/*            Language is the preferred language of the storefront application. All the text on the application will be translated to your preferred language.*/}
            {/*        </ReactTooltip>*/}
            {/*    </Col>*/}
            {/*    <ReactTooltip/>*/}
            {/*</Form.Group>*/}

            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={2}>Display Time Zone</Form.Label>
                <Form.Label column sm={1}>:</Form.Label>
                <Col sm={3} data-tip data-for="timeZoneTip">
                    <Form.Control as="select" disabled name="timeZone" type="select">
                        <option value="usd">Current Time Zone</option>
                    </Form.Control>
                    <ReactTooltip id="timeZoneTip" place="top" effect="solid">
                        Changing the Display Time Zone is not available at the moment.
                    </ReactTooltip>
                </Col>
                <Col>
                    <FontAwesomeIcon icon="info-circle" data-tip data-for="timeZoneTip2"/>
                    <ReactTooltip id="timeZoneTip2" place="top" effect="solid">
                        Display Time Zone is the time zone used in the storefront application.
                    </ReactTooltip>
                </Col>
             <ReactTooltip/>
            </Form.Group>
        </Form>
    )
}

export default Preferences;