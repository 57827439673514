import React, {useEffect, useState} from 'react';
import Modal from '../../../common/Popup';
import ManageImage from '../../../pages/logo/ManageBrands';
import {connect} from 'react-redux';
import {Button} from 'reactstrap';
import  errorImage from '../../../../assets/error_brand_image.png';
import {IMAGE_DEFAULT, IMAGE_APPROVED, IMAGE_PENDING, IMAGE_REJECTED, IMAGE_ERROR} from '../../../../constants';
import './DefaultImage.scss';
import BrandsApi from '../../../../api/Brands';
import { useHistory } from 'react-router-dom';


const DefaultImage = (props) => {

    const [defaultImg, setDefaultImg] = useState('');
    const currentDefaultImg = props.user.settings.brandImage  || '';
    const imgStatus = props.user.settings.brandImageStatus;
    const[currentBrand, setCurrentBrand] = useState('');
    const[approvedImages, SetApprovedImages] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setDefaultImg(currentDefaultImg);
        getBrandNames();
    }, [currentDefaultImg])

    const btnUpdate = (
        <div id="btn-upload-brandimg-wrap">{
                <>
                    <Button color="dark">    
                        Change Brand
                    </Button>
                </>
        }
        </div>
    )

    const handleButtonClick = () => {
        history.push('/brands');
      };

    const getBrandNames = () => {
        BrandsApi.getBrands().then(response => {
            const extractedBrands = response.data.data.map(brand => ({
                id: brand.brand.id,
                name: brand.brand.brandName,
                isDefault: brand.brand.isDefault,
                imageStatus: brand.imageStatus
            }));
            
            const defaultBrand = extractedBrands.find(brand => brand.isDefault === 1);
            const brandsWithApproved = extractedBrands.find(brand => brand.imageStatus === 1 || brand.imageStatus === 2);

     if (brandsWithApproved) {
                SetApprovedImages(true)
            }
            if (defaultBrand) {
                setCurrentBrand(defaultBrand.name);
            }

        }).catch(err => {

        });     
    }

    return (
        <>
            <div className="default-image section-filter"
                style={imgStatus === IMAGE_DEFAULT ? {paddingTop:25} : {paddingTop:0} }
            >
                {(currentDefaultImg !== '' || approvedImages) && <div className='current-brand-text'>Current Brand : {currentBrand}</div>}
                {currentDefaultImg !== '' && <div className='brand-text'>Default Image</div>}

                {/* {(imgStatus > IMAGE_DEFAULT && imgStatus !== IMAGE_ERROR) ?
                    <div id="img-status">
                        {imgStatus === IMAGE_APPROVED ? "Image Approved" :
                            imgStatus === IMAGE_PENDING ? "Pending Approval" :
                                "Image Rejected"}
                    </div> : <></>
                } */}
                <div className="default-image-wrap">
                    <div className="img-block">
                        <img
                            width="180"
                            className={imgStatus > IMAGE_DEFAULT &&
                            imgStatus !== IMAGE_ERROR ?
                                "pending-img" : null
                            }
                            src={imgStatus !== IMAGE_ERROR ?
                                defaultImg : errorImage
                            }
                        />
                        
                    </div>
                </div>

                {currentDefaultImg !== '' || approvedImages? (
                <div className="manage-button-container">
                    <Modal
                        size="xl"
                        title="Manage Brands"
                        opener={btnUpdate}
                        body={<ManageImage />}
                    />
                </div>
                ) : (
                <div className="manage-button-container" onClick={handleButtonClick}>
                    <Button color="dark">Manage Brands</Button>
                </div>
                )}
                
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: state.UserReducer
    }
}

export default connect(mapStateToProps, null)(DefaultImage);