import React from 'react';
import {AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

class Grid extends React.Component {

    render() {
        return(
            <div
                className="ag-theme-alpine"
                style={{
                    height: this.props.height || '400px',
                    width: this.props.width || 'auto'
                }}
            >
                <AgGridReact {...this.props}/>
            </div>
        );
    }
}
export default Grid;