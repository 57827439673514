import React, {useState, useEffect} from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import Settings from "./Settings";
import Preferences from "./Preferences";

const SettingsPreferences = (props) => {

    const [key, setKey] = useState(props.location.activeTab || 1);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])

    return (
        <>
            <Tabs
                id="settingsPreferences"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="1" title="Settings">
                    <Settings/>
                </Tab>
                <Tab eventKey="2" title="Preferences">
                    <Preferences/>
                </Tab>
            </Tabs>
        </>
            )
}

export default SettingsPreferences;