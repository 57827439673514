import React from 'react';
import Blockui from 'react-block-ui';
import 'react-block-ui/style.css';
import './BlockUi.scss';

const BlockUi = (props) => {
    return (
        <Blockui {...props}/>
    )
}

export default BlockUi;