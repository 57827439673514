import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
}))

const Paginator = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Pagination page={props.page || 1} count={props.pageCount || 0} onChange={props.onChange} variant="outlined" shape="rounded" />
        </div>
    )

};

export default Paginator;