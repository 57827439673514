import React from 'react';
import {Form, Button} from 'react-bootstrap';
import AccountSettingsApi from '../../../api/AccountSettings';
import {NotificationManager} from 'react-notifications';
import SimpleReactValidator from 'simple-react-validator';
import * as Constants from '../../../constants';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'

class AuthorizedContact extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formData: {
                title: "Mr",
                firstName: '',
                lastName: '',
                designation: '',
                contactNo: ''
            },
            savedData: {}
        }

        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            validators: {
                nullValidate: {
                    message: "You can’t enter 'NULL' as value.",
                    rule: (val) => {
                        return (val.toLowerCase() !== "null");
                    },
                    required: true
                },
                firstLengthValidate: {
                    message: "Provided first name is too long. Please enter a first name less than " + Constants.FIELD_LENGTH + " characters.",
                    rule: (val) => {
                        return (val.length <= Constants.FIELD_LENGTH);
                    },
                },
                lastLengthValidate: {
                    message: "Provided last name is too long. Please enter a last name less than " + Constants.FIELD_LENGTH + " characters.",
                    rule: (val) => {
                        return (val.length <= Constants.FIELD_LENGTH);
                    },
                },
                designationLengthValidate: {
                    message: "Provided designation is too long. Please enter a designation less than " + Constants.FIELD_LENGTH + " characters.",
                    rule: (val) => {
                        return (val.length <= Constants.FIELD_LENGTH);
                    },
                },
            }
        });
    }

    componentDidMount() {
        this.loadSavedData();
        window.scrollTo(0, 0)
    }

    loadSavedData = () => {
        AccountSettingsApi.getCustomerData().then(response => {
            const data = response.data || {};

            if (data) {
                if (data.authorizedContactInformation) {
                    let formData = {
                        title: data.authorizedContactInformation.title,
                        firstName: data.authorizedContactInformation.firstName,
                        lastName: data.authorizedContactInformation.lastName,
                        designation: data.authorizedContactInformation.designation,
                        contactNo: data.authorizedContactInformation.contactNo
                    }
                    this.setState({formData: {...this.state.formData, ...formData}});
                }
            }

        }).catch(err => {
            console.error(err);
        });
    }

    handleChange = (event) => {
        this.setState({formData: {...this.state.formData, [event.target.name]: event.target.value}});
    }

    handleChangeContactNo = value => {
        this.setState({formData: {...this.state.formData, contactNo: value}});
    };

    handleSubmit = (event) => {
        event.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        AccountSettingsApi.updateAuthorizedContactInformation(this.state.formData).then(response => {
            const data = response.data || {};

            if (data && data.success === 1) {
                NotificationManager.success(data.message);
            } else {
                NotificationManager.error(data.message);
            }
        }).catch(err => {
            NotificationManager.error(err.response.data.message || "An error occurred");
        });
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit} method="post">
                <Form.Group className="form-group">
                    <Form.Label>Title <span className="requiredStar">*</span></Form.Label>
                    <Form.Control as="select" name="title" type="select" onChange={this.handleChange} value={this.state.formData.title}>
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                    </Form.Control>
                    <Form.Text className="text-danger">
                        {this.validator.message('title', this.state.formData.title, 'required')}
                    </Form.Text>
                </Form.Group>

                <Form.Group className="form-group">
                    <Form.Label>First Name <span className="requiredStar">*</span></Form.Label>
                    <Form.Control name="firstName" type="text" onChange={this.handleChange} value={this.state.formData.firstName}/>
                    <Form.Text className="text-danger">
                        {this.validator.message('firstName', this.state.formData.firstName, 'required|alpha_space|firstLengthValidate|nullValidate')}
                    </Form.Text>
                </Form.Group>

                <Form.Group className="form-group">
                    <Form.Label>Last Name <span className="requiredStar">*</span></Form.Label>
                    <Form.Control name="lastName" type="text" onChange={this.handleChange} value={this.state.formData.lastName}/>
                    <Form.Text className="text-danger">
                        {this.validator.message('lastName', this.state.formData.lastName, 'required|alpha_space|lastLengthValidate|nullValidate')}
                    </Form.Text>
                </Form.Group>

                <Form.Group className="form-group">
                    <Form.Label>Designation <span className="requiredStar">*</span></Form.Label>
                    <Form.Control name="designation" type="text" onChange={this.handleChange} value={this.state.formData.designation}/>
                    <Form.Text className="text-danger">
                        {this.validator.message('designation', this.state.formData.designation, 'required|alpha_space|designationLengthValidate|nullValidate')}
                    </Form.Text>
                </Form.Group>

                <Form.Group className="form-group">
                    <Form.Label>Contact No <span className="requiredStar">*</span></Form.Label>
                    <Form.Group>
                        <ReactPhoneInput
                            inputExtraProps={{
                                name: "contactNo",
                                required: true,
                                autoFocus: true,
                                padding: '8px'
                            }}
                            country='us'
                            value={this.state.formData.contactNo}
                            onChange={this.handleChangeContactNo}
                            inputStyle={{width: '100%'}}/>
                    </Form.Group>
                </Form.Group>

                <Form.Group className="form-group">
                    <Button variant="primary" type="submit" className="float-end" disabled={!this.state.formData.firstName || !this.state.formData.lastName || !this.state.formData.designation || !this.state.formData.contactNo}>Save</Button>
                </Form.Group>

                <Form.Group className="form-group"><br/></Form.Group>
            </Form>
        )
    }
}

export default AuthorizedContact;