import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { RiDeleteBinLine } from "react-icons/ri";
import ManageImage from '../pages/logo/MangeBrandImage';
import Modal from '../common/Popup';
import AddBrand from '../common/AddBrands';
import './BrandsPage.scss';
import BrandsApi from '../../api/Brands';
import { NotificationManager } from 'react-notifications';
import { BRAND_IMAGE_STATUS } from '../../constants';
import ConfirmationPopup from '../common/ConfirmationPopup';

class BrandsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDialogOpen: false,
            manageImageOpen: false,
            availableBrands: [],
            availableBrandNames: [],
            showConfirmationModal: false,
            brandToRemoveId: null,
            brandToRemoveIsDefault: null,
        };
    }

    componentDidMount() {
        this.getBrandNames();
    }

    getBrandNames = () => {
        BrandsApi.getBrands().then(response => {
            const extractedBrands = response.data.data.map(brand => ({
                id: brand.brand.id,
                name: brand.brand.brandName,
                isDefault: brand.brand.isDefault,
                description: brand.brand.description,
                defaultImage: brand.defaultBrandImage ? brand.defaultBrandImage.bwImagePath : null,
                imageStatus: brand.imageStatus
            }));
            this.setState({ availableBrands: extractedBrands });
            const extractedBrandNames = extractedBrands.map(brand => brand.name);
            this.setState({ availableBrandNames: extractedBrandNames });
        }).catch(err => {
            NotificationManager.error('Something went wrong while fetching brands.');
        });
    }

    removeBrand = (brandId, isDefault) => {
        if (brandId) {
            if (isDefault == 1) {
                NotificationManager.info('You are trying to remove default brand please change it before removing.');
                this.closeConfirmationModal();
                return
            }

            BrandsApi.deleteBrand(brandId).then(response => {

                const res = response.data;

                if (res && res.success === 1) {
                    NotificationManager.success('Brand removed successfully!');
                    this.getBrandNames()
                } else {
                    NotificationManager.error(res.message);
                }
            }).catch(err => {
                NotificationManager.error('Something went wrong while deleting the brand.');
            });

            this.closeConfirmationModal();
        }
    };

    handleBrandAdded = (newBrandName) => {
        this.getBrandNames();
    };

    handleChangeDefault = () => {
        this.getBrandNames();
    };

    openDialog = () => {
        this.setState({ isDialogOpen: true });
    };

    closeDialog = () => {
        this.setState({ isDialogOpen: false });
        // this.getBrandNames();
    };

    handleOpenManageImage = () => {
        this.setState({ manageImageOpen: true });
    };

    handleCloseManageImage = () => {
        this.setState({ manageImageOpen: false });
    };

    openConfirmationModal = (brandId, isDefault) => {
        this.setState({ showConfirmationModal: true, brandToRemoveId: brandId, brandToRemoveIsDefault: isDefault });
    };

    closeConfirmationModal = () => {
        this.setState({ showConfirmationModal: false, brandToRemoveId: null });
    };

    render() {
        const { isDialogOpen, manageImageOpen } = this.state;

        return (
            <div>
                <button className='new_button' onClick={this.openDialog} style={{ marginLeft: 'auto', marginBottom: '10px' }}>
                    Add new brand
                </button>
                <AddBrand
                    open={isDialogOpen}
                    brandNames={this.state.availableBrandNames}
                    onClose={this.closeDialog}
                    onBrandAdded={this.handleBrandAdded}
                />
                <ConfirmationPopup
                    show={this.state.showConfirmationModal}
                    onClose={this.closeConfirmationModal}
                    onConfirm={() => this.removeBrand(this.state.brandToRemoveId, this.state.brandToRemoveIsDefault)}
                />
                <table className="table table-borderless order-items-table">
                    <thead className='thead-light'>
                        <tr>
                            <th scope="col">Brand</th>
                            <th scope="col">Description</th>
                            <th scope="col">Default Image</th>
                            <th className="text-end" style={{ paddingRight: '100px' }} scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.availableBrands.map((item, key) => {
                                return <tr key={key}>
                                    <td>
                                        {item.name ? (
                                            <div>
                                                {item.name}
                                            </div>
                                        ) : (
                                            <div style={{ paddingLeft: '30px' }}>
                                                N/A
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        {item.description ? (
                                            <div>
                                                {item.description}
                                            </div>
                                        ) : (
                                            <div style={{ paddingLeft: '30px' }}>
                                                N/A
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        {item.defaultImage ? (
                                            <div>
                                                <img src={item.defaultImage} height="60" alt='product image' />
                                            </div>
                                        ) : (
                                            <div>
                                                {item.imageStatus !== 3
                                                    ? (
                                                        <Modal
                                                            size="xl"
                                                            title="Manage Images"
                                                            opener={<button className='upload_button' onClick={this.handleOpenManageImage}>{BRAND_IMAGE_STATUS[item.imageStatus]}</button>}
                                                            body={<ManageImage
                                                                onClose={this.handleCloseManageImage}
                                                                neededBrandId={item.id}
                                                                neededBrandName={item.name}
                                                                defaultTab={item.imageStatus !== 2 ? 1 : 3}
                                                            />}
                                                        />
                                                    )
                                                    : BRAND_IMAGE_STATUS[item.imageStatus]}
                                            </div>

                                        )}
                                    </td>
                                    <td className="text-end" style={{ display: 'flex' }}>
                                        <div style={{ marginLeft: 'auto' }}>
                                            <Modal
                                                size="xl"
                                                title="Manage Images"
                                                opener={<button className='manage_button' onClick={this.handleOpenManageImage}>Manage Image</button>}
                                                body={<ManageImage
                                                    onClose={this.handleCloseManageImage}
                                                    onChangeDefault={this.handleChangeDefault}
                                                    neededBrandId={item.id}
                                                    neededBrandName={item.name}
                                                />}
                                            />
                                        </div>
                                        <button className='new_icon' type="button" onClick={() => this.openConfirmationModal(item.id, item.isDefault)}>
                                            <RiDeleteBinLine className='remove' />&nbsp; Remove
                                        </button>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default BrandsPage;
