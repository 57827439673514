import React from "react";
import NumberFormat from "react-number-format";

const PriceFormat = (props) => {

    return (
        <NumberFormat
            value={parseFloat(props.price).toFixed(2)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            className="priceFormat"
        />
    )
}
export default PriceFormat;