import * as types from './ActionTypes';
import CartApi from '../api/Cart';
import {inertServerErrorToStore} from './common/ErrorActions';
import {NotificationManager} from "react-notifications";

//add cart action
export const addToCart = (item, qty) => {
    return {
        type: types.ADD_TO_CART,
        item, qty
    }
}
//remove item action
export const removeItem = (id, defaultImageId) => {
    return {
        type: types.REMOVE_ITEM,
        id, defaultImageId
    }
}
export const changeQty = (id, qty, defaultImageId) => {
    return {
        type: types.CHANGE_QTY,
        id, qty, defaultImageId
    }
}

export const reloadCart = (item) => {
    return {
        type: types.RELOAD_CART,
        item
    }
}

export const checkout = (cart) => {

    let data = prepareCheckoutData(cart);

    return (dispatch) => {
        dispatch(setIsLoading(true));
        CartApi.checkout(data).then(response => {
            let cartData = response.data;

            if (cartData.success === 1) {
                dispatch(checkoutSuccess(cartData));
            } else {
                dispatch(checkoutError(cartData));
                NotificationManager.error(cartData.message);
            }
        }).catch(err => {
            let message = "An error occurred while your are trying proceed checkout.";
            console.error(err);
            NotificationManager.error(message);
            dispatch(inertServerErrorToStore({msg: message}));
        }).finally(() => {
            dispatch(setIsLoading(false));
        });
    }

    function prepareCheckoutData(cart) {
        let data = {
            total: parseFloat(cart.total),
            cartItems: []
        }

        cart.items.map((item, key) => {
            let price = parseFloat(item.cartQty * item.price);
            data.cartItems.push({productId: item.id, qty: item.cartQty, price, brandImageId: item.defaultImageId});
        });

        return data;
    }
}

export const checkoutSuccess = (cart) => {
    return {
        type: types.CHECKOUT_SUCCESS, ...cart
    }
}

export const checkoutError = (cart) => {
    return {
        type: types.CHECKOUT_ERROR, ...cart
    }
}

export const setIsLoading = (status) => {
    return {
        type: types.SET_IS_LOADING, status
    }
}
