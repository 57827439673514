import axios from 'axios';
import {API_BASE_URL, API_ENDPOINT_PREFIX} from '../constants';
import httpHeaders from '../utils/httpHeaders';

const URL = API_BASE_URL + API_ENDPOINT_PREFIX;

class User {

    signup = (data) => {
        let url = URL + '/customer/register';
        return axios.post(url, data);
    }

    login = (data) => {
        let url = API_BASE_URL + '/user/auth/customer';
        return axios.post(url, data);
    }

    changePassword = (data) => {
        let url = URL + '/customer/settings/change-password';
        return axios.post(url, data, httpHeaders.authHeader());
    }

    logout = () => {
        console.error("Logout API not implemented.");
    }

    settings = (authToken) => {
        let url = URL + '/customer/settings';
        return axios.get(url, httpHeaders.authHeader());
    }
}

export default new User();