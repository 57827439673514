import React from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
import Page from "./page/Page";
import PageHeader from "./page/PageHeader";
import {NotificationContainer} from "react-notifications";
import "react-notifications/lib/notifications.css";
import "./Layout.scss";
import Footer from "./footer/Footer";
import {Row, Col} from "reactstrap";

const Layout = (props) => {

    const pageClass = props.noSidebar
        ? "col-main col-sm-12 col-sm-push-3"
        : "col-main col-sm-9 col-sm-push-3";

    // Redirect if not logged in.
    if (props.requireAuth && !props.user.isLoggedIn) {
        return <Redirect to="/login"/>;
    }

    return (
        <>
            <Header/>
            <PageHeader>{props.pageTitle}</PageHeader>
            <section className="main-container col2-left-layout bounceInUp animated">
                <div className="container-fluid">
                    <Row>
                        {!props.noSidebar && (
                            <Sidebar isLoggedIn={props.user.isLoggedIn}>
                                {props.sidebar}
                            </Sidebar>
                        )}

                        <Col className='page-container-div' xs={12} sm={12} md={8} lg={8} xl={9}>
                            <Page>{props.component}</Page>
                        </Col>
                    </Row>
                </div>
            </section>
            <Footer/>
            <NotificationContainer/>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.UserReducer,
    }
}

export default connect(mapStateToProps, null)(Layout);