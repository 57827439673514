import axios from 'axios';
import {API_BASE_URL, API_ENDPOINT_PREFIX} from '../constants';
import httpHeaders from '../utils/httpHeaders';

const URL = API_BASE_URL + API_ENDPOINT_PREFIX;
class Product {

    list = (data) => {
        const filters =  data || {};
        let url = URL + '/products';
        let authToken = httpHeaders.authHeader().headers;

        return axios.get(url, {
            params: {...filters},
            headers: authToken
        });
    }

    detail = (productId) => {
        let url = URL + '/products/' + productId;
        return axios.get(url);
    }

    placeBid = (data) => {
        let url = URL + '/bid';
        return axios.post(url, data, httpHeaders.authHeader());
    }
}

export default new Product();