import axios from 'axios';
import {API_BASE_URL, API_ENDPOINT_PREFIX, API_FORGOT_PASSWORD} from '../constants';

const URL = API_BASE_URL + API_ENDPOINT_PREFIX + API_FORGOT_PASSWORD;
class ForgotPassword {

    forgotPassword = (data) => {
        let url = URL + '/send-email';
        return axios.post(url, data);
    }

    checkToken = (data) => {
        let url = URL + '/check-token';
        return axios.post(url, data);
    }

    checkPin = (data) => {
        let url = URL + '/check-pin';
        return axios.post(url, data);
    }

    resetPassword = (data) => {
        let url = URL + '/reset-password';
        return axios.post(url, data);
    }

    getTokenDetails = (userId) => {
        let url = URL + '/token-details/' + userId;
        return axios.get(url);
    }
}

export default new ForgotPassword();