import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router';
import { validateBid } from '../../../utils/helper';
import InclusionAPI from '../../../api/Inclusion';
import ProductApi from '../../../api/Product';
import Modal from '../../common/Popup';
import Checkbox from '@material-ui/core/Checkbox';
import PriceFormat from '../../../utils/PriceFormat';
import ManageImage from '../../pages/logo/MangeBrandImage';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Bid = ({ product, userStatus, disabled }) => {
    const history = useHistory();
    const [bidError, setBidError] = useState('');
    const [bidAmount, setBidAmount] = useState(0);
    const [isAllowed, setAllowed] = useState(false);
    const [name, setName] = useState('');

    useEffect(() => {
        setBidError('');
    }, [product.currentPrice]);

    useEffect(() => {
        const fetchCustomerDetails = async () => {
            const { data: response } = await InclusionAPI.get(product.id);
            const customerInclusion = response.data;
            setAllowed(customerInclusion.allowed);
            setName(customerInclusion.name);
        };
        product.id && fetchCustomerDetails();
    }, [product]);

    const handleChange = e => {
        const inputValue = e.target.value;
      
        // Check if the input is numeric and non-negative
        if (/^[0-9]*\.?[0-9]*$/.test(inputValue)) {
          setBidError('');
          setBidAmount(inputValue);
        }else{
            setBidError('Only numbers are allowed.');
        }
      };

    const handleClickPlaceBid = () => {
        if (userStatus.length > 0) {
            return history.push(userStatus[0]);
        } else {
            if (isAllowed) {
                if (validateBid(bidAmount)) {
                    ProductApi.placeBid({
                        auctionId: product.auctionId || product.productAuction.id,
                        bidValue: bidAmount,
                        bidMethod: 0,
                    })
                        .then(response => {
                            const res = response.data;
                            if (res.success === 0) {
                                setBidError(res.message);
                            } else {
                                setBidAmount(0);
                                NotificationManager.success(response.data.message);
                            }
                        })
                        .catch(err => {
                            const errData = err.response.data || {};
                            errData.message && setBidError(errData.message);
                            console.warn(err.response);
                        });
                } else {
                    setBidError('Enter valid bid amount!');
                }
            }
        }
    };

    const enterBidValue = (e, bidValue) => {
        e.preventDefault();
        setBidAmount(bidValue);
    };

    const renderNextBidAmount = () => {
        const nextBidValue = parseFloat((product && product.currentPrice + product.minBidIncrement) || 0).toFixed(2);
        return (
            <span>
                <a
                    href="#"
                    name="nextBid"
                    onClick={e => enterBidValue(e, nextBidValue)}
                    disabled={disabled}
                >
                    Enter <PriceFormat price={nextBidValue} /> or more
                </a>
            </span>
        );
    };

    const btnUpdateImageBid = (
        <Form className="place-bid-wrap">
            <Row className="grouped-components row">
                <Col xs="12" className="no-right-padding">
                    <div >
                        {!disabled && <Form.Control
                            type="text"
                            name="bidAmount"
                            onChange={handleChange}
                            value={bidAmount || ''}
                            disabled={disabled}
                        />}
                    </div>
                    <div style={{ paddingTop: '5px' }}>
                        <Button
                            color="primary"
                            className={`col-12 btn-bid ${userStatus.length === 0 ? '' : userStatus[1]} ${
                                isAllowed ? '' : 'invert-color'
                            }`}
                            name="bid"
                            onClick={handleClickPlaceBid}
                            disabled={disabled}
                        />
                    </div>
                </Col>
            </Row>

            <div className="text-danger">{bidError}</div>
            {!disabled && <div className="next-bid-value">{renderNextBidAmount()}</div>}

            {product.isAutoBid === 1 && (
                <div className="auto-bid">
                    <FormControlLabel color="primary" control={<Checkbox name="checkedA" />} label=" Place auto bid" />
                </div>
            )}
        </Form>
    );

    if (disabled) {
        return <div>{btnUpdateImageBid}</div>;
      }
    if (userStatus[1] === 'no-default-image') {
        return <Modal size="xl" title="Manage Images" opener={btnUpdateImageBid} body={<ManageImage />} />;
    } else if (isAllowed) {
        return <div>{btnUpdateImageBid}</div>;
    } else {
        return (
            <Modal
                size="md"
                title="The player approval for your brand required."
                opener={btnUpdateImageBid}
                body={
                    <p>
                        Thank you for your interest in advertising with {name}. We will contact the player for approval.
                    </p>
                }
                hidePrimaryBtn={true}
            />
        );
    }
};

export default Bid;
