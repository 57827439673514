import React, {useState} from 'react';
import {connect} from "react-redux";
import {Button, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ChangePassword = (props) => {

    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePassword = () => {
        setShowPassword(!showPassword);
    }

    const toggleNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    }

    const toggleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    return (
        <div>
            <Form method="post">
                <Form.Group col={2}>
                    <Form.Label>Current Password</Form.Label>
                    <div className="d-flex">
                        <Form.Control name="currentPassword" type={showPassword ? "text" : "password"} onChange={props.handleChange}/>
                        &nbsp;
                        <Button className="float-end border" variant="light" type="button" onClick={togglePassword}>
                            <FontAwesomeIcon icon={showPassword ? "eye" : "eye-slash"}/>
                        </Button>
                    </div>
                    <Form.Text className="text-danger">
                        {props.validator.current.message('currentPassword', props.formData.currentPassword, 'required|nullValidate')}
                    </Form.Text>
                </Form.Group>

                <Form.Group>
                    <Form.Label>New Password</Form.Label>
                    <div className="d-flex">
                        <Form.Control name="newPassword" type={showNewPassword ? "text" : "password"} onChange={props.handleChange}/>
                        &nbsp;
                        <Button className="float-end border" variant="light" type="button" onClick={toggleNewPassword}>
                            <FontAwesomeIcon icon={showNewPassword ? "eye" : "eye-slash"}/>
                        </Button>
                    </div>
                    <Form.Text className="text-danger">
                        {props.validator.current.message('newPassword', props.formData.newPassword, 'required|nullValidate')}
                    </Form.Text>
                </Form.Group>

                <Form.Group>
                    <Form.Label>Confirm New Password</Form.Label>
                    <div className="d-flex">
                        <Form.Control name="confirmNewPassword" type={showConfirmPassword ? "text" : "password"} onChange={props.handleChange}/>
                        &nbsp;
                        <Button className="float-end border" variant="light" type="button" onClick={toggleConfirmPassword}>
                            <FontAwesomeIcon icon={showConfirmPassword ? "eye" : "eye-slash"}/>
                        </Button>
                    </div>
                    <Form.Text className="text-danger">
                        {props.validator.current.message('confirmNewPassword', props.formData.confirmNewPassword, `nullValidate|required|newPasswordValidate:${props.formData.newPassword}`)}
                    </Form.Text>
                </Form.Group>
            </Form>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        popup: state.popup,
    }
}

export default connect(mapStateToProps,null)(ChangePassword);