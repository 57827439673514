export default {
    user: {
        userId: '',
        companyName: '',
        email: '',
        firstName: '',
        lasttName: '',
        isLoggedIn: false,
        settings: {

        },
    },
    product: {
        sellingMethod: '',
        productId: ''
    },
    productList:[],

    cart: {
        items: [],
        total: 0,
        count: 0,
        checkout: {
            success: '',
            orderId: '',
            orderIdFormatted: '',
        }
    }
};