import * as types from '../ActionTypes';

export const filterProducts = (data) => {
    return (dispatch) => {
        dispatch({type: types.SET_PRODUCT_FILTER_OPTIONS, data});
    }
}

export const clearProductFilters = () => {
    return (dispatch) => {
        dispatch({ type: types.CLEAR_PRODUCT_FILTER_OPIONS});
    }
}

export const productPagination = (data) => {
    return (dispatch) => {
        dispatch({ type: types.PAGINATE_PRODUCT, data});
    }
}