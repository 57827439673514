import axios from 'axios';
import {API_BASE_URL, API_ENDPOINT_PREFIX} from '../constants';
import httpHeaders from '../utils/httpHeaders';

class ProductFilter {
    tournaments = () => {
        let url = API_BASE_URL + '/common/filter/tournaments';
        return axios.get(url);
    }

    matches = (tournamentId) => {
        let url = API_BASE_URL + '/common/filter/matches/' + tournamentId
        return axios.get(url);
    }
}

export default new ProductFilter();