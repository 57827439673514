import { QUADCOLOUR_OPTIONS } from '../constants';
import * as Constants from '../constants/RouteConstants';
export const validateBid = (bidAmount) => {
    return !isNaN(bidAmount);
}

export const getDateTimeStr = (datetime) => {
    return window.moment(datetime).format('YYYY-MM-DD HH:mm:ss');
}

export const checkProductIsExpired = (product) => {
    return new Date(product.endAt) - new Date() <= 0 || product.status !== 1;
}

export const checkUserStatus = (params) => {

    let arr = [];

    if (!params.isLoggedIn) {
        arr.push('/login');
        arr.push('not-loggedin');
        return arr;
    }
    if (params.settings.customerApprovalStatus == "SUSPENDED") {
        arr.push(Constants.ROUTE_PRODUCTS);
        arr.push('acc-suspended');
        return arr;
    }  
    if (params.settings.customerApprovalStatus !== "ACTIVE") {
        arr.push(Constants.ROUTE_ACCOUNT_STATUS);
        arr.push('acc-not-approved');
    } 
    if (!params.settings.paymentMethodStatus == 1) {
        arr.push('/account-settings/settings-and-preferences/');
        arr.push('no-payment-method');
    } 
    if (params.settings.brandImageStatus == 0){
        arr.push(Constants.ROUTE_PRODUCTS);
        arr.push('no-uploaded-image');
    } 
    if (params.settings.brandImageStatus == 2){
        arr.push(Constants.ROUTE_PRODUCTS);
        arr.push('no-default-image');
    } 
    if (params.settings.brandImageStatus == 3){
        arr.push(Constants.ROUTE_PRODUCTS);
        arr.push('not-approved');
    }
    if (params.settings.brandImageStatus == 4){
        arr.push(Constants.ROUTE_PRODUCTS);
        arr.push('image-rejected');
    }  
    if (params.settings.addressStatus != 1){
        arr.push('/account-settings/account-information');
        arr.push('no-address');
    }
    if (params.settings.emailStatus != 1){
        arr.push('/account-settings/settings-and-preferences');
        arr.push('no-email-verification');
    }
    return arr;
}

export const checkUserPaymentStatus = (params) => {
    let arr = [];
    if (!params.settings.paymentMethodStatus == 1) {
        arr.push('/account-settings/settings-and-preferences/');
        arr.push('no-payment-method');
    } 
}

// Helper function to get the delivered colour
export const getDeliveredColour = (colourScheme, colourInverted) => {

    // Find the matching colour scheme from the list
    const selectedScheme = QUADCOLOUR_OPTIONS.find(scheme => scheme.id === colourScheme);

    if (!selectedScheme) return 'Unknown Colour';

    const [foreground, background] = selectedScheme.name.split(' and ');

    // If inverted, swap the foreground and background
    if (colourInverted) {
        return `${background} and ${foreground}`;
    }

    return `${foreground} and ${background}`;
};
