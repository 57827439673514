import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ManageImage from '../../pages/logo/MangeBrandImage';
import Modal from '../../common/Popup';
import { Link } from 'react-router-dom';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function CustomizedDialogs(props) {
    const { open, onClose, suspendedMessage, goToLink, buttonText } = props;

    const [manageImageOpen, setManageImageOpen] = useState(false);

    const handleGoTo = () => {
        if (goToLink) {
            alert('Go To clicked');
        } else {
            alert('Alternative action for null goToLink');
        }

        onClose();
    };

    const handleOpenManageImage = () => {
        if (goToLink === null) {
            setManageImageOpen(true);
        }
    };

    const handleCloseManageImage = () => {
        setManageImageOpen(false);
    };

    const btnOpenImage = (
        <div id="btn-upload-brandimg">{
            <Button onClick={handleOpenManageImage}>
                {buttonText}
            </Button>
        }
        </div>
    )


    return (
        <div>

            <BootstrapDialog
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                sx={{ zIndex: 999 }}
            >
                 <div className='top-row'>
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        Message
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                 </div>
                <DialogContent dividers>
                    {suspendedMessage && (
                        <Typography
                            gutterBottom
                            dangerouslySetInnerHTML={{ __html: suspendedMessage }}
                        />

                    )}
                    <DialogActions>
                        {goToLink !== null && (
                            <Button component={Link} to={goToLink} autoFocus>
                                {buttonText}
                            </Button>
                        )}
                        {goToLink === null && (
                            <div >
                                <Modal
                                    size="xl"
                                    title="Manage Images"
                                    opener={btnOpenImage}
                                    body={<ManageImage onClose={handleCloseManageImage} />}
                                />
                            </div>
                        )}
                        <Button onClick={onClose}>
                            Exit
                        </Button>
                    </DialogActions>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}
