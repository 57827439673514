import {PayPalButtons, PayPalScriptProvider} from '@paypal/react-paypal-js';
import PaypalAPI from '../../api/Paypal';
import {connect} from 'react-redux';
import {useRef} from "react";
const PaypalButton = ({initialOptions, paypalCheckout, setIsLoading, handlePayPalError, cart, orderData, divolgoOrderId}) => {
    const stateRef = useRef();
    let apiError = {};
    let localOrder = {};
    stateRef.current = cart;
    const createOrder = (order) => {
        localOrder = order;
        setIsLoading && setIsLoading(true);
        return PaypalAPI.createPaypalOrder(order)
            .then((response) => {
                setIsLoading && setIsLoading(false);
                return response;
            })
            .then((order) => {
                setIsLoading && setIsLoading(false);
                return order.data.id;
            })
            .catch((err) => {
                apiError = err.response;
                setIsLoading && setIsLoading(false);
            });
    }
    const handleCreateOrder = (payPal) => {
        setIsLoading && setIsLoading(true);
        if (orderData) {
            setIsLoading && setIsLoading(false);
            return createOrder(orderData);
        } else {
            const items = stateRef.current.items || [];
            const orderItems = items.map((item) => {
                return ({
                    id: item.id,
                    price: item.price,
                    name: item.name,
                    description: '',
                    quantity: parseInt(item.cartQty)
                });
            });
            const order = {
                items: orderItems,
                productType: 'BUY',
                orderId: null
            };
            if (payPal.paymentSource === 'card') {
                setIsLoading && setIsLoading(false);
            }
            return createOrder(order);
        }
    }
    const handleApprove = (data) => {

        const orderData = {
            paypalOrderId: data.orderID,
            divolgoOrderId: divolgoOrderId,
            productType: localOrder.productType,
            payerId: data.payerID,
            paymentId: data.paymentID,
        };

        paypalCheckout(orderData);
    }
    const handleCancel = () => {
        setIsLoading && setIsLoading(false);
        console.log('cancelled');
    }
    const handleError = () => {
        setIsLoading && setIsLoading(false);
        if (apiError.status) {
            handlePayPalError && handlePayPalError(apiError);
        } else {
            handlePayPalError && handlePayPalError({
                status: 500,
                data: {message: 'An error occurred while doing the paypal payment!'},
            });
        }
    }
    return (
        <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
                className='payment-buttons'
                style={{color: 'blue', tagline: false, size: 'small'}}
                createOrder={handleCreateOrder}
                onApprove={handleApprove}
                onCancel={handleCancel}
                onError={handleError}
            />
        </PayPalScriptProvider>
    );
};
const mapStateToProps = (state) => {
    return {
        cart: state.cart,
    };
};
export default connect(mapStateToProps, undefined)(PaypalButton);