import React, {useEffect, useRef, useState} from 'react';
import {Form, Button, Col, Row, Table} from 'react-bootstrap';
import AccountSettingsApi from '../../../api/AccountSettings';
import tikLogo from '../../../assets/icons/tik.png';
import cancelLogo from '../../../assets/icons/cancel.png'
import verifiedLogo from '../../../assets/icons/verify.png';
import Modal from "../../common/Popup";
import ChangePassword from "./ChildForms/ChangePassword";
import {setPopupMetaData} from "../../../actions/common/PopupActions";
import {connect, useDispatch} from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import UserApi from "../../../api/User";
import {useHistory} from "react-router-dom";
import {bindActionCreators} from "redux";
import {setUserSettings} from "../../../actions/UserActions";
import Paypal from "../../../api/Paypal";
import {NotificationManager} from "react-notifications";
import BlockUi from "react-block-ui";
import EmailVerificationApi from "../../../api/EmailVerification";
import {ACCOUNT_COMPLETION_STEPS_COUNT} from '../../../constants';
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import {Accordion} from "@mui/material";

const Settings = (props) => {

    const [, forceUpdate] = useState();
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate:{forceUpdate:forceUpdate},
        validators: {
            newPasswordValidate: {
                message: "New Password and confirm password do not match.",
                rule: (val, params) => {
                    return (val == params);
                }
            },
            nullValidate: {
                message: "You can’t enter 'NULL', 'Null', 'null' ...etc as value.",
                rule: (val ) => {
                    return ( val.toLowerCase() !== 'null');
                },
                required: true
            }
        }
    }));
    const dispatch = useDispatch();
    const history = useHistory();
    const [userData, setUserData] = useState({});
    const [formData, setFormData] = useState({});
    const [finalStatusLabel, setFinalStatusLabel] = useState('');
    const [paymentMethods,setPaymentMethods] = useState([]);
    const [paymentMethodId,setPaymentMethodId] = useState(0);
    const [paymentMethod,setPaymentMethod] = useState({});
    const [isLoading,setIsLoading] = useState(true);
    const [blockUiText, setBlockUiText] = useState('Loading')
    const [expanded, setExpanded] = useState(false);
    const updatePopupMetData = (metadata) => dispatch(setPopupMetaData(metadata));
    const updateUserSettings = bindActionCreators(setUserSettings, dispatch);

    useEffect(() => {
        loadSavedData();
    }, []);

    const loadSavedData = () => {
        setIsLoading(true);
        AccountSettingsApi.getCustomerData().then(response => {
            const data = response.data || {};

            if (data) {
                setUserData({
                    customerId: data.id,
                    email: data.email,
                    alternativeEmail: '',
                    status: data.status,
                    emailStatus: data.emailVerificationStatus,
                    defaultImage:data.defaultImage,
                    customerAddress: data.customerAddress,
                })
                setPaymentMethod(data.paymentMethod || {})

                if(data.paymentMethod) {
                    setPaymentMethodId(data.paymentMethod.id)
                }
                let stepsCount = 0;
                if(data.status === "ACTIVE") {
                    stepsCount ++;
                }
                if(data.defaultImage !== null) {
                    stepsCount ++;
                }
                if(data.paymentMethod) {
                    stepsCount ++;
                }
                if(data.customerAddress !== null) {
                    stepsCount ++;
                }
                if(data.emailVerificationStatus === "VERIFIED") {
                    stepsCount ++;
                }
                if(stepsCount === ACCOUNT_COMPLETION_STEPS_COUNT) {
                    setFinalStatusLabel("Completed");
                } else {
                    setFinalStatusLabel(<h6>Incomplete ({stepsCount}/{ACCOUNT_COMPLETION_STEPS_COUNT} Steps Completed)</h6>);
                }
            }
            setIsLoading(false);
            checkParams();
        }).catch(err => {
            console.error(err);
        });

        AccountSettingsApi.paymentMethods().then(response => {
            const savedPaymentMethods = response.data.paymentMethods || [];
            setPaymentMethods(savedPaymentMethods);
        }).catch(err => {
            console.error(err);
        });
    }

    const checkParams = () => {
        let paypalToken = getParams(window.location.href).token;

        if (paypalToken) {
            setIsLoading(true);

            let request = {
                token: paypalToken
            }

            Paypal.savePaypalInfo(request).then(res => {
                let response = res.data;

                if (response.success === 1) {
                    updateUserSettings();
                    NotificationManager.success(response.message);
                    loadSavedData();
                    window.history.replaceState(null, null, window.location.pathname);
                } else {
                    NotificationManager.error(response.message);
                }

                setIsLoading(false);
            }).catch(err => {
                NotificationManager.error(err)
            });
        }
    }

    const getParams = (url) => {
        let params = {};
        let parser = document.createElement('a');
        parser.href = url;
        let query = parser.search.substring(1);
        let vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split('=');
            params[pair[0]] = decodeURIComponent(pair[1]);
        }
        return params;
    }

    const loadPaypal = () => {
        setBlockUiText('Redirecting to the PayPal Application');
        setIsLoading(true);
        Paypal.getCheckoutUrl().then(res => {
            window.location.href = res.data.url;
        });
    }

    const updatePaymentMethod = () => {
        const postData = {
            paymentMethodId,
        };

        AccountSettingsApi.updatePaymentMethod(postData).then(res => {
            let response = res.data;
            if (response.success === 1) {
                setPaymentMethod(response.data);
                updateUserSettings();
                NotificationManager.success(response.message);
                setIsLoading(false);
            } else {
                NotificationManager.error(response.message);
            }
        }).catch(err => {
            NotificationManager.error(err)
        });
        loadSavedData();
    }

    const handleClickPassword = () => {
        updatePopupMetData({primaryBtnText: 'Save', primaryBtn: {action: (formData) => onSavePassword(formData)}});
    }

    const handleClickPaymentMethod = () => {
        loadPaypal();
    }

    const handleChange = (event) => {
        if(event.target.name === "paymentMethod") {
            setPaymentMethodId(event.target.value);
        } else {
            setFormData({...formData, [event.target.name]: event.target.value});
        }
    }

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const onSavePassword = (passwordData) => {
        return new Promise(function (resolve, reject) {

            if(!validator.current.allValid()) {
                validator.current.showMessages();
                forceUpdate(1);
                resolve({error: true});
                return;
            } else{
                UserApi.changePassword(passwordData).then(response => {
                    if(response.data.success === 1) {
                        resolve({error: false, message: "Password Changed Successfully."});
                    } else {
                        reject(response.data.message);
                    }
                }).catch(err => {
                    reject(err);
                });
            }
        });
    }

    const handleSavePaymentMethod = () => {
        setIsLoading(true);
        if (paymentMethodId !== paymentMethod.paymentMethodId) {

            // if(paymentMethodId == 1) {
            //     Paypal.checkExistingPaypalInfo().then(res => {
            //         if(res.data.data === true) {
            //             updatePaymentMethod();
            //             loadSavedData();
            //             setIsLoading(false);
            //         } else {
            //             loadPaypal();
            //         }
            //     }).catch(err => {
            //         NotificationManager.error(err)
            //         setIsLoading(false);
            //     });
            // } else {
            //     updatePaymentMethod();
            //     loadSavedData();
            //     setIsLoading(false);
            // }
            updatePaymentMethod();
            setIsLoading(false);
        }
        loadSavedData();
    }

    const handleVerifyEmail = () => {

        EmailVerificationApi.sendVerificationEmail({email:userData.email}).then(response => {
            if(response.data.success === 1) {
                NotificationManager.success(response.data.message);
                history.push('verify-email?tokenId=' + response.data.data);
            } else {
                NotificationManager.error(response.data.message);
            }
        }).catch(err => {
            NotificationManager.error(err);
        });
    }

    return (
        <BlockUi className="block-ui" tag="div" blocking={isLoading} message={blockUiText}>
            <Form method="post">
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3} md={3} lg={3} xl={2}>Account Status</Form.Label>
                    <Form.Label column sm={1}>:</Form.Label>
                    <Form.Label column sm={8} md={8} lg={6} xl={4}>
                        <Accordion className="webstore-accordion" expanded={expanded === 'panel'} onChange={handleChangeAccordion('panel')}>
                            <AccordionSummary
                                className="accordion-heading"
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={"panelbh-content"}
                                id={"panelbh-header"}
                            >
                                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                                    {finalStatusLabel}
                                </Typography>
                                <Typography sx={{color: 'text.secondary'}}/>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <Table style={{margin:0}} responsive borderless hover>
                                        <tbody>
                                        <tr>
                                            <td>
                                                Default Image
                                                <img
                                                    width="20px"
                                                    className="float-end"
                                                    src={userData.defaultImage !== null ? tikLogo : cancelLogo}
                                                    alt={userData.defaultImage !== null ? "tik logo" : "cancel Logo"}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Billing Address
                                                <img
                                                    width="20px"
                                                    className="float-end"
                                                    src={userData.customerAddress !== null ? tikLogo : cancelLogo}
                                                    alt={userData.customerAddress !== null ? "tik logo" : "cancel Logo"}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Account Approval
                                                <img
                                                    width="20px"
                                                    className="float-end"
                                                    src={userData.status === "ACTIVE" ? tikLogo : cancelLogo}
                                                    alt={userData.status === "ACTIVE" ? "tik logo" : "cancel Logo"}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Payment Method
                                                <img
                                                    width="20px"
                                                    className="float-end"
                                                    src={paymentMethodId !== 0 ? tikLogo : cancelLogo}
                                                    alt={paymentMethodId !== 0 ? "tik logo" : "cancel Logo"}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{paddingBottom:0}}>
                                                Email Verification
                                                <img
                                                    width="20px"
                                                    className="float-end"
                                                    src={userData.emailStatus === "VERIFIED" ? tikLogo : cancelLogo}
                                                    alt={userData.emailStatus === "VERIFIED" ? "tik logo" : "cancel Logo"}
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3} md={3} lg={3} xl={2}>Primary Email</Form.Label>
                    <Form.Label column sm={1}>:</Form.Label>
                    <Form.Label column sm={8} md={8} lg={8} xl={8}>
                        <span>{userData.email}</span>
                        <span style={{paddingLeft:20}}>{userData.emailStatus === "VERIFIED" ?
                            <img src={verifiedLogo} alt="verified icon" width="24px"/>
                            :
                            <Button variant="primary" type="button" onClick={handleVerifyEmail}>Verify Email</Button>
                        }</span>
                    </Form.Label>
                    <Col sm={3}>

                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3} md={3} lg={3} xl={2}>Password</Form.Label>
                    <Form.Label column sm={1}>:</Form.Label>
                    <Col sm>
                        <Modal
                            size = "m"
                            title = "Change Password"
                            opener = {<Button variant="primary" name="changePassword" onClick={handleClickPassword} type="button">Change Password</Button>}
                            body = {
                                <ChangePassword
                                    validator={validator}
                                    handleChange={handleChange}
                                    formData={formData}
                                />
                            }
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3} md={3} lg={3} xl={2}>Alternative Email</Form.Label>
                    <Form.Label column sm={1}>:</Form.Label>
                    {userData.alternativeEmail !== '' ?
                        <Form.Label column sm={2}>{userData.alternativeEmail}</Form.Label>
                        :
                        <></>
                    }

                    <Col sm>
                        <Button variant="primary" type="button" disabled>Add</Button>
                    </Col>
                </Form.Group>

                <Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={3} md={3} lg={3} xl={2}>Payment Method</Form.Label>
                        <Form.Label column sm={1}>:</Form.Label>
                        <Col column sm={4} md={3} lg={4} xl={4}>
                            <Form.Control as="select" type="select" name="paymentMethod" onChange={handleChange} value={paymentMethodId}>
                                {!paymentMethod.id && <option value="0">-Select-</option>}
                                {paymentMethods && paymentMethods.map((pm, key) => {
                                    return <option key={key} value={pm.id} >{pm.name}</option>
                                })}
                            </Form.Control>
                        </Col>
                        {paymentMethodId != paymentMethod.id &&
                        <Col sm={1}><Button color="primary" disabled={paymentMethodId <= 0} type="button" onClick={handleSavePaymentMethod}>Save</Button></Col>
                        }
                        {/*(paymentMethodId == 1 && paymentMethod.id == 1) &&
                        <Col sm={4} md={5} lg={4} xl={3}>
                            <Button variant="primary" name="paypal" onClick={handleClickPaymentMethod} type="button">Change Paypal Account</Button>
                        </Col>
                    */}
                    </Form.Group>
                </Form.Group>
            </Form>
        </BlockUi>
    )
}

export default connect(null,null)(Settings);