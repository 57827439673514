import React from 'react';
import { Link } from 'react-router-dom';
import {Row, Col, ListGroup, ListGroupItem} from 'reactstrap';

const MangeSettingsSidebar = () => {

    return (
        <>
            <h2>Account Settings</h2>
            <Row>
                <Col xs="12">
                    <ListGroup>
                        <Link to="/account-settings">
                            <ListGroupItem className="side-bar-list-item" action><span>Account Dashboard</span></ListGroupItem>
                        </Link>
                        <Link to="/account-settings/account-information">
                            <ListGroupItem className="side-bar-list-item" action><span>Account Information</span></ListGroupItem>
                        </Link>
                        <Link to="/account-settings/settings-and-preferences">
                            <ListGroupItem className="side-bar-list-item" action><span>Settings And Preferences</span></ListGroupItem>
                        </Link>
                        <Link to="/account-settings/notifications-preferences">
                            <ListGroupItem className="side-bar-list-item" action><span>Notification Preferences</span></ListGroupItem>
                        </Link>
                    </ListGroup>
                </Col>
            </Row>
        </>
    )
};

export default MangeSettingsSidebar;