import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faCartPlus,
    faTrashAlt,
    faArrowRight,
    faPhoneAlt,
    faCamera,
    faQuestionCircle,
    faSearch,
    faTimesCircle,
    faEye,
    faEyeSlash,
    faInfoCircle,
    faUser
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faArrowRight,
    faCartPlus,
    faPhoneAlt,
    faTrashAlt,
    faCamera,
    faQuestionCircle,
    faSearch,
    faTimesCircle,
    faEye,
    faEyeSlash,
    faInfoCircle,
    faUser
);