import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SimpleReactValidator from "simple-react-validator";
import ForgotPassword from "../../../api/ForgotPassword";
import {useHistory} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import FormTitle from "../../common/FormTitle";
import Clock from "../productPage/Clock";
import OtpInput from "react-otp-input";
import BlockUi from "../../common/BlockUi";

const ResetPasswordForm = (props) => {

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [pin, setPin] = useState(0);
    const [pinError, setPinError] = useState('');
    const [token, setToken] = useState('');
    const [userId, setUserId] = useState(0);
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState(true);
    const [expirationTime, setExpirationTime] = useState('');
    const [type, setType] = useState('pin');
    const [verified, setVerified] = useState(false);
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const [, forceUpdate] = useState();
    const validator = useRef(new SimpleReactValidator({
        autoForceUpdate:{forceUpdate:forceUpdate},
        validators: {
            newPasswordValidate: {
                message: "New Password and confirm password do not match.",
                rule: (val, params) => {
                    return (val == params);
                }
            }
        }
    }));

    useEffect(() => {

        if(count === 0) {

            setIsLoading(true);

            if (props.location.search === '') {
                history.push('/not-found');
            } else {
                const URL = props.location.search.split('=');
                const authToken = URL[1];

                if (URL[0] === "?userId") {
                    setUserId(parseInt(authToken.split('&')[0]));
                    setType('pin');

                    ForgotPassword.getTokenDetails(parseInt(authToken.split('&')[0])).then(response => {
                        const data = response.data.data;

                        if(data == null) {
                            history.push('/not-found');
                        } else {
                            setEmail(data.email);
                            setExpirationTime(data.expirationTime);
                            setStatus(data.active);
                        }

                    }).catch(err => {
                        console.log(err);
                    });
                } else {

                    const tokenString = authToken.split("&userId");
                    setToken(tokenString[0]);
                    setUserId(parseInt(URL[2]));

                    if (tokenString[0] === undefined || tokenString[0] === '') {
                        history.push('/not-found');
                    } else {
                        const formData = {
                            userId: parseInt(URL[2]),
                            token: tokenString[0]
                        };

                        ForgotPassword.checkToken(formData).then(response => {
                            if(response.data) {
                                setVerified(response.data.data);
                                if(!response.data.data) {
                                    history.push('/not-found');
                                }
                            }
                        }).catch(err => {
                            console.log(err);
                        });
                        setType('token');
                    }
                }
                setCount(count + 1);
                setIsLoading(false);
            }
        }
    },[verified,newPassword])

    const toggleNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    }

    const toggleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const handleChange = (event) => {
        if(event.target === undefined) {
            setPin(event);
            setPinError('');
        } else {
            if (event.target.name === 'newPassword') {
                setNewPassword(event.target.value);
            } else if (event.target.name === "confirmNewPassword") {
                setConfirmNewPassword(event.target.value);
            }
        }
    }

    const handleResetPassword = () => {

        if(!validator.current.allValid()) {

            validator.current.showMessages();
            forceUpdate(1);
        } else {

            setIsLoading(true);

            const formData = {
                userId: userId,
                newPassword: newPassword,
                confirmNewPassword: confirmNewPassword,
                token: token,
                pin: pin,
            }

            ForgotPassword.resetPassword(formData).then(response => {
                if (response.data.success === 1) {
                    NotificationManager.success("The Password has been reset successfully!");
                    history.push('/signup');
                } else {
                    NotificationManager.error(response.data.message || 'An error occurred!');
                }
            }).catch(err => {
                NotificationManager.error(err || 'An error occurred!');
            });
            setIsLoading(false);
        }
    }

    const handleVerifyPin = () => {

        if (pin === 0) {
            setPinError('PIN is required.')
        } else if (parseInt(pin.toString().length) !== 6) {
            setPinError('Please Enter 6-Digit PIN!')
        } else {

            setIsLoading(true);

            const formData = {
                userId: userId,
                pin: pin
            };

            ForgotPassword.checkPin(formData).then(response => {
                if(response.data) {
                    setVerified(response.data.data);
                    if(!response.data.data) {
                        NotificationManager.error("The PIN you entered is incorrect!");
                        setPinError("The PIN you entered is incorrect!");
                    }
                }
            }).catch(err => {
                console.log(err);
            });
            setIsLoading(false);
        }
    }

    const handleResend = () => {

        setIsLoading(true);
        ForgotPassword.forgotPassword({email: email}).then(response => {
            if(response.data.success === 1) {

                const nextUserId = response.data.data;
                NotificationManager.success("Password Reset Link Has Been Sent to your email!");
                history.push('/reset-password?userId=' + nextUserId);
            } else {
                NotificationManager.error(response.data.message);
            }
        }).catch(err => {
            NotificationManager.error(err);
        });
        setIsLoading(false);
    }

    const handleEnterKeyReset = (event) => {
        if(event.which === 13) {
            handleResetPassword();
        }
    }

    const handleEnterKeyPin = (event) => {
        if(event.which === 13) {
            handleVerifyPin();
        }
    }

    return (
        <BlockUi className="block-ui" tag="div" blocking={isLoading} message='loading'>
            <Form method="post">
                {verified === true ?
                    <Container onKeyPress={handleEnterKeyReset}>
                        <Row>
                            <Col className="center-content text-left" sm={12} md={12} lg={6} xl={6}>
                                <FormTitle>Create New Password</FormTitle>
                                <Form.Label>Your new password must be different from the previous password.</Form.Label>
                                <br/><br/>
                                <Form.Group>
                                    <Form.Label>New Password</Form.Label>
                                    <div className="d-flex">
                                        <Form.Control name="newPassword" type={showNewPassword ? "text" : "password"} onChange={handleChange}/>
                                        &nbsp;
                                        <Button className="float-end border" variant="light" type="button" onClick={toggleNewPassword}>
                                            <FontAwesomeIcon icon={showNewPassword ? "eye" : "eye-slash"}/>
                                        </Button>
                                    </div>
                                    <Form.Text className="text-danger">
                                        {validator.current.message('newPassword', newPassword, 'required')}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="center-content text-left" sm={12} md={12} lg={6} xl={6}>
                                <Form.Group>
                                    <Form.Label>Confirm New Password</Form.Label>
                                    <div className="d-flex">
                                        <Form.Control name="confirmNewPassword" type={showConfirmPassword ? "text" : "password"} onChange={handleChange}/>
                                        &nbsp;
                                        <Button className="float-end border" variant="light" type="button" onClick={toggleConfirmPassword}>
                                            <FontAwesomeIcon icon={showConfirmPassword ? "eye" : "eye-slash"}/>
                                        </Button>
                                    </div>
                                    <Form.Text className="text-danger">
                                        {validator.current.message('confirmNewPassword', confirmNewPassword, `required|newPasswordValidate:${newPassword}`)}
                                    </Form.Text>
                                </Form.Group>
                                <Button variant="primary" name="resetPassword" onClick={handleResetPassword} type="button">Save</Button>
                                <br/><br/>
                            </Col>
                        </Row>
                    </Container>
                    :
                    <>
                        {type === 'pin' &&
                            <>
                                {status &&
                                <Container onKeyPress={handleEnterKeyPin}>
                                    <Row>
                                        <Col className="center-content" sm={12} md={12} lg={6} xl={6}>
                                            <Form.Group>
                                                <FormTitle>Submit PIN</FormTitle>
                                                <Form.Label>
                                                    <div>Please enter the 6 digit code that was sent to</div>
                                                    <br/>
                                                    <h5 className="center-content">{email}</h5>
                                                    <br/>
                                                    <div className="center-content" style={{width:"50%"}}>
                                                        <Clock title={"PIN expires in"} time={expirationTime} noDays/>
                                                    </div><br/>
                                                </Form.Label>
                                                <Form.Label>
                                                    <OtpInput
                                                        value={pin}
                                                        onChange={handleChange}
                                                        numInputs={6}
                                                        separator={<span style={{width: "8px"}}/>}
                                                        isInputNum={true}
                                                        shouldAutoFocus={true}
                                                        inputStyle={{
                                                            border: "1px solid lightGray",
                                                            borderRadius: "8px",
                                                            width: "40px",
                                                            height: "40px",
                                                            fontSize: "18px",
                                                            color: "#000",
                                                            fontWeight: "400",
                                                            caretColor: "blue"
                                                        }}
                                                        focusStyle={{
                                                            border: "1px solid grey",
                                                            outline: "none"
                                                        }}
                                                    />
                                                </Form.Label>
                                                <Form.Text className="text-danger font-14">
                                                    {pinError}
                                                </Form.Text>
                                            </Form.Group>
                                            <Button style={{width:"55%"}} variant="primary" name="verify" onClick={handleVerifyPin} type="button">Submit</Button>
                                            <br/>
                                            <br/>
                                        </Col>
                                    </Row>
                                </Container> ||
                                <Container>
                                    <Row>
                                        <Col className="center-content" sm={12} md={12} lg={6} xl={6}>
                                            <p>Didn't receive the email? The PIN has expired</p><br/>
                                            <Button variant="primary" name="resend" onClick={handleResend} type="button">Resend Email</Button>
                                        </Col>
                                    </Row>

                                </Container>
                                }
                            </>
                        }
                    </>
                }
            </Form>
        </BlockUi>
    );
}

export default ResetPasswordForm;