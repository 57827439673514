import React from "react";
import MainNavBar from "./components/MainNavBar";
import "./Header.scss";

const Header = ({children}) => (

    <header className="header">
        <MainNavBar/>
    </header>
);

export default Header;