import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import PriceFormat from "../../../utils/PriceFormat";
import InvoiceAPI from "../../../api/Invoice";

const InvoiceListPage = (props) => {

    const [invoices, setInvoices] = useState([]);
    const [message, setMessage] = useState('');

     useEffect(() => {

         window.scrollTo(0, 0);
         InvoiceAPI.list().then(response => {

             const data = response.data.invoices || [];
             setInvoices(data);

             if(data.length === 0) {
                 setMessage("You don't have any previous invoices to display!");
             }

         }).catch(err => {
             console.warn(err);
         });
     },[]);

    return(
        <div className="order-list-container">
            <table className="main-cart-table table table-hover">
                <thead className="thead-light">
                <tr>
                    <th scope="col">INVOICE#</th>
                    <th scope="col">DATE</th>
                    <th scope="col">STATUS</th>
                    <th scope="col" className="text-end">INVOICE TOTAL</th>
                    <th scope="col" className="text-end">BALANCE</th>
                    <th scope="col"/>
                </tr>
                </thead>

                <tbody>
                {invoices.map((invoice, key) => {
                    return <tr key={key}>
                        <td>{invoice.invoiceNumber}</td>
                        <td>{invoice.invoiceDateFormatted}</td>
                        <td>{invoice.status.replace(/_/g, ' ')}</td>
                        <td className="text-end"><PriceFormat price = {invoice.totalAmount}/></td>
                        <td className="text-end"><PriceFormat price = {invoice.balance}/></td>
                        <td  className="text-end">
                            <Link to={`/my-invoice/${invoice.id}`}>View</Link>
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
            <div style={{textAlign:'center'}}><b>{message}</b></div>
        </div>
    );
}

export default InvoiceListPage;