import axios from 'axios';
import {API_BASE_URL, API_ENDPOINT_PREFIX, API_RECEIPT} from '../constants';
import httpHeaders from '../utils/httpHeaders';

const URL = API_BASE_URL + API_ENDPOINT_PREFIX + API_RECEIPT;

class Receipt {

    list = () => {
        return axios.get(URL, httpHeaders.authHeader());
    }

    get = (receiptId) => {
        let url = URL + '/' + receiptId;
        return axios.get(url, httpHeaders.authHeader());
    }

    download = (receiptId) => {
        return axios.get(URL + '/pdf/' + receiptId, {
            headers: httpHeaders.authHeader().headers,
            responseType: 'blob'
        });
    }
}

export default new Receipt();