import initialState from './InitialState';
import * as types from '../../actions/ActionTypes';

const FilterReducer = (state =  initialState.filterOptions, action) => {
    switch (action.type) {

        case types.SET_PRODUCT_FILTER_OPTIONS:
            return {...state, ...action.data}

        case types.CLEAR_PRODUCT_FILTER_OPIONS:
            return initialState.filterOptions;

        default:
            return state;
    }
}

export default FilterReducer;