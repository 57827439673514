import React from 'react';
import SimpleReactValidator from 'simple-react-validator';
import FormTitle from '../common/FormTitle';
import './Common.scss';
import {Button, Form, FormLabel} from 'react-bootstrap';
import ContactUsApi from "../../api/ContactUs";
import {NotificationManager} from "react-notifications";

class ContactUsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formData: {
                name : '',
                email : '',
                number : '',
                message : ''
            }
        }

        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            validators: {
                nullValidate: {
                    message: "You can’t enter 'NULL' as value.",
                    rule: (val) => {
                        return (val !== "NULL" &&  val !== "Null" && val !== "null");
                    },
                    required: true
                }
            }
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleChange = (event) => {
        this.setState({formData: {...this.state.formData, [event.target.name]: event.target.value}});
    }

    handleSubmit = event => {
        event.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }
        ContactUsApi.postContactUs(this.state.formData).then(response => {
            const data = response.data || {};
            if (data && data.success === 1) {
                NotificationManager.success("Contact inquiry sent successfully. One of our agents will contact you soon!");
                this.setState({formData: {name : '', email : '', number : '', message : ''}});
                this.validator.hideMessages();
            } else  {
                NotificationManager.error(data.message);
            }
        }).catch(err => {
            NotificationManager.error(err.response.data.message || "An error occurred");
        });
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col">
                    <Form onSubmit={this.handleSubmit}>
                        <FormTitle>Contact Us</FormTitle>

                        <div style={{paddingTop:"20px",paddingBottom: "20px"}}>
                            <FormLabel>Jot us a note, and we’ll get back to you as quickly as possible.</FormLabel>
                        </div>

                        <Form.Group className="form-group">
                            <Form.Label>Name<span className="requiredStar"> *</span></Form.Label>
                            <Form.Control name="name" type="text" value={this.state.formData.name} placeholder="Your name" onChange={this.handleChange}/>
                            <Form.Text className="text-danger">
                                {this.validator.message('name', this.state.formData.name, 'required|nullValidate')}
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="form-group">
                            <Form.Label>E-mail<span className="requiredStar"> *</span></Form.Label>
                            <Form.Control name="email" value={this.state.formData.email} placeholder="Your e-mail" type="text" onChange={this.handleChange}/>
                            <Form.Text className="text-danger">
                                {this.validator.message('e-mail', this.state.formData.email, 'required|nullValidate|email')}
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="form-group">
                            <Form.Label>Phone Number<span className="requiredStar"> *</span></Form.Label>
                            <Form.Control name="number" value={this.state.formData.number} placeholder="Your phone number" type="text" onChange={this.handleChange}/>
                            <Form.Text className="text-danger">
                                {this.validator.message('phone number', this.state.formData.number, 'required|nullValidate|phone|integer')}
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="form-group">
                            <Form.Label>What's on your mind?<span className="requiredStar"> *</span></Form.Label>
                            <Form.Control name="message" value={this.state.formData.message} as="textarea" placeholder="What's on your mind?" rows={5} onChange={this.handleChange}/>
                            <Form.Text className="text-danger">
                                {this.validator.message('message', this.state.formData.message, 'required|nullValidate')}
                            </Form.Text>
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                    </div>
                </div>
            </>
        );
    }
}

export default ContactUsPage;

