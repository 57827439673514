import axios from 'axios';
import {API_BASE_URL, API_ENDPOINT_PREFIX} from '../constants';
import httpHeaders from '../utils/httpHeaders';

const URL = API_BASE_URL + API_ENDPOINT_PREFIX;
class AccountSetings {

    paymentMethods = () => {
        let url = URL + '/customer/settings/payment-methods';
        return axios.get(url, httpHeaders.authHeader());
    }

    getCustomerData = () => {
        let url = URL + '/customer/current';
        return axios.get(url, httpHeaders.authHeader());
    }

    updateBusinessInformation = (data) => {
        let url = URL + '/customer/settings/business-information';
        return axios.put(url, data, httpHeaders.authHeader());
    }

    updatePaymentMethod = (data) => {
        let url = URL + '/customer/settings/payment-method';
        return axios.put(url, data, httpHeaders.authHeader());
    }

    updateAuthorizedContactInformation = (data) => {
        let url = URL + '/customer/settings/contact-information';
        return axios.put(url, data, httpHeaders.authHeader());
    }

}

export default new AccountSetings();