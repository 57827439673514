import React, { Component } from 'react';
import { Carousel } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Image1 from "../../assets/Images/roadsafety.jpg";
import Image2 from "../../assets/Images/briana.jpg";
import './HomePage.scss'
import * as Constants from '../../constants/RouteConstants';

class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 0,
        };
    }

    handleSelect = (selectedIndex) => {
        this.setState({ index: selectedIndex });
    };

    render() {
        return (
            <div className="homepage-container">
                <div className="header">
                    <div className="hero-text">
                        <h1 className="header-title">
                            Revolutionize your sports advertising strategy with Divolgo!
                            Say goodbye to the limitations of traditional ad placements based on past performance.
                        </h1>
                        <p className="header-subtitle">
                            In the dynamic world of sports advertising, where every moment counts,
                            relying solely on players' and teams' historical data is a thing of the past.
                            That's why we've crafted a cutting-edge solution that opens up new realms of possibility.
                        </p>
                        <p className="header-subtitle">
                            Presenting Divolgo - Your Gateway to Unprecedented Sports Ad Reach.
                        </p>
                        <a href={Constants.ROUTE_LOGIN}>
                            <h6 className="join-text">
                                Join now as an advertiser!
                            </h6>
                        </a>
                    </div>
                </div>
                <div className="carousel-container">
                    <div className="carousel-wrapper">
                        <Carousel className="top-slides" activeIndex={this.index}
                            onSelect={this.handleSelect}>
                            <Carousel.Item interval={10000}>
                                <img
                                    className="d-block w-100"
                                    src={Image2}
                                    alt="First slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={10000}>
                                <img
                                    className="d-block w-100"
                                    src={Image1}
                                    alt="Second slide"
                                />
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
                <div className="challenges-container">
                    <div className="challenges-text">
                        <h5 className="challenges-title">
                            The Challenge: Barriers in Traditional Sports Advertising
                        </h5>
                        <p className="subsection">
                            In the realm of sports advertising, the arena has been limited
                            by constraints that hinder both advertisers and fans.
                        </p>
                        <h5 className="challenges-title">
                            Restricted Real Estate:
                        </h5>
                        <p className="subsection">
                            The prime real estate on players' attire comes at a premium, driving advertising
                            costs to exorbitant levels. This exclusivity alienates smaller advertisers,
                            preventing them from participating and thriving.
                        </p>
                        <h5 className="challenges-title">
                            Endorsement Monopoly:
                        </h5>
                        <p className="subsection">
                            Lengthy endorsement deals monopolize advertising space for years,
                            leaving minimal room for fresh voices and brands. This stifles competition
                            and innovation, ultimately limiting the diversity of messages reaching fans.
                        </p>
                    </div>
                </div>
                <div className="steps-container">
                    <div className="steps-text">
                        <h5 className="steps-title">
                            Getting started is easy
                        </h5>
                        <ul className="steps-list">
                            <li>1. Register with Divolgo</li>
                            <li>2. Get KYC approval from Divolgo</li>
                            <li>3. Upload your logo</li>
                            <li>4. Logo approved by Divolgo</li>
                            <li>5. Find advertising options and add to cart</li>
                            <li>6. Check out and pay using PayPal or your credit card</li>
                        </ul>
                    </div>
                </div>
                <div style={{ textAlign: "center" }}>
                    <a href={Constants.ROUTE_LOGIN}>
                        <button className="join-button black-button">
                            Join Now
                        </button>
                    </a>
                </div>
            </div>
        );
    }
}

export default HomePage;
