import axios from 'axios';
import {API_BASE_URL, API_ENDPOINT_PREFIX} from '../constants';
import httpHeaders from '../utils/httpHeaders';

const URL = API_BASE_URL + API_ENDPOINT_PREFIX;

class BrandImage {

    upload = (data) => {
        let url = URL + '/brand-image/upload';
        let fd = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            fd.append(key, value);
        });

        return axios.post(url, fd, httpHeaders.authHeader());
    }

    crop = (data) => {
        let url = URL + '/brand-image/crop';
        return axios.post(url, data, httpHeaders.authHeader());
    }

    changeColor = (data) => {
        let url = URL + '/brand-image/changeColour';
        return axios.post(url, data, httpHeaders.authHeader());
    }

    changeColorFromUrl = (data) => {
        let url = URL + '/brand-image/changeColourFromUrl';
        return axios.post(url, data, httpHeaders.authHeader());
    }

    getApprovedImages = (brandId) => {
        let url = URL + '/brand-images/approved/brands/'+brandId;
        return axios.get(url, httpHeaders.authHeader())
    }

    getPendingImages = (brandId) => {
        let url = URL + '/brand-images/pending/brands/'+brandId;
        return axios.get(url, httpHeaders.authHeader())
    }

    getRejectedImages = (brandId) => {
        let url = URL + '/brand-images/rejected/brands/'+brandId;
        return axios.get(url, httpHeaders.authHeader())
    }

    setAsDefaultImage = (brandId,imageId) => {
        let url = URL + '/brand-images/brands/'+brandId+'/set-default';
        return axios.post(url, {brandImageId: imageId}, httpHeaders.authHeader());
    }

    deleteImage = (imageId) => {
        let url = URL + '/brand-images/' + imageId;
        return axios.delete(url, httpHeaders.authHeader())  
    }
}

export default new BrandImage();