import React from 'react';
import {Col, Form, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

const GeneralNotifications = (props) => {

    return (
        <div>
            <Form method="post">
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>Newsletter Subscription</Form.Label>
                    <Form.Label column sm={1}>:</Form.Label>
                    <Col sm={1} data-tip data-for="newsLetterTip">
                        <span className="d-inline-block">
                            <Form.Check
                                type="switch"
                                label=''
                                name="newsletter"
                                disabled
                            />
                        </span>
                        <ReactTooltip id="newsLetterTip" place="top" effect="solid">
                            {props.enableNotification}
                        </ReactTooltip>
                    </Col>
                    <Col>
                        <FontAwesomeIcon icon="info-circle" data-tip data-for="newsLetterTip2"/>
                        <ReactTooltip id="newsLetterTip2" place="top" effect="solid">
                            By enabling this, You will be subscribed to receive Divolgo newsletter monthly.
                        </ReactTooltip>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={2}>Image Approval/Refusal</Form.Label>
                    <Form.Label column sm={1}>:</Form.Label>
                    <Col sm={1} data-tip data-for="imageStateTip">
                        <span className="d-inline-block">
                            <Form.Check
                                type="switch"
                                label=''
                                name="imageState"
                                checked
                                disabled
                            />
                        </span>
                        <ReactTooltip id="imageStateTip" place="top" effect="solid">
                            {props.disableNotification}
                        </ReactTooltip>
                    </Col>
                    <Col>
                        <FontAwesomeIcon icon="info-circle" data-tip data-for="imageStateTip2"/>
                        <ReactTooltip id="imageStateTip2" place="top" effect="solid">
                            By enabling this, You will receive an email when an admin approves or rejects your brand image.
                        </ReactTooltip>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={2}>Order Confirmation</Form.Label>
                    <Form.Label column sm={1}>:</Form.Label>
                    <Col sm={1} data-tip data-for="orderTip">
                        <span className="d-inline-block">
                            <Form.Check
                                type="switch"
                                label=''
                                name="order"
                                checked
                                disabled
                            />
                        </span>
                        <ReactTooltip id="orderTip" place="top" effect="solid">
                            {props.disableNotification}
                        </ReactTooltip>
                    </Col>
                    <Col>
                        <FontAwesomeIcon icon="info-circle" data-tip data-for="orderTip2"/>
                        <ReactTooltip id="orderTip2" place="top" effect="solid">
                            By enabling this, You will receive an email when creating an order.
                        </ReactTooltip>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                    <Form.Label column sm={2}>Security Notifications</Form.Label>
                    <Form.Label column sm={1}>:</Form.Label>
                    <Col sm={1} data-tip data-for="securityTip">
                        <span className="d-inline-block">
                            <Form.Check
                                type="switch"
                                label=''
                                name="security"
                                disabled
                            />
                        </span>
                        <ReactTooltip id="securityTip" place="top" effect="solid">
                            {props.enableNotification}
                        </ReactTooltip>
                    </Col>
                    <Col>
                        <FontAwesomeIcon icon="info-circle" data-tip data-for="securityTip2"/>
                        <ReactTooltip id="securityTip2" place="top" effect="solid">
                            By enabling this, You will receive an email when there is a security issue in your account.
                        </ReactTooltip>
                    </Col>

                </Form.Group>
            </Form>
        </div>
    );
}

export default GeneralNotifications;