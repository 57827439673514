import axios from 'axios';
import {API_BASE_URL, API_ENDPOINT_PREFIX} from '../constants';
import httpHeaders from '../utils/httpHeaders';

const URL = API_BASE_URL + API_ENDPOINT_PREFIX;

class Brands {

    getBrands = () => {
        let url = URL + '/brands';
        return axios.get(url, httpHeaders.authHeader())
    }

    setDefaultBrand = (data) => {
        let url = URL + '/brands/set-default';
        return axios.post(url, data, httpHeaders.authHeader());
    }

    createBrand = (data) => {
        let url = URL + '/brands';
        return axios.post(url, data, httpHeaders.authHeader());
    }

    deleteBrand = (brandId) => {
        let url = URL + '/brands/'+brandId;
        return axios.delete(url, httpHeaders.authHeader())
    }

}

export default new Brands();