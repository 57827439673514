import axios from 'axios';
import { API_BASE_URL, API_ENDPOINT_PREFIX } from '../constants';
import httpHeaders from '../utils/httpHeaders';

const URL = API_BASE_URL + API_ENDPOINT_PREFIX;

class Inclusion {
    get = productId => {
        let url = URL + '/customer-inclusion/products/' + productId;
        return axios.get(url, httpHeaders.authHeader());
    };
}

export default new Inclusion();
