import React, {useState, useEffect} from "react";
import {Row, Col, Label, Input, FormGroup} from "reactstrap";
import {connect} from "react-redux";
import {filterProducts, clearProductFilters, productPagination} from "../../../../actions/common/FilterActions";
import ProductFiltersApi from "../../../../api/ProductFilter";
import {Radio, RadioGroup, Checkbox, FormControlLabel} from "@material-ui/core";

const ProductsSidebar = (props) => {

    const {filters} = props;
    const [tournaments, setTournaments] = useState([]);
    const [matches, setMatches] = useState([]);
    const [tournament, setTournament] = useState(0);

    const handleChangeTournament = (e) => {
        setTournament(e.target.value);
    };

    const handleChangeMatch = (e) => {
        props.filterProducts({matchId: e.target.value});
    };

    useEffect(() => {
        ProductFiltersApi.tournaments()
            .then((response) => {
                setTournaments(response.data.tournaments || []);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    useEffect(() => {
        ProductFiltersApi.matches(tournament)
            .then((response) => {
                setMatches(response.data.matches || []);
                props.filterProducts({tournamentId: tournament});
            })
            .catch((err) => {
                console.error(err);
            });
    }, [tournament]);

    //Reset pagination when filters get changed
    useEffect(() => {
        props.resetPagination({page: "", pageSize: ""});
    }, [props.filters]);

    //Reset pagination when filters get changed
    useEffect(() => {
        props.resetPagination({page: "", pageSize: ""});
    }, [props.filters]);

    const handleChangeFilters = (e) => {
        let key = e.target.name;
        let value = e.target.value;

        if (e.target.type && e.target.type === "checkbox") {
            value = e.target.checked ? 1 : 0;
        }

        props.filterProducts({[key]: value});
    };

    const renderSearchFilter = (child) => {
        return (
            <Col xs="12" className="filter-item-wrap">
                <FormGroup>{child}</FormGroup>
            </Col>
        );
    };

    return (
        <Row className="search-filters-row">

            {renderSearchFilter(
                <>
                    <br/>
                    <Label>Tournament</Label>
                    <Input
                        type="select"
                        value={tournament}
                        onChange={handleChangeTournament}
                        value={filters.tournamentId}
                    >
                        <option value="0">ALL</option>
                        {tournaments.map((tmt, key) => {
                            return (
                                <option key={key} value={tmt.id}>
                                    {tmt.name}
                                </option>
                            );
                        })}
                    </Input>
                </>
            )}

            {renderSearchFilter(
                <>
                    <Label>Match</Label>
                    <Input
                        type="select"
                        value={filters.matchId}
                        onChange={handleChangeMatch}
                    >
                        <option value="0">--</option>
                        {matches.map((match, key) => {
                            return (
                                <option key={key} value={match.id}>
                                    {match.name}
                                </option>
                            );
                        })}
                    </Input>
                </>
            )}

            {renderSearchFilter(
                <>
                    <Label>Type</Label>
                    <RadioGroup
                        aria-label="product type"
                        name="productType"
                        value={filters.productType}
                        onChange={handleChangeFilters}
                    >
                        <FormControlLabel value="" control={<Radio/>} label="All"/>
                        <FormControlLabel
                            value="buy"
                            control={<Radio/>}
                            label="Buy Now"
                        />
                        <FormControlLabel
                            value="auction"
                            control={<Radio/>}
                            label="Auction"
                        />
                    </RadioGroup>
                </>
            )}

            {renderSearchFilter(
                <>
                    <FormControlLabel
                        onChange={handleChangeFilters}
                        color="primary"
                        value={1}
                        control={<Checkbox name="featured"/>}
                        label="FEATURED"
                    />
                    <FormControlLabel
                        onChange={handleChangeFilters}
                        value={1}
                        color="primary"
                        control={<Checkbox name="endingSoon"/>}
                        label="ENDING SOON"
                    />
                </>
            )}
        </Row>
    );
};

const mapStateToProps = (state) => {
    return {
        filters: state.filters,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        filterProducts: (options) => {
            dispatch(filterProducts(options));
        },
        cleaFilters: () => {
            dispatch(clearProductFilters());
        },
        resetPagination: (options) => {
            dispatch(productPagination(options));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsSidebar);