import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import SimpleReactValidator from 'simple-react-validator';

import FormTitle from '../common/FormTitle';
import SignupForm from './user/SignupForm.jsx';
import SigninForm from './user/SigninForm.jsx';
import './Common.scss';

import {login, setUserSettings, signup} from '../../actions/UserActions'
import { FormLabel } from 'react-bootstrap';

import BlockUi from '../common/BlockUi';
import * as Constants from '../../constants';

import * as ConstantsRoute from '../../constants/RouteConstants';

class SignupPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: '',
            login: {
            },
            signup: {
            },
            signinIsLoading: false,
            signupIsLoading: false,
            signinErrors: '',
            signupErrors: '',
        }

        this.loginValidator = new SimpleReactValidator({
            autoForceUpdate: this,
            validators: {
                nullValidate: {
                    message: "You can’t enter 'NULL' as value.",
                    rule: (val) => {
                        return (val !== "NULL" &&  val !== "Null" && val !== "null");
                    },
                    required: true
                }
            }
        });

        this.signupValidator = new SimpleReactValidator({
            autoForceUpdate: this,
            required: true,
            accepted: true,
            validators: {
                passwordValidate: {
                    message: "Passwords do not match.",
                    rule: (val, params, validator) => {
                        return (val == params);
                    }
                },
                checkboxValidate: {
                    message: "You must agree to the terms and conditions",
                    rule: (val, params) => {
                        return (val == params);
                    }
                },
                nullValidate: {
                    message: "You can’t enter 'NULL' as value.",
                    rule: (val) => {
                        return (val !== "NULL" &&  val !== "Null" && val !== "null");
                    },
                    required: true
                },
                comapnyLengthValidate:{
                    message: "Provided company name is too long. Please enter a company name less than " + Constants.FIELD_LENGTH + " characters.",
                    rule: (val) => {
                        return (val.length <=  Constants.FIELD_LENGTH);
                    },
                },
                emailLengthValidate:{
                    message: "Provided email is too long. Please enter a email less than " + Constants.FIELD_LENGTH + " characters.",
                    rule: (val) => {
                        return (val.length <=  Constants.FIELD_LENGTH);
                    },
                },
                passwordLengthValidate:{
                    message: "Provided password is too long. Please enter a password less than " + Constants.FIELD_LENGTH + " characters.",
                    rule: (val) => {
                        return (val.length <=  Constants.FIELD_LENGTH);
                    },
                },
                brandLengthValidate:{
                    message: "Provided brand is too long. Please enter a brand less than " + Constants.FIELD_LENGTH + " characters.",
                    rule: (val) => {
                        return (val.length <=  Constants.FIELD_LENGTH);
                    },
                },
            }
        });
    }

    componentDidUpdate() {
        this.redirectAfterLogin();
    }

    componentDidMount() {
        this.redirectAfterLogin();
    }

    redirectAfterLogin = () => {
        const isLoggedIn  = this.props.user.isLoggedIn;
        const userApprovalStatus = this.props.user.settings.customerApprovalStatus;
        if (isLoggedIn) {
            // history.push({ConstantsRoute.ROUTE_PRODUCTS});
            if(userApprovalStatus === "ACTIVE"){
                window.location.href= ConstantsRoute.ROUTE_PRODUCTS;
            }
            if(userApprovalStatus === "PENDING_APPROVAL" || this.props.user.signupSuccess){
                window.location.href= "/account-status";
            }
        }
        //Auto login after signup
        if (this.props.user.signupSuccess) {
            this.props.actions.login({username: this.state.signup.email, password: this.state.signup.password});
        }
    }

    handleEnterLogin = event => {
        if(event.which === 13)
        {
            this.handleClickLogin(event);
        }
    }

    handleEnterSignup = event => {
        if(event.which === 13)
        {
            this.handleClickSignup(event);
        }
    }

    handleClickLogin = async (event) => {
        if (!this.loginValidator.allValid()) {
            this.loginValidator.showMessages();
            return;
        }

        this.props.error.signInErr = false;
        this.setState({signinIsLoading: true});

        await this.props.actions.login(this.state.login);
        await this.props.actions.setUserSettings();
        this.setState({redirect: ConstantsRoute.ROUTE_PRODUCTS});
    }

    handleClickSignup = event => {
        if (!this.signupValidator.allValid()) {
            this.signupValidator.showMessages();
            return;
        }

        this.props.error.signUpErr = false;
        this.setState({signupIsLoading: true});

        this.props.actions.signup(this.state.signup)
    }

    handleChangeLogin = event => {
        this.setState({...this.state, login: {...this.state.login, [event.target.name]: event.target.value}});
    }

    handleChangeSignup = event => {
        event.target.value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({...this.state, signup: {...this.state.signup, [event.target.name]: event.target.value}});
    }

    render() {

        const hasSignupErrors = this.props.error && this.props.error.signUpErr ? true : false;
        const hasSigninErrors =  this.props.error && this.props.error.signInErr ?  true : false;

        return (

            <>
                <div className="row">
                    <div className="col-md-5">
                        <FormTitle>Sign In, if you have an account</FormTitle>

                        {this.props.error && this.props.error.signInErr && (<FormLabel className="text-danger">{this.props.error.signInErr}</FormLabel>) }

                        <BlockUi tag="div" blocking={!hasSigninErrors && this.state.signinIsLoading}>
                            <SigninForm
                                data = {this.state.login}
                                validator = {this.loginValidator}
                                handleClick = {this.handleClickLogin}
                                handleChange = {this.handleChangeLogin}
                                handleEnterKey = {this.handleEnterLogin}
                            />
                        </BlockUi>
                    </div>

                    <div className="col-md-7">
                        <div className="col-md-10 float-end">
                            <FormTitle>New to Divolgo?</FormTitle>

                            {this.props.error && this.props.error.signUpErr && (<FormLabel className="text-danger">{this.props.error.signUpErr}</FormLabel>) }

                            <BlockUi tag="div" blocking={!hasSignupErrors && this.state.signupIsLoading}>
                                <SignupForm
                                    data = {this.state.signup}
                                    validator = {this.signupValidator}
                                    handleClick = {this.handleClickSignup}
                                    handleChange = {this.handleChangeSignup}
                                    handleEnterKey = {this.handleEnterSignup}
                                />
                            </BlockUi>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.ErrorReducer,
        user: state.UserReducer
    }
}

const mapDispathToProps = dispatch => {
    return {
        actions: {
            login: bindActionCreators(login, dispatch),
            signup: bindActionCreators(signup, dispatch),
            setUserSettings: bindActionCreators(setUserSettings, dispatch),
        }
    }
}

export default connect(mapStateToProps, mapDispathToProps)(SignupPage);