import React from 'react';
import FormTitle from '../common/FormTitle';
import './Common.scss';
import { Button, Form, FormLabel, Row, Col } from 'react-bootstrap';
import AccountSettingsApi from '../../api/AccountSettings';
import tikLogo from '../../assets/icons/tik.png';
import cancelLogo from '../../assets/icons/cancel.png'
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ManageImage from '../pages/logo/MangeBrandImage';
import Modal from '../common/Popup';
import { VERIFIED_STATUS, ACTIVE_STATUS } from '../../constants';
import { ROUTE_BRANDS } from '../../constants/RouteConstants';

class AccountStatusPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            formData: {
                name: '',
                email: '',
                number: '',
                message: '',
            },
            userData: {
                defaultImage: null,
                customerAddress: null,
            },
            paymentMethodId: 0,
            setManageImageOpen: false,
        };

        this.loadSavedData();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    loadSavedData = () => {
        AccountSettingsApi.getCustomerData()
            .then((response) => {
                const data = response.data || {};
                if (data) {
                    this.setState({
                        userData: {
                            customerId: data.id,
                            email: data.email,
                            alternativeEmail: '',
                            status: data.status,
                            emailStatus: data.emailVerificationStatus,
                            defaultImage: data.defaultImage,
                            customerAddress: data.customerAddress,
                            paymentMethod: data.paymentMethod || {},
                        },
                    });

                    if (data.paymentMethod) {
                        this.setState({
                            paymentMethodId: data.paymentMethod.id,
                        });
                    }
                }
            })
            .catch((error) => {
                this.props.history.push('/');
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    };

    render() {
        const { userData, paymentMethodId, isLoading } = this.state;
        const btnOpenImage = (
            <div id="btn-upload-brandimg">
                <Button variant="link" onClick={this.handleOpenManageImage} style={{ fontSize: '15px' }}>
                    upload image
                </Button>
            </div>
        );

        return (
            <>
                <div className="row">
                    <div className="col">
                        <Form onSubmit={this.handleSubmit}>
                            <FormTitle>Account Activation Status</FormTitle>

                            <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                <p>Welcome to Divolgo!</p>
                                <FormLabel>
                                    Your account needs to be verified by Divolgo before you can purchase ads. The current status of your
                                    account is shown below. Please complete the tasks that are required and await confirmation of
                                    verification of your account. Once confirmed you will be able to buy ads and place them on wearables of
                                    elite sports personalities.
                                </FormLabel>
                            </div>

                            <AccordionDetails>
                                <Typography>
                                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                                        <li>
                                            <div className="d-flex align-items-center">
                                                <div className="col">
                                                    Email Verification
                                                </div>
                                                {!isLoading && (
                                                    <>
                                                        <div className="col-2">
                                                            <img
                                                                width="20px"
                                                                className="float-end"
                                                                src={userData.emailStatus === VERIFIED_STATUS ? tikLogo : cancelLogo}
                                                                alt={userData.emailStatus === VERIFIED_STATUS ? "tik logo" : "cancel Logo"}
                                                            />
                                                        </div>
                                                        <div className="col-2">
                                                            {userData.emailStatus !== VERIFIED_STATUS ? (
                                                                <Button variant="link" href="/account-settings/settings-and-preferences" style={{ fontSize: '15px' }}>verify email</Button>
                                                            ) : null}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-center">
                                                <div className="col">
                                                    Complete Profile Information
                                                </div>
                                                {!isLoading && (
                                                    <>
                                                        <div className="col-2">
                                                            <img
                                                                width="20px"
                                                                className="float-end"
                                                                src={userData.customerAddress !== null ? tikLogo : cancelLogo}
                                                                alt={userData.customerAddress !== null ? "tik logo" : "cancel Logo"}
                                                            />
                                                        </div>
                                                        <div className="col-2">
                                                            {userData.customerAddress == null ? (
                                                                <Button variant="link" href="/account-settings/account-information" style={{ fontSize: '15px' }}>complete profile</Button>
                                                            ) : null}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-center">
                                                <div className="col">
                                                    Set Payment Method
                                                </div>
                                                {!isLoading && (
                                                    <>
                                                        <div className="col-2">
                                                            <img
                                                                width="20px"
                                                                className="float-end"
                                                                src={paymentMethodId !== 0 ? tikLogo : cancelLogo}
                                                                alt={paymentMethodId !== 0 ? "tik logo" : "cancel Logo"}
                                                            />
                                                        </div>
                                                        <div className="col-2">
                                                            {paymentMethodId == 0 ? (
                                                                <Button variant="link" href="/account-settings/settings-and-preferences" style={{ fontSize: '14px' }}>set payment method</Button>
                                                            ) : null}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-center">
                                                <div className="col">
                                                    Default Logo Image
                                                </div>
                                                {!isLoading && (
                                                    <>
                                                        <div className="col-2">
                                                            <img
                                                                width="20px"
                                                                className="float-end"
                                                                src={userData.defaultImage !== null ? tikLogo : cancelLogo}
                                                                alt={userData.defaultImage !== null ? "tik logo" : "cancel Logo"}
                                                            />
                                                        </div>
                                                        <div className="col-2">
                                                            {userData.defaultImage == null ? (
                                                                <Button variant="link" href={ROUTE_BRANDS} style={{ fontSize: '15px' }}>manage image</Button>
                                                            ) : null}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex align-items-center">
                                                <div className="col">
                                                    Account Approval Complete
                                                </div>
                                                {!isLoading && (
                                                    <>
                                                        <div className="col-2">
                                                            <img
                                                                width="20px"
                                                                className="float-end"
                                                                src={userData.status === ACTIVE_STATUS ? tikLogo : cancelLogo}
                                                                alt={userData.status === ACTIVE_STATUS ? "tik logo" : "cancel Logo"}
                                                            />
                                                        </div>
                                                        <div className="col-2">
                                                            {userData.status !== ACTIVE_STATUS ? (
                                                                <Button variant="link" href="/contact-us" style={{ fontSize: '15px' }}>contact admin</Button>
                                                            ) : null}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </li>
                                    </ul>
                                </Typography>
                            </AccordionDetails>
                            <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                <FormLabel>
                                    If you have any queries about the verification process, please contact <a className='link-with-underline' href="/contact-us">Divolgo customer support</a>.
                                </FormLabel>
                            </div>
                        </Form>
                    </div>
                </div>
            </>
        );
    }
}

export default AccountStatusPage;
