import storage from '../utils/storage';

class httpHeaders {

    constructor() {
        this.authToken = storage.getAuthToken() || '';
    }

    authHeader = () => {
        let header = {};
        if (this.authToken) {
            header =  {headers: { Authorization: 'Bearer ' + this.authToken}};
        }else if(storage.getAuthToken()){
            this.authToken = storage.getAuthToken();
            header =  {headers: { Authorization: 'Bearer ' + this.authToken}};
        }
        return header;
    }
}

export default new httpHeaders();