import React from 'react';
import {WEBSOCKET_URI} from '../constants';
import SockJsClient from 'react-stomp';

const topic = '/topic/updated-auction';

class Websocket extends React.Component {
    constructor(props) {
        super(props);
    }

    sendMessage = (msg) => {
        this.clientRef.sendMessage('/topic/updated-auction', msg);
    }

    receiveMessage = (msg) => {
        this.props.onReceiveMessage(msg);
    }

    render() {

        return (
            <div>
                <SockJsClient
                    url = {WEBSOCKET_URI}
                    topics={[topic]}
                    onMessage={(msg) => this.receiveMessage(msg)}
                    ref={ (client) => { this.clientRef = client }}
                />
            </div>
        );
    }
}

export default  Websocket;