import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Row, Col} from "reactstrap";
import BrandImageItem from './BrandImageCard';
import {primaryAction} from '../../../actions/common/PopupActions';
import {updateDefaultImage} from '../../../actions/UserActions';

import BrandImageApi from '../../../api/BrandImage';
import { bindActionCreators } from 'redux';
import {setUserSettings} from '../../../actions/UserActions';
import { list } from '../../../actions/ProductActions';
import BrandsApi from '../../../api/Brands';
import { NotificationManager } from 'react-notifications';

const UploadedImages = (props) => {

    const {images, brandId, defaultBrandId, onChangeDefault, reloadImages} = props;
    const [defaultImage, setDefaultImage] = useState();

    const handleChange = (value) => {
        setDefaultImage(value);
    }

    const handleDelete = (value) => {

        let defaultImage = false

        {images && images.map((image, key) => {
            if (image.isDefaultImage == 1 && image.id === value) {
                defaultImage = true;
                return;
            }
        })}

        if (!defaultImage) {
            BrandImageApi.deleteImage(value).then(response => {

                const res = response.data;
    
                if (res && res.success === 1) {
                    NotificationManager.success('Brand Image deleted successfully.');
                } else {
                    NotificationManager.error(res.message);
                }
                reloadImages();
            }).catch(err => {
                NotificationManager.error('An error occured while deleting image.');
            });
        } else {
            NotificationManager.info('You are trying to delete default brand image please change it before deleting.');
        }
    }
    

    const setDefaultBrand = (brandId) => {
        BrandsApi.setDefaultBrand({ brandId: brandId }).then(response => {

            const res = response.data;

            if (res && res.success === 1) {
                
            } else {
                NotificationManager.error(res.message);
            }
        }).catch(err => {
            NotificationManager.error('An error occured while changing current brand.');
        });
    }

    const onSave = () => {
        let p = new Promise(function (resolve, reject) {

            if(brandId !== defaultBrandId){
                setDefaultBrand(brandId);
            }

            BrandImageApi.setAsDefaultImage(brandId,defaultImage).then(response => {

                const res = response.data;

                if (res && res.success === 1) {
                    props.updateDefaultImage(props.user.settings, res.data.bwImagePath);
                    props.actions.setUserSettings();
                    resolve({error: false, message: "Default image has been updated."});
                    onChangeDefault();

                    let options = {...props.filters, ...props.pagination};
                    props.getProductList(options);

                } else {
                    reject(res.message);
                }
            }).catch(err => {
                console.error(err);
                reject('An Image must be selected as the default image.');
            });
        });
        return p;
    }

    useEffect(() => {
        props.popup.activeTab == 1 && props.primaryAction({primaryBtn: {action: () => onSave() }});
    }, [props.popup.activeTab, defaultImage, images]);

    return (
        <>
            <Row>
                {images && images.map((image, key) => {

                    let currentDefaultImg = image.isDefaultImage && image.id;

                    {images && images.map((image, key) => {
                        if(image.id === defaultImage){
                            currentDefaultImg = defaultImage || image.isDefaultImage && image.id;
                        }
                    })}

                    return <BrandImageItem
                        key = {key}
                        logo = {image.brandImageItems}
                        imgPath = {image.bwImagePath}
                        handleChange = {handleChange}
                        handleDelete = {handleDelete}
                        value = {image.id}
                        defaultImage = {currentDefaultImg}
                        flag = {props.flag}
                        {...image}
                    />
                })}

                {images.length <= 0 && <Col><br/><br/>No Items</Col>}
            </Row>
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        primaryAction: (metadata) => { dispatch(primaryAction(metadata)) },
        updateDefaultImage: (settings, newImg) => { dispatch(updateDefaultImage(settings, newImg)) },
        actions: {
			setUserSettings: bindActionCreators(setUserSettings, dispatch)
        },
        getProductList: (filters) => {
            dispatch(list(filters));
        }
    }
}

const mapStateToProps = state => {
    return {
        popup: state.popup,
        user: state.UserReducer,
        filters: state.filters,
        pagination: state.pagination,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadedImages);
