import React from 'react';
import {Route} from 'react-router-dom';

const LayoutRoute = ({component: Component, sidebar: Sidebar, layout: Layout, ...rest}) => {

    return (
        <Route
            {...rest}
            render={props => (
                <Layout
                    {...rest}
                    component={<Component {...props} />}
                    sidebar={!rest.noSidebar && <Sidebar {...props}/>}
                    sidebar2={!rest.noSidebar && <Sidebar {...props}/>}
                />
            )}
        />
    );
};

export default LayoutRoute;