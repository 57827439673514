import React from 'react';
import {Form, Button} from 'react-bootstrap';
import AccountSettingsApi from '../../../api/AccountSettings';
import CommonApi from '../../../api/Common';
import {NotificationManager} from 'react-notifications';
import SimpleReactValidator from 'simple-react-validator';
import * as Constants from '../../../constants';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'

class BusinessInformation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formData: {
                companyName: ''
            },
            customerData: {},
            industries: [],
            countries: [],
            accountStatus: '',
            telephone: ''
        }

        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            validators: {
                nullValidate: {
                    message: "You can’t enter 'NULL' as value.",
                    rule: (val) => {
                        return (val.toLowerCase() !== "null");
                    },
                    required: true
                },
                countryValidate: {
                    message: "Please choose your country.",
                    rule: (val) => {
                        return (val > 0);
                    },
                    required: true
                },
                industryValidate: {
                    message: "Please choose your industry.",
                    rule: (val) => {
                        return (val > 0);
                    },
                    required: true
                },
                addressLengthValidate: {
                    message: "Provided address is too long. Please enter a address less than " + Constants.FIELD_LENGTH + " characters.",
                    rule: (val) => {
                        return (val.length <= Constants.FIELD_LENGTH);
                    },
                },
                cityLengthValidate: {
                    message: "Provided city is too long. Please enter a city less than " + Constants.FIELD_LENGTH + " characters.",
                    rule: (val) => {
                        return (val.length <= Constants.FIELD_LENGTH);
                    },
                },
                stateLengthValidate: {
                    message: "Provided state is too long. Please enter a state less than " + Constants.FIELD_LENGTH + " characters.",
                    rule: (val) => {
                        return (val.length <= Constants.FIELD_LENGTH);
                    },
                },
                postalCodeLengthValidate: {
                    message: "Provided postal code is too long. Please enter a postal code less than " + Constants.FIELD_LENGTH + " characters.",
                    rule: (val) => {
                        return (val.length <= Constants.FIELD_LENGTH);
                    },
                },
                messages: {
                    integer: "The postal code must be a valid number."
                }
            },
        });
    }

    componentDidMount() {
        this.loadCustomerData();
        this.loadIndustries();
        this.loadCountries();
        window.scrollTo(0, 0)
    }

    loadIndustries = () => {
        CommonApi.industries().then(response => {
            const industries = response.data.industries || [];
            this.setState({industries});
        }).catch(err => {
            console.error(err.response);
        });
    }

    loadCountries = () => {
        CommonApi.countries().then(response => {
            const countries = response.data.countries || [];
            this.setState({countries});
        }).catch(err => {
            console.error(err.response);
        });
    }

    loadCustomerData = () => {
        AccountSettingsApi.getCustomerData().then(response => {
            const data = response.data || {};

            if (data) {
                let formData = {
                    companyName: data.companyName,
                    brandName: data.brands.map(brand => brand.brandName),
                    industryId: data.industryId,
                    email: data.email,
                };

                if (data.customerAddress) {
                    formData = {
                        ...formData,
                        addressLine1: data.customerAddress.addressLineOne,
                        addressLine2: data.customerAddress.addressLineTwo,
                        city: data.customerAddress.city,
                        state: data.customerAddress.state,
                        postalCode: data.customerAddress.postalCode,
                        countryId: data.customerAddress.countryId,
                        telephone: data.customerAddress.telephone
                    }
                }
                this.setState({formData: {...this.state.formData, ...formData}, accountStatus: data.status})
            }
        }).catch(err => {
            console.error(err);
        });
    }

    handleChange = (event) => {
        this.setState({formData: {...this.state.formData, [event.target.name]: event.target.value}});
    }

    handleChangeTelephoneInput = (value) => {
        this.setState({formData: {...this.state.formData, telephone: value}});
    };

    handleSubmit = (event) => {
        event.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        AccountSettingsApi.updateBusinessInformation(this.state.formData).then(response => {
            const data = response.data || {};

            if (data && data.success === 1) {
                NotificationManager.success(data.message);
            } else {
                NotificationManager.error(data.message);
            }
        }).catch(err => {
            NotificationManager.error(err.response.data.message || "An error occurred");
        });
    }

    render() {

        return (
            <Form onSubmit={this.handleSubmit} method="post">
                <Form.Group className="form-group">
                    <Form.Label>Brand</Form.Label>
                    <Form.Control name="brandName" type="text" onChange={this.handleChange} defaultValue={this.state.formData.brandName} disabled={true}/>
                    <Form.Text className="text-danger">
                        {this.validator.message('brandName', this.state.formData.brandName, 'required')}
                    </Form.Text>
                </Form.Group>

                <Form.Group className="form-group">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control name="companyName" type="text" onChange={this.handleChange} value={this.state.formData.companyName} disabled={this.state.accountStatus === "ACTIVE"}/>
                    <Form.Text className="text-danger">
                        {this.validator.message('companyName', this.state.formData.companyName, 'required|string')}
                    </Form.Text>
                </Form.Group>

                <Form.Group className="form-group">
                    <Form.Label>Industry <span className="requiredStar">*</span></Form.Label>
                    <Form.Control as="select" name="industryId" onChange={this.handleChange} value={this.state.formData.industryId}>
                        <option value="0">Choose</option>
                        {this.state.industries.map((industry, key) => {
                            return (
                                <option value={industry.id}>{industry.name}</option>
                            )
                        })
                        }
                    </Form.Control>
                    <Form.Text className="text-danger">
                        {this.validator.message('industryId', this.state.formData.industryId, 'industryValidate')}
                    </Form.Text>
                </Form.Group>

                <Form.Group className="form-group">
                    <Form.Label>Billing Address <span className="requiredStar">*</span></Form.Label>
                    <Form.Control name="addressLine1" type="text" placeholder="Address Line 1" onChange={this.handleChange} value={this.state.formData.addressLine1}/>
                    <Form.Text className="text-danger">
                        {this.validator.message('addressLine1', this.state.formData.addressLine1, 'required|string|addressLengthValidate|nullValidate')}
                    </Form.Text><br/>

                    <Form.Control name="addressLine2" type="text" placeholder="Address Line 2" onChange={this.handleChange} value={this.state.formData.addressLine2}/>
                    <Form.Text className="text-danger">
                        {this.validator.message('addressLine2', this.state.formData.addressLine2, 'string|addressLengthValidate|nullValidate')}
                    </Form.Text><br/>

                    <Form.Control name="city" type="text" placeholder="City" onChange={this.handleChange} value={this.state.formData.city}/>
                    <Form.Text className="text-danger">
                        {this.validator.message('city', this.state.formData.city, 'required|string|cityLengthValidate|nullValidate')}
                    </Form.Text><br/>

                    <Form.Control name="state" type="text" placeholder="State/Province" onChange={this.handleChange} value={this.state.formData.state}/>
                    <Form.Text className="text-danger">
                        {this.validator.message('state', this.state.formData.state, 'required|alpha_space|stateLengthValidate|nullValidate')}
                    </Form.Text>
                </Form.Group>

                <Form.Group className="form-group">
                    <Form.Label>Postal Code <span className="requiredStar">*</span></Form.Label>
                    <Form.Control name="postalCode" type="text" onChange={this.handleChange} value={this.state.formData.postalCode}/>
                    <Form.Text className="text-danger">
                        {this.validator.message('postalCode', this.state.formData.postalCode, 'required|integer|postalCodeLengthValidate|nullValidate')}
                    </Form.Text>
                </Form.Group>

                <Form.Group className="form-group">
                    <Form.Label>Country <span className="requiredStar">*</span></Form.Label>
                    <Form.Control as="select" name="countryId" onChange={this.handleChange} value={this.state.formData.countryId}>
                        <option value="0">Choose</option>
                        {this.state.countries.map((country, key) => {
                            return (
                                <option value={country.id}>{country.countryName}</option>
                            )
                        })}
                    </Form.Control>
                    <Form.Text className="text-danger">
                        {this.validator.message('countryId', this.state.formData.countryId, 'countryValidate')}
                    </Form.Text>
                </Form.Group>

                <Form.Group className="form-group">
                    <Form.Label>Telephone <span className="requiredStar">*</span></Form.Label>
                    <Form.Group>
                        <ReactPhoneInput
                            inputExtraProps={{
                                name: "phone",
                                required: true,
                                autoFocus: true,
                                padding: '8px'
                            }}
                            country='us'
                            value={this.state.formData.telephone}
                            name="telephone"
                            onChange={this.handleChangeTelephoneInput}
                            inputStyle={{width: '100%'}}/>
                    </Form.Group>
                </Form.Group>

                <Form.Group className="form-group">
                    <Button variant="primary" type="submit" className="float-end" disabled={this.state.formData.countryId == "0" || this.state.formData.industryId == "0" || !this.state.formData.telephone || !this.state.formData.addressLine1 || !this.state.formData.city || !this.state.formData.state || !this.state.formData.postalCode || !this.state.formData.telephone}>Save</Button>
                </Form.Group>

                <Form.Group className="form-group"><br/></Form.Group>
            </Form>
        )
    }
}

export default BusinessInformation;