import React from 'react';
import {Form} from 'react-bootstrap';
import {connect} from "react-redux";

const ForgotPasswordForm = (props) => {

    return (
        <Form>
            <Form.Group  controlId="formBasicEmail">
                <Form.Label>Enter the email associated with your Divolgo account and we will send you an email with instructions to reset your password.
                </Form.Label>
                <br/><br/>
                <Form.Label>Email <span className="requiredStar">*</span></Form.Label>
                <Form.Control name="email" type="email" placeholder="Enter email" onChange={props.handleChange}/>
                <Form.Text className="text-danger">
                    {props.validator.current.message('email', props.userEmail, 'required')}
                    <p className="font-14">{props.forgotError}</p>
                </Form.Text>
            </Form.Group>
        </Form>
    )
}

const mapStateToProps = state => {
    return {
        popup: state.popup,
    }
}

export default connect(mapStateToProps,null)(ForgotPasswordForm);