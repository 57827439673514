
import {STORAGE_PROVIDER} from '../constants';

class storage {

    setData = (key, data) => {

        if (STORAGE_PROVIDER == 'LOCAL') {
            window.localStorage.setItem(key, data);
        } else if (STORAGE_PROVIDER == 'SESSION') {
            localStorage.setItem(key, data);
        } else {
            console.error('Storage method has not specified.')
        }
    }

    getData = (key) => {
        if (STORAGE_PROVIDER == 'LOCAL') {
            return window.localStorage.getItem(key);
        } else if (STORAGE_PROVIDER == 'SESSION') {
            return sessionStorage.getItem(key);
        } else {
            console.error('Storage method has not specified.');
        }
    }

    deleteData = (key) => {
        if (STORAGE_PROVIDER == 'LOCAL') {
            return window.localStorage.removeItem(key)
        } else if (STORAGE_PROVIDER == 'SESSION') {
            return sessionStorage.removeItem(key);
        } else {
            console.error('Storage method has not specified.');
        }
    }

    getUserData = () => {
        return this.getData('userData');
    }

    getAuthToken = () => {
        return this.getData('authToken');
    }

    getUserSettings = () => {
        let data = this.getData(this.getAuthToken());
        return JSON.parse(data) || {};
    }

    removeUserAuthData = () => {
        this.deleteData(this.getAuthToken());
        this.deleteData('userData');
        this.deleteData('authToken');
        this.deleteData('cart');
        return true;
    }
}

export default new storage();