export default {
    errorData: {
        httpError: {},
        msg: ''
    },
    popupMetaData: {
        primaryBtnText: 'Save',
        primaryBtn: {
            action: () => console.log("Action not implemented.")
        },
        secondaryBtnText: 'Close'
    },
    filterOptions: {
        keyword: '',
        tournamentId: '',
        matchId: '',
        productType: '',
    },
    pagination: {
        page: '',
        pageSize: '',
    },

    isLoading: false,

}