import axios from 'axios';
import {API_BASE_URL, API_ENDPOINT_PREFIX} from '../constants';
import httpHeaders from '../utils/httpHeaders';

const URL = API_BASE_URL + API_ENDPOINT_PREFIX;

class Cart {

    checkout = (data) => {
        let url = URL + '/checkout/';
        return axios.post(url, data, httpHeaders.authHeader());
    }
}

export default new Cart();