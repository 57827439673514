import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import FormTitle from "../../common/FormTitle";
import Clock from "../productPage/Clock";
import OtpInput from "react-otp-input";
import BlockUi from "../../common/BlockUi";
import EmailVerificationApi from "../../../api/EmailVerification";

const EmailVerificationForm = (props) => {

    const [pin, setPin] = useState(0);
    const [pinError, setPinError] = useState('');
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState(false);
    const [tokenId, setTokenId] = useState();
    const [expirationTime, setExpirationTime] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {

        setIsLoading(true);
        if (props.location.search !== '') {
            const URL = props.location.search.split('=');

            if (URL[0] === '?tokenId') {
                setTokenId(URL[1]);

                EmailVerificationApi.getTokenDetails(URL[1]).then(response => {

                    const data = response.data;
                    if(data) {

                        if (data.success === 1) {
                            setEmail(data.data.email);
                            setExpirationTime(data.data.time);
                            setStatus(data.data.active);
                        } else {
                            history.push('/account-settings/settings-and-preferences');
                        }
                    }
                }).catch(err => {
                    console.log(err);
                });

            } else if (URL[0] === '?token') {

                const tmpToken = URL[1].split('&');

                const formData = {
                    customerId: URL[2],
                    token: tmpToken[0]
                }

                EmailVerificationApi.verifyByToken(formData).then(response => {
                    const data = response.data;
                    if(data) {

                        if (data.success === 1) {
                            NotificationManager.success(data.message);
                            history.push("/account-settings/settings-and-preferences");
                        } else {
                            history.push('/not-found');
                        }
                    }
                }).catch(err => {
                    console.log(err);
                });
            }
        } else {
            history.push('/not-found');
        }
        setIsLoading(false);
    },[])

    const handleChange = (event) => {
        if(event.target === undefined) {
            setPin(event);
            setPinError('');
        }
    }

    const handleVerifyPin = () => {

        if (pin === 0) {
            setPinError('PIN is required.')
        } else if (parseInt(pin.toString().length) !== 6) {
            setPinError('Please Enter 6-Digit PIN!')
        } else {

            setIsLoading(true);

            const formData = {
                id: tokenId,
                pin: pin
            };

            EmailVerificationApi.verifyByPin(formData).then(response => {
                const data = response.data;
                if(data) {

                    if (data.success === 1) {
                        NotificationManager.success(data.message);
                        history.push("/account-settings/settings-and-preferences");
                    } else {
                        NotificationManager.error(data.message);
                        setPinError(data.message);
                    }
                }
            }).catch(err => {
                console.log(err);
            });
            setIsLoading(false);
        }
    }

    const handleResend = () => {

        setIsLoading(true);
        EmailVerificationApi.sendVerificationEmail({email:email}).then(response => {

            if(response.data.success === 1) {
                NotificationManager.success(response.data.message);
                history.go(0);
            } else {
                NotificationManager.error(response.data.message);
            }
        }).catch(err => {
            NotificationManager.error(err);
        });
        setIsLoading(false);
    }

    const handleEnterKeyPin = (event) => {
        if(event.which === 13) {
            handleVerifyPin();
        }
    }

    return (
        <BlockUi className="block-ui" tag="div" blocking={isLoading} message='loading'>
            <Form method="post">
                {status &&
                <Container onKeyPress={handleEnterKeyPin}>
                    <Row>
                        <Col className="center-content" sm={12} md={12} lg={6} xl={6}>
                            <Form.Group>
                                <FormTitle>Submit PIN</FormTitle>
                                <Form.Label>
                                    <div>Please enter the 6 digit code that was sent to</div>
                                    <br/>
                                    <h5 className="center-content">{email}</h5>
                                    <br/>
                                    <div className="center-content" style={{width:"50%"}}>
                                        <Clock title={"PIN expires in"} time={expirationTime} noDays/>
                                    </div><br/>
                                </Form.Label>
                                <Form.Label>
                                    <OtpInput
                                        value={pin}
                                        onChange={handleChange}
                                        numInputs={6}
                                        separator={<span style={{width: "8px"}}/>}
                                        isInputNum={true}
                                        shouldAutoFocus={true}
                                        inputStyle={{
                                            border: "1px solid lightGray",
                                            borderRadius: "8px",
                                            width: "40px",
                                            height: "40px",
                                            fontSize: "18px",
                                            color: "#000",
                                            fontWeight: "400",
                                            caretColor: "blue"
                                        }}
                                        focusStyle={{
                                            border: "1px solid grey",
                                            outline: "none"
                                        }}
                                    />
                                </Form.Label>
                                <Form.Text className="text-danger font-14">
                                    {pinError}
                                </Form.Text>
                            </Form.Group>
                            <Button style={{width:"55%"}} variant="primary" name="verify" onClick={handleVerifyPin} type="button">Submit</Button>
                            <br/>
                            <br/>
                        </Col>
                    </Row>
                </Container> ||
                <Container>
                    <Row>
                        <Col className="center-content" sm={12} md={12} lg={6} xl={6}>
                            <p>Didn't receive the email? The PIN has expired</p><br/>
                            <Button variant="primary" name="resend" onClick={handleResend} type="button">Resend Email</Button>
                        </Col>
                    </Row>
                </Container>
                }
            </Form>
        </BlockUi>
    );
}

export default EmailVerificationForm;