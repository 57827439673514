// Page urls
export const ROUTE_HOME = '/';
export const ROUTE_PRODUCTS = '/products';
export const ROUTE_LOGIN = '/login';
export const ROUTE_SIGNIN = '/signup';
export const ROUTE_ACCOUNT_STATUS = '/account-status';
export const ROUTE_TERMS_AND_CONDITION = '/terms-and-conditions';
export const ROUTE_FAQ = '/faq';
export const ROUTE_SETTINGS = '/account-settings';
export const ROUTE_SETTINGS_AND_PREFERENCE = '/account-settings/settings-and-preferences';
export const ROUTE_ACCOUNT_INFORMATION = '/account-settings/account-information';
export const ROUTE_NOTIFICATION_AND_PREFERENCE = '/account-settings/notifications-preferences';
export const ROUTE_ORDERS = '/my-orders';
export const ROUTE_INVOICES = '/my-invoices';
export const ROUTE_RECEIPTS = '/my-receipts';
export const ROUTE_ABOUT_US = '/about-us';
export const ROUTE_GETTING_STARTED = 'about-us/#getting_started';
export const ROUTE_CONTACT_US = '/contact-us';
export const ROUTE_BRANDS = '/brands';