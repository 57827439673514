import React from 'react';
import {Link} from 'react-router-dom'
import OrderApi from '../../../api/Order';
import PriceFormat from "../../../utils/PriceFormat";
import {Table} from "react-bootstrap";

class OrderListPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            message:''
        };
    }

    componentDidMount() {
        OrderApi.list().then(response => {
            let orders = response.data.orders || [];
            this.setState({orders: orders});
            if(this.state.orders.length == 0) {
               this.setState({message:"You don't have any previous orders to display!"})
            }
        }).catch(err => {
            this.setState({order: []});
            console.warn(err);
        });
        window.scrollTo(0, 0);
    }

    render() {

        return(
            <div className="order-list-container">
                <Table className="main-cart-table" hover>
                    <thead className="thead-light">
                        <tr>
                            <th scope="col" width="35%">ORDER #</th>
                            <th scope="col" width="20%" className="">DATE</th>
                            <th scope="col" width="10%">STATUS</th>
                            <th scope="col" width="15%" className="text-end">ORDER TOTAL</th>
                            <th scope="col" width="15%"/>
                        </tr>
                    </thead>

                    <tbody>
                        {this.state.orders.map((order, key) => {
                            return <tr key={key}>
                                <td>{order.orderNumber}</td>
                                <td>{order.createdAtFormatted}</td>
                                <td>{order.externalStatus}</td>
                                <td className="text-end"><PriceFormat price = {order.totalAmount}/></td>
                                <td  className="text-end">
                                    <Link to={`/my-order/${order.id}`}>View</Link>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table>
                <div style={{textAlign:'center'}}><b>{this.state.message}</b></div>
            </div>
        );
    }
}

export default OrderListPage;