import {combineReducers} from 'redux';

import UserReducer from './customer/UserReducer';
import products from './customer/ProductReducer';
import cart from './customer/CartReducer';

import ErrorReducer from './common/ErrorReducer';
import popup from './common/PopupReducer';
import filters from './common/FilterReducer';
import pagination from './common/PaginationReducer';
import isLoading from './common/LoaderReducer';


const RootReducer = combineReducers({
    UserReducer,
    products,
    ErrorReducer,
    cart,
    popup,
    filters,
    pagination,
    isLoading,
});

export default RootReducer;