import React, { useEffect, useState } from 'react';
import BlockTitle from '../../common/FormTitle';
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import PriceFormat from "../../../utils/PriceFormat";
import { getDateTimeStr } from "../../../utils/helper";
import InvoiceAPI from "../../../api/Invoice";
import ReceiptAPI from "../../../api/Receipt";
import download from "downloadjs";
import NotificationManager from "react-notifications/lib/NotificationManager";

const InvoiceDetailPage = (props) => {

    const [invoice, setInvoice] = useState({});
    const history = useHistory();
    const [deliveredImages, setDeliveredImages] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        InvoiceAPI.get(props.match.params.invoiceId).then(response => {
            let data = response.data.data;
            setInvoice(data);
        }).catch(err => {
            console.error(err);
        });
    }, []);

    useEffect(() => {
        if (invoice.id) {
            const images = invoice.order.orderItems.map((item) => item.brandImage.bwImagePath);
            setDeliveredImages(images);
        }
    }, [invoice]);

    const handleBack = () => {
        history.push("/my-invoices");
    }

    const handleDownload = () => {
        InvoiceAPI.download(invoice.id).then(response => {

            const data = response.data || {};
            const content = response.headers['content-type'];

            if (data || data.data != null) {
                download(response.data, 'invoice' + invoice.id, content)
            } else {
                NotificationManager.error(data.message);
            }
        }).catch(err => {
            NotificationManager.error(err.response.data.message || "An error occurred");
        })
    }

    return (
        <>
            {invoice.id &&
                <div className="order-details-container">
                    <h1>Invoice #{invoice.invoiceNumber} <span className="order-status">{invoice.status.replace(/_/g, ' ')}</span>
                        {invoice.pdfGcpPath !== null &&
                            <>
                                &nbsp;
                                <Button variant="success" type="button" onClick={handleDownload}>Download PDF</Button>
                            </>
                        }
                        <span className="order-date">Invoice Date: {getDateTimeStr(invoice.createdAt)}</span> </h1>

                    <table className="table table-borderless order-items-table">
                        <thead>
                            <tr>
                                <th scope="col">Product</th>
                                <th scope="col">Delivered brand image</th>
                                <th className="text-end" scope="col">Price</th>
                                <th className="text-end" scope="col">Qty</th>
                                <th className="text-end" scope="col">Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                invoice.order.orderItems.map((item, key) => {

                                    let itemPrice;
                                    let subTotal;
                                    let currentBrandImagePath = item.brandImage.bwImagePath;
                                     
                                    if (item.product.sellingMethod === "AUCTION") {
                                        itemPrice = item.product.productAuction.bids.find(bid => bid.customerId === invoice.order.customerId).bidValue;
                                        subTotal = itemPrice;
                                    } else {
                                        itemPrice = item.price;
                                        subTotal = item.qtyOrdered * item.price;
                                    }

                                    return <tr key={key}>
                                        <td><a href={`/product/${item.product.id}`} target="_blank">{item.product.name}</a></td>
                                        <td><img src={currentBrandImagePath} height="60" alt='product image' /><br /></td>
                                        <td className="text-end"><PriceFormat price={itemPrice} /></td>
                                        <td className="text-end">{item.qtyOrdered}</td>
                                        <td className="text-end"><PriceFormat price={subTotal} /></td>
                                    </tr>
                                })
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <th className="text-end" colSpan="3">Grand Total</th>
                                <th className="text-end"><PriceFormat price={invoice.totalAmount} /></th>
                            </tr>
                            <tr>
                                <th className="text-end" colSpan="3">Amount Paid</th>
                                <th className="text-end"><PriceFormat price={invoice.totalAmount - invoice.balance} /></th>
                            </tr>
                            <tr>
                                <th className="text-end" colSpan="3">Balance</th>
                                <th className="text-end"><PriceFormat price={invoice.balance} /></th>
                            </tr>
                        </tfoot>
                    </table>

                    <div id="order-other-info">
                        <BlockTitle>Invoice Information</BlockTitle>
                        <table className="table table-borderless">
                            <tbody>
                                <tr>
                                    <th>Billing Address</th>
                                    <th>Order Date</th>
                                </tr>
                                <tr>
                                    <td>{invoice.billingAddress}</td>
                                    <td>{getDateTimeStr(invoice.order.createdAt)}</td>
                                </tr>
                                <tr>
                                    <th>Delivered image</th>
                                    <th>&nbsp;</th>
                                </tr>
                                <tr>
                                    <td className="brand-image-td">
                                        {Array.isArray(deliveredImages) && deliveredImages.map((image, index) => (
                                            <div key={index} className="image-container">
                                                <img className="brand-image" src={image} alt={`Delivered image ${index}`} />
                                            </div>
                                        ))}
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <Button variant="primary" type="button" onClick={handleBack}>Back to My Invoices</Button>
                </div>
            }
        </>
    );
}

export default InvoiceDetailPage;