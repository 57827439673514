import React, {useState} from 'react';
import {Form, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const SignupForm = (props) => {

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePassword = () => {
        setShowPassword(!showPassword);
    }

    const toggleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    return (
        <Form onKeyPress={props.handleEnterKey}>
            <Form.Group className="form-group">
                <Form.Label>Brand <span className="requiredStar">*</span></Form.Label>
                <Form.Control name="brandName" type="text" placeholder="Your brand name" onChange={props.handleChange}/>
                <Form.Text className="text-danger">
                    {props.validator.message('brandName', props.data.brandName, 'required|brandLengthValidate|nullValidate')}
                </Form.Text>
            </Form.Group>

            <Form.Group className="form-group">
                <Form.Label>Brand Description<span className="requiredStar">*</span></Form.Label>
                <Form.Control name="brandDescription" type="text" placeholder="Your brand description" onChange={props.handleChange}/>
                <Form.Text className="text-danger">
                    {props.validator.message('brandDescription', props.data.brandDescription, 'required|brandLengthValidate|nullValidate')}
                </Form.Text>
            </Form.Group>

            <Form.Group className="form-group">
                <Form.Label>Company <span className="requiredStar">*</span></Form.Label>
                <Form.Control name="companyName" type="text" onChange={props.handleChange}/>
                <Form.Text className="text-danger">
                    {props.validator.message('companyName', props.data.companyName, 'required|comapnyLengthValidate|nullValidate')}
                </Form.Text>
            </Form.Group>

            <Form.Group className="form-group">
                <Form.Label>Email <span className="requiredStar">*</span></Form.Label>
                <Form.Control name="email" type="email" onChange={props.handleChange}/>
                <Form.Text className="text-danger">
                    {props.validator.message('email', props.data.email, 'required|email|emailLengthValidate')}
                </Form.Text>
            </Form.Group>

            <Form.Group className="form-group">
                <Form.Label>Password <span className="requiredStar">*</span></Form.Label>
                <div className="d-flex">
                    <Form.Control name="password" type={showPassword ? "text" : "password"} onChange={props.handleChange}/>
                    &nbsp;
                    <Button className="float-end border" variant="light" type="button" onClick={togglePassword}>
                        <FontAwesomeIcon icon={showPassword ? "eye" : "eye-slash"}/>
                    </Button>
                </div>
                <Form.Text className="text-danger">
                    {props.validator.message('password', props.data.password, 'required|passwordLengthValidate|nullValidate')}
                </Form.Text>
            </Form.Group>

            <Form.Group className="form-group">
                <Form.Label>Confirm Password <span className="requiredStar">*</span></Form.Label>
                <div className="d-flex">
                    <Form.Control name="confirmPassword" type={showConfirmPassword ? "text" : "password"} placeholder="Confirm Password" onChange={props.handleChange}/>
                    &nbsp;
                    <Button className="float-end border" variant="light" type="button" onClick={toggleConfirmPassword}>
                        <FontAwesomeIcon icon={showConfirmPassword ? "eye" : "eye-slash"}/>
                    </Button>
                </div>
                    <Form.Text className="text-danger">
                        {props.validator.message('confirmPassword', props.data.confirmPassword, `required|passwordValidate:${props.data.password}|passwordLengthValidate|nullValidate`)}
                        {/* {!props.validator.fieldValid('confirmPassword') && 'Password mismatched'} */}
                    </Form.Text>
            </Form.Group>

            <Form.Group className="form-group">
                <Form.Label>Terms and Conditions <span className="requiredStar">*</span></Form.Label>
                <Form.Check
                    name="termsAndConditions"
                    label={<div>I agree to the <a href={"terms-and-conditions"} className={"terms-and-conditions"} target={"_blank"}>Terms and Conditions</a></div>}
                    onClick={props.handleChange}
                />
                <Form.Text className="text-danger">
                    {props.validator.message('termsAndConditions', props.data.termsAndConditions, 'checkboxValidate:true|required')}
                </Form.Text>
            </Form.Group>

            <Button variant="primary" type="button" onClick = {props.handleClick} disabled = {!props.data.brandName || !props.data.companyName || !props.data.email || !props.data.password || !props.data.confirmPassword || props.data.termsAndConditions != "true"} >
                Signup
            </Button>
        </Form>
    );
}

export default SignupForm;