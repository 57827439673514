import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { setPopupMetaData } from '../../../actions/common/PopupActions';
import BrandImageApi from '../../../api/BrandImage';
import BrandsApi from '../../../api/Brands';
import UploadedImages from './UploadedImages';
import './ManageImage.scss';

const ManageBrands = (props) => {

    const {
        neededBrandId,
    } = props;

    const [activeTab, setActiveTab] = useState('1');
    const [savedImages, setSavedImages] = useState([]);
    const [primaryBtnText, setPrimaryBtnText] = useState('');
    const [brandNames, setBrandNames] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState('');
    const [brands, setBrands] = useState([]);
    const [defaultImageId, setDefaultImageId] = useState(null);
    const [defaultBrand, setDefaultBrand] = useState([]);

    const tabPanes = [
        { tabId: '1', title: 'Approved Images' },
    ]

    useEffect(() => {
        getBrandNames();
        setPopupMetaData(activeTab);
    }, [primaryBtnText]);

    useEffect(() => {
        if (activeTab === '1') {
            getApprovedImages();
        }
    }, [selectedBrand, activeTab]);


    const toggle = tab => {
        setPopupMetaData(tab);
        if (activeTab !== tab) setActiveTab(tab);
    }

    const getBrandNames = () => {
        BrandsApi.getBrands().then(response => {
            const extractedBrands = response.data.data
                .filter(brand => brand.imageStatus == 1 ||  brand.imageStatus == 2)
                .map(brand => ({
                    id: brand.brand.id,
                    name: brand.brand.brandName,
                    isDefault: brand.brand.isDefault,
                }));
            setBrands(extractedBrands);
            const extractedBrandNames = extractedBrands.map(brand => brand.name);
            setBrandNames(extractedBrandNames);

            const defaultBrand = extractedBrands.find(brand => brand.isDefault === 1);

            if (defaultBrand) {
                setDefaultBrand(defaultBrand)
                setSelectedBrand(defaultBrand.name);
            } else {
                setSelectedBrand(extractedBrands[0].name);
            }
        }).catch(err => {
 
        });
    }

    const findBrandIdByName = (name) => {
        const selectedBrand = brands.find((brand) => brand.name === name);
        if (selectedBrand) {
            return selectedBrand.id;
        }
        return null;
    }

    const getApprovedImages = () => {
        if (findBrandIdByName(selectedBrand)) {
            BrandImageApi.getApprovedImages(findBrandIdByName(selectedBrand)).then(response => {
                const approvedImages = response.data || [];
                const defaultImage = approvedImages.find(image => image.isDefaultImage === 1);

                if (defaultImage) {
                    setDefaultImageId(defaultImage.id);
                }

                setSavedImages(approvedImages);
            }).catch(err => {
                
            })
        }

    }
    
    const onChangeDefault = () => {
        
    }

    const setPopupMetaData = (tab) => {
        if (tab == 1) { // Approved Images
            setPrimaryBtnText('Set as default');
            props.setPopupMetaData({ activeTab: tab, primaryBtnText: primaryBtnText, secondaryBtnText: 'Close', primaryBtn: { action: () => { } } });
        }
    }

    const renderTabContent = (props) => {
        return (
            <NavItem key={props.tabId}>
                <NavLink
                    className={classnames({ active: activeTab === props.tabId })} onClick={() => { toggle(props.tabId); }}>
                    {props.title}
                </NavLink>
            </NavItem>
        );
    }

    const handleBrandChange = (event) => {
        const newSelectedBrand = event.target.value;
        setSelectedBrand(newSelectedBrand);
    }

    return (
        <div>
            <div className='brand-text'>
                Brand &nbsp;&nbsp;&nbsp;
                <select
                    value={selectedBrand}
                    onChange={handleBrandChange}

                >
                    {brandNames.map((brand) => (
                        <option key={brand} value={brand}>
                            {brand}
                        </option>
                    ))}
                </select>
            </div>
            <hr />
            <Nav tabs>
                {
                    tabPanes.map((tabPane, key) => {
                        return renderTabContent({ ...tabPane })
                    })
                }
            </Nav>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <UploadedImages images={savedImages} flag={'approved'} brandId={findBrandIdByName(selectedBrand)} defaultBrandId={defaultBrand.id} defaultImageId={defaultImageId} onChangeDefault={onChangeDefault} />
                </TabPane>
            </TabContent>
        </div>
    );
}
const mapDispatchToProps = (dispatch) => {
    return {
        setPopupMetaData: (metadata) => { dispatch(setPopupMetaData(metadata)) }
    }
}

export default connect(null, mapDispatchToProps)(ManageBrands);
