import React from "react";
import Clock from './Clock';
import {checkProductIsExpired, checkUserStatus} from '../../../utils/helper';
import defaultProductImage from '../../../assets/Default_Product_Image.png';
import Bid from './Bid';
import AddToCart from './AddToCart';
import './ProductCard.scss';
import UserIcon from '../../../assets/icons/user.png';
import UsersIcon from '../../../assets/icons/users.png';
import ReactTooltip from "react-tooltip";
import PriceFormat from "../../../utils/PriceFormat";
import {Card, Col} from "react-bootstrap";
import PopupMessage from './PopupMessage';

class ProductCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: "",
      bidAmount: "",
      placeBidButtonText: "Place Bid",
      addToCartButtonText: "Add to Cart",
      bidError: "",
      isSuspendedModalOpen: false,
      warningMessages: [],
    };
  }

  openSuspendedModal = (suspendedMessage, goToLink, buttonText, alternativeAction) => {
    this.setState({
      isSuspendedModalOpen: true,
      suspendedMessage: suspendedMessage,
      goToLink: goToLink,
      buttonText: buttonText,
      alternativeAction: alternativeAction,
    });
  };
    
  closeSuspendedModal = () => {
    this.setState({ isSuspendedModalOpen: false });
  };

  handleChange = (e) => {
        this.setState({bidError: ''});
        this.setState({bidAmount: e.target.value});
  };

  renderBidCount = () => {
    const bidCount = Number.parseInt(this.props.product.bidCount || 0);
    const bidLabel = bidCount + (bidCount > 1 ? ' bids' : ' bid');

    return (
      <span>({bidLabel})</span>
    )
  }

  renderNextBidAmount = () => {
    let nextBidValue = this.props.product.currentPrice + this.props.product.minBidIncrement;
        return <span>Enter <PriceFormat price = {nextBidValue}/>or more</span>
  }

  showPrice = () => {
    if (this.props.product.sellingMethod === 'AUCTION') {
            return (<>Current Price: <PriceFormat price = {this.props.product.currentPrice}/></>);
    } else {
            return (<>Price: <PriceFormat price = {this.props.product.price}/></>);
    }
  }

  handleClickCard = (event) => {
        if(!(event.target.name === 'addToCart' ||
        event.target.name === 'bid' ||
        event.target.name === 'bidAmount' ||
        event.target.name === 'nextBid' ||
        event.target.className === 'priceFormat'
      )
    ) {
      const userStatus = checkUserStatus(this.props.userStatus);

      if (userStatus.length > 0) {
        const status = userStatus;
        let warnings = '';

        if (status.includes('not-loggedin')) {
          const suspendedMessage = 'To purchase ads you need to be a registered user and logged in. If you are new to Divolgo please register.';
          const goToLink = '/login';
          const buttonText = 'Login';
          this.openSuspendedModal(suspendedMessage, goToLink, buttonText);
          return;
        }
        if (status.includes('acc-suspended')) {
          const suspendedMessage = 'Your account has been suspended please contact admin for further information.';
          const goToLink = '/account-status';
          const buttonText = 'Contact';
          this.openSuspendedModal(suspendedMessage, goToLink, buttonText);
          return;
        } 
        if (status.includes('acc-not-approved')) {
            if(status.length == 2){
                const suspendedMessage = "Contact administrator if it has been more than 3 days since you completed account registraton process.";
                const goToLink = '/contact-us';
                const buttonText = 'Contact';
                this.openSuspendedModal(suspendedMessage, goToLink, buttonText);
                return;    
            }else{
                const suspendedMessage = "You haven't completed account registration process. Please go to Account status page and complete all reqiurements.";
                const goToLink = '/account-status';
                const buttonText = 'Account status';
                this.openSuspendedModal(suspendedMessage, goToLink, buttonText);
                return;
            }
        }
        if (status.includes('no-uploaded-image')) {
            const suspendedMessage = 'You need to upload a logo image and set it as the default logo before an ad can be purchased.';
            const goToLink = '/brands';
            const buttonText ='upload';
            this.openSuspendedModal(suspendedMessage, goToLink, buttonText, );
            return;
        }
        if (status.includes('no-default-image')) {
          const suspendedMessage = 'Please set a default logo image in your account settings.';
          const goToLink = '/brands';
          const buttonText ='set default';
          this.openSuspendedModal(suspendedMessage, goToLink, buttonText, );
          return;
        }
        if (status.includes('not-approved')) {
            const suspendedMessage = 'Your logo image is not approved yet.  Contact administrator if it has been more than 3 days since your upload.';
            const goToLink = '/contact-us';
            const buttonText ='contact';
            this.openSuspendedModal(suspendedMessage, goToLink, buttonText, );
            return;
          }
        if (status.includes('image-rejected')) {
            const suspendedMessage = 'Your logo image has been rejected. Please upload a new image.';
            const goToLink = '/brands';
            const buttonText ='upload';
            this.openSuspendedModal(suspendedMessage, goToLink, buttonText, );
            return;
        }
        if (status.includes('no-email-verification')) {
            const suspendedMessage = 'Your email must be verified to progress through the registration process. Please check your email and follow instructions.<br />If you haven’t received an email, please select verify option below.';

          const goToLink = '/account-settings/settings-and-preferences';
          const buttonText = 'verify';
          this.openSuspendedModal(suspendedMessage, goToLink, buttonText);
          return;
        }
        if (status.includes('no-address')) {
          const suspendedMessage = 'Your profile information is incomplete. Please update your details.';
          const goToLink = '/account-settings/account-information';
          const buttonText = 'Edit profile';
          this.openSuspendedModal(suspendedMessage, goToLink, buttonText);
          return;
        }
        if (status.includes('no-payment-method')) {
          const suspendedMessage = 'A payment method has to be set up in your account. Please select add payment method option below or go to account settings in your profile page.';
          const goToLink = '/account-settings/settings-and-preferences';
          const buttonText = 'add method';
          this.openSuspendedModal(suspendedMessage, goToLink, buttonText);
          return;
        }
      } else {
        this.props.handleClickImage(this.props.product.id);
      }
    }
  };

  render() {
    const isAuction = this.props.product.sellingMethod === 'AUCTION';
    const clockTitle = this.props.product.sellingMethod === 'AUCTION' ? 'Auction closes in :' : 'Product expires in :';
    const isExpired = checkProductIsExpired(this.props.product);
    const cardImagePath = this.props.product.productImagePath !== null ? this.props.product.productImagePath : defaultProductImage;
    
    return (
      <>
        {!isExpired && (
          <Col sm={12} md={6} lg={4} xl={3} className="col-card">
            <Card className="product-card" onClick={this.handleClickCard}>
              <Card.Img
                className="product-image"
                top
                width="100%"
                src={cardImagePath}
                alt={this.props.title}
              />
              {this.props.product.sellingMethod === 'AUCTION' &&
                            <><Card.Title className="ribbon ribbon-top-left" data-tip data-for={this.props.product.numOfWinners > 1 ? "auctionMultiTip" : "auctionSingleTip"} >
                    <span>
                      {this.props.product.numOfWinners > 1 ? <img src={UsersIcon} height="16px" alt="users icon"/> :
                        <img src={UserIcon} height="16px" alt="user icon"/>
                      }
                      &nbsp;&nbsp;AUCTION
                    </span>
                  </Card.Title>
                  <ReactTooltip id="auctionSingleTip" place="top" effect="solid">
                    Single Winner Auction
                  </ReactTooltip>
                  <ReactTooltip id="auctionMultiTip" place="top" effect="solid">
                    Multi-Winner Auction
                  </ReactTooltip>
                </>
              }
              <Card.Body className="d-flex flex-column">
                <Card.Text as="div" className="mt-auto">
                  <Card.Title>{this.props.title}</Card.Title>
                  <Card.Text>{this.props.description}</Card.Text>

                  <Card.Text as="div" >
                                        <Clock title = {clockTitle} time={this.props.product.endAt}/>
                  </Card.Text>

                  <Card.Text className="current-price-wrap" as="div">
                    {this.showPrice()}
                  </Card.Text>
                  <Card.Text className="bid-count-wrap" as="div" >
                    {isAuction && ( this.renderBidCount() )}
                  </Card.Text>

                  <Card.Text className="productButtons" as="div" >
                    {
                      isAuction ?
                        <Bid
                                                    userStatus = {checkUserStatus(this.props.userStatus)}
                                                    product = {this.props.product}
                                                    disabled={(checkUserStatus(this.props.userStatus)).length > 0}
                        />
                        :
                        <AddToCart
                                                    userStatus = {checkUserStatus(this.props.userStatus)}
                                                    product = {this.props.product}
                                                    disabled={(checkUserStatus(this.props.userStatus)).length > 0}
                        />
                    }
                  </Card.Text>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        )}
        {this.state.isSuspendedModalOpen && (
          <PopupMessage
            open={this.state.isSuspendedModalOpen}
            onClose={this.closeSuspendedModal}
            suspendedMessage={this.state.suspendedMessage}
            goToLink={this.state.goToLink}
            buttonText={this.state.buttonText}
            alternativeAction={this.state.alternativeAction}
          />
        )}
      </>
    );
  }
}

export default ProductCard;