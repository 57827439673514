import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import logo from '../../../../assets/logo/logo.svg';
import storage from '../../../../utils/storage';
import {Navbar, Nav, NavDropdown, Container, Row} from 'react-bootstrap'
import {logout} from '../../../../actions/UserActions'
import MiniCart from './MiniCart';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Col, Input, InputGroup} from "reactstrap";
import {clearProductFilters, filterProducts, productPagination} from "../../../../actions/common/FilterActions";
import {useLocation} from "react-router-dom";
import {
    ROUTE_HOME,
    ROUTE_PRODUCTS,
    ROUTE_LOGIN,
    ROUTE_SIGNIN,
    ROUTE_ACCOUNT_INFORMATION,
    ROUTE_ACCOUNT_STATUS,
    ROUTE_FAQ,
    ROUTE_INVOICES,
    ROUTE_NOTIFICATION_AND_PREFERENCE,
    ROUTE_ORDERS,
    ROUTE_RECEIPTS,
    ROUTE_SETTINGS,
    ROUTE_SETTINGS_AND_PREFERENCE,
    ROUTE_TERMS_AND_CONDITION, 
    ROUTE_ABOUT_US,
    ROUTE_GETTING_STARTED,
    ROUTE_CONTACT_US,
    ROUTE_BRANDS  
} from '../../../../constants/RouteConstants';

const MainNavBar = (props) => {

    const [keyword, setKeyword] = useState([]);
    const [redirect, setRedirect] = useState('');
    const [userData, setUserData] = useState({});
    const location = useLocation();
    const aboutUsUrls = [
        ROUTE_ABOUT_US ,
        ROUTE_TERMS_AND_CONDITION,
        ROUTE_FAQ,
        '/about-us/'
      ];

      const userUrls = [
        ROUTE_SETTINGS,
        ROUTE_SETTINGS_AND_PREFERENCE,
        ROUTE_ACCOUNT_INFORMATION,
        ROUTE_NOTIFICATION_AND_PREFERENCE,
        ROUTE_ORDERS,
        ROUTE_INVOICES,
        ROUTE_RECEIPTS,
        ROUTE_BRANDS
      ];

      const accountDashboardUrls = [
        ROUTE_SETTINGS,
        ROUTE_SETTINGS_AND_PREFERENCE,
        ROUTE_ACCOUNT_INFORMATION,
        ROUTE_NOTIFICATION_AND_PREFERENCE,
      ];

    useEffect(() => {
        setUserData(JSON.parse(storage.getUserData()) || {});
    }, []);

    const handleLogout = () => {
        props.actions.logout();
        setRedirect("/login");
    }

    const userNavTitle = (
        <span className="nav-drop-down"><FontAwesomeIcon icon="user"/>
            &nbsp;&nbsp;
            {userData.id &&
            <>{userData.companyName}</>
            }
        </span>
    );

    const handleChangeSearch = (e) => {
        setKeyword(e.target.value);
        props.resetPagination({page: "", pageSize: ""});
    };

    const handleClickSearch = () => {
        keyword.length > 0 && props.filterProducts({keyword});
    };

    const handlePressEnterKey = (e) => {
        e.key === "Enter" && props.filterProducts({keyword});
    };

    const renderSearchFilter = (child) => {
        return (
            <Col xs="4" className="filter-item-wrap">
                {child}
            </Col>
        );
    };

    return (
        <Container className="outer-nav-container">
            <Row>
                {redirect && (window.location.href = redirect)}
                <Navbar className="nav-container" expand="lg">
                    <Container fluid>
                        <Navbar.Brand href="/">
                            <img height="60"
                                 src={logo}
                                 alt="Divolgo Sports"
                                 className="brand-image img-circle elevation-3"
                                 style={{opacity: '.8'}}
                            />
                        </Navbar.Brand>

                        {location.pathname === ROUTE_PRODUCTS &&
                        <>
                            {renderSearchFilter(
                                <InputGroup className="search-group">
                                    <Input
                                        name="keyword"
                                        value={keyword}
                                        className="keyword-search"
                                        onChange={handleChangeSearch}
                                        onKeyDown={handlePressEnterKey}
                                        placeholder="Search Products"
                                    />
                                    <Button className="search-button" size="xs" onClick={() => handleClickSearch()}>
                                        <FontAwesomeIcon icon="search"/>
                                    </Button>
                                </InputGroup>
                            )}
                        </>
                        }

                        <Navbar.Toggle aria-controls="navbarScroll"/>
                        <Navbar.Collapse className="nav-collapse" id="navbarScroll">
                            <Nav
                                className="me-auto my-2 my-lg-0"
                                style={{maxHeight: '100px'}}
                                navbarScroll
                            >
                                <Nav.Link href= {ROUTE_HOME} 
                                    style={{ fontWeight: location.pathname === ROUTE_HOME ? 'bold' : 'normal',}}>
                                    Home
                                </Nav.Link>

                                <Nav.Link href={ROUTE_PRODUCTS}
                                style={{ fontWeight: location.pathname === ROUTE_PRODUCTS ? 'bold' : 'normal',}}>Products</Nav.Link>

                                <NavDropdown title={<span className="nav-drop-down"
                                        style={{ fontWeight: aboutUsUrls.includes(location.pathname) ? 'bold' : 'normal',}}>
                                        About&nbsp;Us</span>} 
                                    id="navbarScrollingDropdown">
                                    <NavDropdown.Item href={ROUTE_ABOUT_US}
                                        style={{ fontWeight: location.pathname === ROUTE_ABOUT_US ? 'bold' : 'normal',}}>
                                            What&nbsp;is&nbsp;Divolgo?
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href={ROUTE_GETTING_STARTED}
                                        style={{ fontWeight: location.pathname === '/about-us/' ? 'bold' : 'normal',}}>
                                            Getting&nbsp;Started
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item href={ROUTE_TERMS_AND_CONDITION}
                                        style={{ fontWeight: location.pathname === ROUTE_TERMS_AND_CONDITION ? 'bold' : 'normal',}}>
                                            Terms&nbsp;and&nbsp;Conditions
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item href={ROUTE_FAQ}
                                        style={{ fontWeight: location.pathname === ROUTE_FAQ ? 'bold' : 'normal',}}>
                                            FAQ
                                    </NavDropdown.Item>
                                </NavDropdown>

                                <Nav.Link href={ROUTE_CONTACT_US}
                                    style={{ fontWeight: location.pathname === ROUTE_CONTACT_US ? 'bold' : 'normal',}}>
                                    Contact&nbsp;Us
                                </Nav.Link>

                                {!userData.id && <Nav.Link href={ROUTE_SIGNIN}
                                    style={{ 
                                        fontWeight:location.pathname === ROUTE_LOGIN || location.pathname === ROUTE_SIGNIN ? 'bold' : 'normal',}}>
                                        Sign&nbsp;In&nbsp;or&nbsp;Register
                                </Nav.Link>}

                                {userData.id && (
                                    <NavDropdown title={userNavTitle} id="basic-nav-dropdown" 
                                        style={{ fontWeight: userUrls.includes(location.pathname) ? 'bold' : 'normal',}}>
                                        <NavDropdown.Item href={ROUTE_SETTINGS}
                                        style={{ fontWeight: accountDashboardUrls.includes(location.pathname) ? 'bold' : 'normal',}}>
                                                My&nbsp;Account
                                        </NavDropdown.Item>
                                        <NavDropdown.Item href={ROUTE_BRANDS}
                                        style={{ fontWeight: location.pathname === ROUTE_BRANDS ? 'bold' : 'normal',}}>
                                                Brands
                                        </NavDropdown.Item>
                                        <NavDropdown.Item href={ROUTE_ORDERS}
                                            style={{ fontWeight: location.pathname === ROUTE_ORDERS ? 'bold' : 'normal',}}>
                                                Orders
                                        </NavDropdown.Item>
                                        <NavDropdown.Item href={ROUTE_INVOICES}
                                            style={{ fontWeight: location.pathname === ROUTE_INVOICES ? 'bold' : 'normal',}}>
                                                Invoices
                                        </NavDropdown.Item>
                                        <NavDropdown.Item href={ROUTE_RECEIPTS}
                                            style={{ fontWeight: location.pathname === ROUTE_RECEIPTS ? 'bold' : 'normal',}}>
                                                Receipts
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider/>
                                        <NavDropdown.Item onClick={handleLogout}>Sign&nbsp;Out</NavDropdown.Item>
                                    </NavDropdown>
                                )}
                                <MiniCart/>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Row>
        </Container>
    )
};

const mapDispatchToProps = dispatch => {
    return {
        filterProducts: (options) => {
            dispatch(filterProducts(options));
        },
        resetPagination: (options) => {
            dispatch(productPagination(options));
        },
        cleaFilters: () => {
            dispatch(clearProductFilters());
        },
        actions: {
            logout: () => {
                dispatch(logout())
            },
        }
    }
}

export default connect(null, mapDispatchToProps)(MainNavBar);