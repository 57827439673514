import React from 'react';
import './Footer.scss';
import {Col, Container, Row, Table} from "react-bootstrap";
import logoWhite from '../../../assets/logoWhite.png'
import * as Constants from '../../../constants/RouteConstants';

const Footer = () => {

    return (
        <div className="footer">
            <Container className="footer-container">
                <Row>
                    <Col xs={12} sm={7} md={5} lg={5} xl={5}>
                        <div>
                            <div>
                                <img src={logoWhite} width="150px" alt="divolgo logo"/>
                            </div>
                            <div>
                                <p className="footer-text">
                                    By accessing, continuing to use or navigating throughout this site you accept that we will
                                    use certain browser cookies to improve your customer experience with us. Divolgo only uses
                                    cookies which will improve your experience with us and will not interfere with your privacy.
                                    Please refer to our
                                    <u><a className="footer-text" href={"/privacy-and-cookie-policy"}>
                                        Privacy&nbsp;and&nbsp;Cookie&nbsp;Policy
                                    </a></u>
                                    for further information on our use of cookies and how
                                    you can disable or manage their use should you wish.
                                </p>
                            </div>
                        </div>
                    </Col>

                    <Col className="links-col" md="auto">
                        <div className="mid-container links">
                            <div>
                                <h1>Quick&nbsp;Links</h1>
                            </div>
                            <div>
                                <Table borderless className="topic-text">
                                    <tbody>
                                    <tr>
                                        <td><a href={Constants.ROUTE_HOME} className="active">Home</a></td>
                                        <td><a href={Constants.ROUTE_PRODUCTS}>Products</a></td>
                                    </tr>
                                    <tr>
                                        <td><a href={"/account-settings"}>My Account</a></td>
                                        <td><a href={"/account-settings/account-information"}>Account Information</a></td>
                                    </tr>
                                    <tr>
                                        <td><a href={"/my-orders"}>My Orders</a></td>
                                        <td><a href={"/about-us"}>About Us</a></td>
                                    </tr>
                                    <tr>
                                        <td><a href={"/faq"}>FAQ</a></td>
                                        <td><a href={"/contact-us"}>Contact Us</a></td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>

                    <Col className="contact-col" md="auto">
                        <div className="links max-content">
                            <div>
                                <h1>Contact Us</h1>
                            </div>
                            <div>
                                <Table borderless className="topic-text">
                                    <tbody>
                                    <tr>
                                        <td>Address</td>
                                        <td>:</td>
                                        <td>
                                            <a href="https://www.google.lk/maps/place/9+Horton+Pl,+Colombo+00700/@6.9118413,79.8652893,21z/data=!4m5!3m4!1s0x3ae259704cb5dcbf:0xc86e4b56e86b9877!8m2!3d6.9118541!4d79.8651175"  target="_blank">
                                                Techlabs Global Private Limited<br/>
                                                9B Horton Place<br/>
                                                Colombo 7, 00700<br/>
                                                Sri Lanka
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>E-Mail</td>
                                        <td>:</td>
                                        <td>
                                            <a href="mailto:support@divolgodigital.com">support@divolgodigital.com</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Telephone</td>
                                        <td>:</td>
                                        <td>
                                            <a href="tel:+94777915011">+94 777 915011</a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>

                </Row>
            </Container>
            <div className="f-copy">
                Copyright &copy; {new Date().getFullYear()} Divolgo. All rights reserved.
            </div>
        </div>
    );
}

export default Footer;