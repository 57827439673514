import React from 'react';
import './NotFoundPage.scss';
import logo from '../../assets/logo/logo.svg';
import {Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";

const NotFoundPage = (props) => {

    const history = useHistory();

    return (
        <>
            <div className="logoLarge">
                <img src={logo}
                     alt="Divolgo Sports"
                     className="logo-image"
                />
            </div>
            <div className="center-text">
                <h2>404 NOT FOUND</h2>
            </div>
            <div className="center-text">
                <h3> Sorry, We couldn't find the page you requested. </h3>
            </div>
            <div className="center-text">
                <Button variant="primary" id="backButton" type="button" onClick={history.goBack}>Back to the Previous Page</Button>
            </div>
        </>
    );
}

export default NotFoundPage;