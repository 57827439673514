import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { setPopupMetaData } from '../../../actions/common/PopupActions';
import BrandImageApi from '../../../api/BrandImage';
import BrandsApi from '../../../api/Brands';
import UploadeImage from './UploadImage';
import UploadedImages from './UploadedImages';
import './ManageImage.scss';

const MangeBrandImage = (props) => {

    const {
        neededBrandId,
        neededBrandName,
        defaultTab,
        onChangeDefault
    } = props;


    const [activeTab, setActiveTab] = useState('1');
    const [savedImages, setSavedImages] = useState([]);
    const [brandId, setBrandId] = useState('');
    const [primaryBtnText, setPrimaryBtnText] = useState('');
    const [defaultImageId, setDefaultImageId] = useState(null);

    const tabPanes = [
        {tabId: '1', title: 'Approved Images'},
        {tabId: '2', title: 'Pending Approval'},
        {tabId: '3', title: 'Upload New Image'},
        {tabId: '4', title: 'Rejected Images'},
    ]

    useEffect(() => {
        setBrandId(neededBrandId)
        setPopupMetaData(activeTab);
        if(defaultTab){
            setActiveTab('3');
        }
    }, [primaryBtnText]);

    useEffect(() => {
        switch (activeTab) {
            case '1':
                getApprovedImages();
                break;
            case '2':
                getPendingImages();
                break;
            case '4':
                getRejectedImages();
                break;
        }
    }, [activeTab]);
    

    const toggle = tab => {
        setPopupMetaData(tab);
        if (activeTab !== tab) setActiveTab(tab);
    }

    const getApprovedImages = () => {
        if(neededBrandId){
            BrandImageApi.getApprovedImages(neededBrandId).then(response => {
                const approvedImages = response.data || [];
                const defaultImage = approvedImages.find(image => image.isDefaultImage === 1);
    
                if (defaultImage) {
                    setDefaultImageId(defaultImage.id);
                }
                
                setSavedImages(approvedImages);
            }).catch(err => {
                
            })
        }
        
    }

    const getPendingImages= () => {
        if(neededBrandId){
            BrandImageApi.getPendingImages(neededBrandId).then(response => {
                setSavedImages(response.data || []);
            }).catch(err => {
                
            })
        }
    }

    const getRejectedImages = () => {
        if(neededBrandId){
            BrandImageApi.getRejectedImages(neededBrandId).then(response => {
                setSavedImages(response.data || []);
            }).catch(err => {
                
            })
        }
    }

    const setPopupMetaData = (tab) => {
        if (tab  == 1) { // Approved Images
            setPrimaryBtnText('Set as default image');
            props.setPopupMetaData({activeTab: tab, primaryBtnText: primaryBtnText, secondaryBtnText: 'Close', primaryBtn:  {action: () => {}} });

        } else if (tab == 2) { // Pending Images]
            setPrimaryBtnText('');
            props.setPopupMetaData({primaryBtnText: primaryBtnText, secondaryBtnText: 'Close', primaryBtn: {action: () => {}} });

        } else if (tab == 3) { //Upload new Image
            setPrimaryBtnText('Upload');
            props.setPopupMetaData({activeTab: tab, setActiveTab: setActiveTab, secondaryBtnText: 'Close', getPendingImages: getPendingImages, setPrimaryBtnText: setPrimaryBtnText, primaryBtnText: primaryBtnText, primaryBtn:  {action: () => {}} });

        } else if (tab == 4) { //Rejected Image
            setPrimaryBtnText('');
            props.setPopupMetaData({activeTab: tab, primaryBtnText: primaryBtnText, secondaryBtnText: 'Close', primaryBtn: {action: () => {}} });
        }
    }

    const renderTabContent = (props) => {
        return(
            <NavItem key={props.tabId}>
                <NavLink
                    className={classnames({ active: activeTab === props.tabId })} onClick={() => {toggle(props.tabId);}}>
                    {props.title}
                </NavLink>
            </NavItem>
        );
    }

    return (
        <div>
            <div className='brand-text'>
                Brand &nbsp; : &nbsp;&nbsp;
                {neededBrandName}
            </div>
            <hr />
            <Nav tabs>
                {
                    tabPanes.map((tabPane, key) => {
                        return renderTabContent({...tabPane})
                    })
                }
            </Nav>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <UploadedImages images={savedImages} flag={'approved'} brandId={brandId}  defaultBrandId={brandId} defaultImageId={defaultImageId} onChangeDefault={onChangeDefault} reloadImages={getApprovedImages} />
                </TabPane>

                <TabPane tabId="3" id="upload-new-image-tab">
                <UploadeImage brandId={neededBrandId} />
                </TabPane>

                <TabPane tabId="2">
                    <UploadedImages images={savedImages} flag={'pending'} reloadImages={getPendingImages} />
                </TabPane>

                <TabPane tabId="4">
                    <UploadedImages images={savedImages} flag={'rejected'} reloadImages={getRejectedImages}/>
                </TabPane>
            </TabContent>
        </div>
    );
}
const mapDispatchToProps = (dispatch) => {
    return {
        setPopupMetaData: (metadata) => { dispatch(setPopupMetaData(metadata)) }
    }
}

export default connect(null, mapDispatchToProps)(MangeBrandImage);
