import React from 'react';

class AboutUsPage extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <div>
                    <h4><a id="what_is_divolgo">What is Divolgo</a></h4><br/>
                    <p align="justify">
                        One of the greatest ironies in the world of professional sports is the simple fact that the
                        existing sponsorship model is grossly underperforming its potential. Even though the model is
                        the economic lifeblood of the sports business it is woefully antiquated in its capacity to
                        efficiently create optimal value for advertisers, the sponsored athletes or teams and their
                        enabling organizations, including the IPL. Billions of dollars are being left on the field.
                        <br/><br/>
                        With Divolgo and the advent of cutting-edge technology, the game changes, those dollars become
                        real and everybody wins!
                        <br/><br/>
                        Divolgo is an advanced patent-pending ad-tech platform that enables wearable athletic clothing
                        and equipment (e.g. caps, helmets, bags) to be updated in real-time to represent different
                        brands for an advertising segment (a complete match, 5-over period, a round of golf, etc.). Our
                        revolutionary technology transforms the age-old fixed “one brand, one team” annual sponsorship
                        approach into a variable play, allowing multiple advertisers to bid on segments as the game or
                        tournament unfolds. As you know, the current sponsorship space determines brand exposure value
                        based on a prior athlete or team performance. With Divolgo it becomes all about who’s winning
                        today and/or how to most effectively monetize every moment especially the big moments with the
                        highest ad revenue potential (a batsman closing in on a century, a team getting close to
                        winning, etc.) of advertising revenue.
                        <br/><br/>
                        Fundamentally, Divolgo is designed to Uberize the ad market by making each ad segment more
                        affordable while also greatly expanding the funnel of advertisers. We know that the mega teams
                        and their stars and a small number of major advertisers will continue to engage in long-term,
                        fixed contracts. But we also know that with our platform, we can selectively and without
                        conflict bring in additional major and mid-sized advertisers significantly expanding the overall
                        funnel to unleash significant value for all parties. We estimate that the potential gain in
                        sponsorship revenue annually could be as much as 2x-5x for the same relative advertising space.
                    </p>
                </div>
                <div>
                    <br/>
                    <h4><a id="getting_started">Getting Started</a></h4>
                    <br/>
                    <ol>
                        <li>
                            <p>
                                Go to '<u><a href={"/login"}>REGISTER</a></u>' page and set up your account with the
                                necessary information.
                            </p>
                        </li>
                        <li>
                            <p>Select a payment method:
                                <ul>
                                    <li>Select 'PayPal' for the automatic payments.</li>
                                    <li>Select 'Invoice' for check or money order payments.</li>
                                </ul>
                            </p>
                        </li>
                        <li>
                            <p>
                                Upload a default logo or image: this will be delivered to wearables when you purchase an
                                advertising slot or win an auction to advertise at a particular segment.
                            </p>
                        </li>
                        <li>
                            <p>
                                Now you're all set to Bid or Buy!
                            </p>
                        </li>
                    </ol>
                    <br/>
                </div>
            </div>
        );
    }

}

export default AboutUsPage;