import React, {useState} from "react";
import "./Page.scss";
import {Carousel} from "react-bootstrap";
import Image1 from "../../../assets/bannerImages/top-banner1.png"
import Image2 from "../../../assets/bannerImages/top-banner2.png"
import Image3 from "../../../assets/bannerImages/top-banner3.png"
import Image4 from "../../../assets/bannerImages/top-banner4.png"
import Image5 from "../../../assets/bannerImages/new-banner.jpg"

const PageHeader = ({children}) => {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return(
        <>
            <Carousel className="top-slides" activeIndex={index} onSelect={handleSelect}>
                <Carousel.Item interval={10000}>
                    <img
                        className="d-block w-100"
                        src={Image5}
                        alt="First slide"
                    />
                </Carousel.Item>
                {/* <Carousel.Item interval={10000}>
                    <img
                        className="d-block w-100"
                        src={Image2}
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item interval={10000}>
                    <img
                        className="d-block w-100"
                        src={Image3}
                        alt="Third slide"
                    />

                </Carousel.Item>
                <Carousel.Item interval={10000}>
                    <img
                        className="d-block w-100"
                        src={Image4}
                        alt="Fourth slide"
                    />

                </Carousel.Item> */}
            </Carousel>
            {children && (
                <div className="page-title">
                    <h2>{children}</h2>
                </div>
            )}
        </>
    );
}

export default PageHeader;