import axios from 'axios';
import {API_BASE_URL, API_ENDPOINT_PREFIX, API_INVOICE} from '../constants';
import httpHeaders from '../utils/httpHeaders';

const URL = API_BASE_URL + API_ENDPOINT_PREFIX + API_INVOICE;

class Invoice {

    list = () => {
        return axios.get(URL, {
            params: {date:null, status:null, minPrice:2000, maxPrice:null},
            headers: httpHeaders.authHeader().headers
        });
    }

    get = (invoiceId) => {
        let url = URL + '/' + invoiceId;
        return axios.get(url, httpHeaders.authHeader());
    }

    download = (invoiceId) => {
        return axios.get(URL + '/pdf/' + invoiceId, {
            headers: httpHeaders.authHeader().headers,
            responseType: 'blob'
        });
    }
}

export default new Invoice();