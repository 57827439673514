import React from 'react';
import './Page.scss';

const Page = ({children}) => {
    return (
        <div className="page">
            <div className="page__container">
                {children}
            </div>
        </div>
    )
};

export default Page;