import React, { useState, useCallback, useEffect } from 'react';
import BrandImageApi from '../../../api/BrandImage';
import { Button, Col, Container, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import BlockUi from 'react-block-ui';
import { BICOLOUR_OPTIONS, BW_OPTIONS, QUADCOLOUR_OPTIONS } from '../../../constants';
import './SelectColour.scss';

const ImageColourEditor = ({opener,
    className,
    title,
    addToCartAction,
    size,
    centered,
    backdrop,
    deviceColourScheme,
    originalImagePath,
    }) => {
    const [newImageUri, setNewImageUri] = useState(originalImagePath);
    const [colourInverted, setColourInverted] = useState(false);
    const [imageColour, setImageColour] = useState('BKWH'); // Default to 'BKWH'
    const [colourSchemes, setColourSchemes] = useState(BW_OPTIONS);

    const [modal, setModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const toggle = () => setModal(!modal);

    useEffect(()=>{

        switch(deviceColourScheme) {
            case "BICOLOUR":
                setColourSchemes(BICOLOUR_OPTIONS);
                break;
            case "QUADCOLOUR":
                setColourSchemes(QUADCOLOUR_OPTIONS);
                break;
            default:
                setColourSchemes(BW_OPTIONS);
        }
    },[setColourSchemes, deviceColourScheme]);

    const updateImageColor = useCallback(async (colorScheme, invertColor) => {
        setIsLoading(true);
        try {
            const data = {
                originalImagePath,
                imageColour: colorScheme,
                colourInverted: invertColor
            };

            const response = await BrandImageApi.changeColorFromUrl(data);
            setNewImageUri(response.data.imageUrl);
        } catch (error) {
            console.error('Error updating image color:', error);
        } finally {
            setIsLoading(false); 
        }
    }, [originalImagePath]);

    const handleColourInvertedCheck = useCallback(async (event) => {
        setColourInverted(event.target.checked);
        await updateImageColor(imageColour, event.target.checked);
    }, [imageColour, updateImageColor]);

    const handleDropdownChange = useCallback(async (event) => {
        setImageColour(event.target.value);
        await updateImageColor(event.target.value, colourInverted);
    }, [colourInverted, updateImageColor]);

    const handlePrimaryButton = useCallback(()=>{
        addToCartAction(newImageUri,imageColour,colourInverted);
        setModal(false);
    },[addToCartAction, colourInverted, imageColour, newImageUri]);


    return (
        <>
            <span onClick={toggle}>{opener}</span>
                <Modal size={size || 'lg'}  isOpen={modal} toggle={toggle} className={className}  centered={centered} backdrop={backdrop}>
                    <ModalHeader toggle={toggle}>{title}</ModalHeader>
                    <ModalBody>
                    <div className="image-colour-editor">
                        <div className="image-container">
                            {isLoading && (
                                <div className="loading-spinner">
                                    <Spinner color="secondary">Loading..</Spinner>
                                </div>
                            )}
                            <img 
                                src={newImageUri} 
                                alt="Editable" 
                                className={isLoading ? 'image-loading' : ''} 
                            />
                        </div>

                        <div className="controls">
                        <Container>
                        <Row>
                            <Col sm="8">
                            Colour scheme &nbsp;&nbsp;
                            <Label colourScheme>
                                <Input
                                    type="select"
                                    value={imageColour}
                                    onChange={handleDropdownChange}
                                    disabled={isLoading}
                                >
                                    {/* <option value="0">Colour scheme</option> */}
                                    {colourSchemes.map((scheme, key) => (
                                        <option key={key} value={scheme.id}>
                                            {scheme.name}
                                        </option>
                                    ))}
                                </Input>
                            </Label>
                            </Col>
                            <Col sm="4">
                            <Label invertColour>
                                <Input
                                    type="checkbox"
                                    name="invertColourCheck"
                                    onChange={handleColourInvertedCheck}
                                    disabled={isLoading}
                                />
                                &nbsp;&nbsp;Invert colours
                            </Label>
                            </Col>
                        </Row>
                        </Container>
                        </div>
                        </div>
                    </ModalBody>
                    <BlockUi className="" tag="div" message={"Please wait"}>
                        <ModalFooter>
                            <Button color="primary" onClick={handlePrimaryButton} disabled={isLoading}>
                                Add to Cart
                            </Button>
                            <Button color="secondary" onClick={()=>{setModal(false)}} disabled={isLoading}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </BlockUi>
                </Modal>
            
        </>
    );
}

export default ImageColourEditor;
