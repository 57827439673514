import axios from 'axios';
import {API_BASE_URL, API_ENDPOINT_PREFIX, API_ORDER} from '../constants';
import httpHeaders from '../utils/httpHeaders';

const URL = API_BASE_URL + API_ENDPOINT_PREFIX + API_ORDER;

class Order {

    list = () => {
        return axios.get(URL, httpHeaders.authHeader());
    }

    get = (orderId) => {
        let url = URL + '/' + orderId;
        return axios.get(url, httpHeaders.authHeader());
    }
}

export default new Order();