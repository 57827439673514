import React from "react";
import {Col} from "react-bootstrap";
import DefaultImage from "./content/DefaultImage";
import "./Sidebar.scss";
import { useLocation } from "react-router-dom";
import * as Constants from '../../../constants/RouteConstants';

const Sidebar = (props) => {

    const location = useLocation();
    const currentPage = location.pathname;

    return (
        <Col xs={12} sm={12} md={4} lg={4} xl={3} style={{paddingTop: 8}}>
            <aside className=" sidebar wow bounceInUp animated animated">
                {props.isLoggedIn && <DefaultImage/>}

                {currentPage == Constants.ROUTE_PRODUCTS && (
                    <div className="section-filter" style={{ backgroundColor: "#fff" }}>
                        <div className="b-filter__inner bg-grey">{props.children}</div>
                    </div>
                )}
            </aside>
        </Col>
    );
};

export default Sidebar;