import initialState from './InitialState';
import * as types from '../../actions/ActionTypes';

const PopupReducer =  (state = initialState.popupMetaData, action) => {
    switch (action.type) {
        case types.SET_POPUP_MEATADATA:
            return {...state, ...action.metadata};

        case types.POPUP_PRIMARY_ACTION:
            return {...state, ...action.data};

        default:
            return state;
    }
}

export default PopupReducer;