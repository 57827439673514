import React, { useState, useEffect } from "react";
import "./ProductCard.scss";
import {Col, Row} from "react-bootstrap";

const Clock = (props) => {
    const calculateTimeLeft = () => {
        const difference = new Date(props.time) - new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24)
                    .toString()
                    .padStart(2, "0"),
                minutes: Math.floor((difference / 1000 / 60) % 60)
                    .toString()
                    .padStart(2, "0"),
                seconds: Math.floor((difference / 1000) % 60)
                    .toString()
                    .padStart(2, "0"),
            };
        }
        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        let timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
            }, 1000);

        return () => {
            clearTimeout(timer);
        };
        }, [timeLeft]);

    return (
        <div className="clock-container">
            <span className="label">{props.title}</span>
            <div className="clock container-fluid">
                <Row className="clock-row">
                    {!props.noDays &&
                    <Col className="days text-dark">
                        <span>{timeLeft.days}</span>
                        <span className="tag">days</span>
                    </Col>
                    }
                    {!props.noHours &&
                    <Col className="hours text-dark">
                        <span>{timeLeft.hours}</span>
                        <span className="tag">hours</span>
                    </Col>
                    }
                    {!props.noMins &&
                    <Col className="minutes text-dark">
                        <span>{timeLeft.minutes}</span>
                        <span className="tag">minutes</span>
                    </Col>
                    }
                    {!props.noSecs &&
                    <Col className="seconds text-dark">
                        <span>{timeLeft.seconds}</span>
                        <span className="tag">seconds</span>
                    </Col>
                    }
                </Row>
            </div>
        </div>
    );
};

export default Clock;