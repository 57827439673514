import axios from 'axios';
import {API_BASE_URL, API_ENDPOINT_PREFIX, API_PAYPAL_AUTHORIZE_ORDER, API_PAYPAL_CAPTURE_ORDER, API_PAYPAL_CREATE_ORDER, API_PAYPAL_INFO} from '../constants';
import httpHeaders from '../utils/httpHeaders';

const URL = API_BASE_URL + API_ENDPOINT_PREFIX;
class Paypal {

    getCheckoutUrl = () => {
        let url = URL + '/paypal/generate-checkout-url';
        return axios.get(url, httpHeaders.authHeader());
    }

    getPaypalInfo = () => {
        let url = URL + '/paypal/get-payment-info';
        return axios.get(url, httpHeaders.authHeader());
    }

    savePaypalInfo = (data) => {
        let url = URL + '/paypal/save-customer-info';
        return axios.post(url, data, httpHeaders.authHeader());
    }

    checkExistingPaypalInfo = () => {
        let url = URL + '/paypal/check-existing-account';
        return axios.get(url,  httpHeaders.authHeader());
    }

    // Temporary Implementation
    createPaypalOrder = (data) => {
        return axios.post(API_PAYPAL_CREATE_ORDER, data, httpHeaders.authHeader());
    };
    authorizePaypalOrder = () => {
        return axios.get(API_PAYPAL_AUTHORIZE_ORDER, httpHeaders.authHeader());
    };
    getPaypalAccountDetails = () => {
        return axios.get(API_PAYPAL_INFO, httpHeaders.authHeader());
    };
    captureOrder = (data) => {
        return axios.post(API_PAYPAL_CAPTURE_ORDER, data, httpHeaders.authHeader());
    }
    createOrder = (data) => {
        let url = URL + '/checkout/buy/paypal';
        return axios.post(url, data, httpHeaders.authHeader());
    }
    proceedAuctionOrder = (paypalData) => {
        let url = URL + `/checkout/auction/paypal`;
        return axios.post(url, paypalData, httpHeaders.authHeader());
    }
}

export default new Paypal();