import * as types from './ActionTypes';
import storage from '../utils/storage';
import UserApi from '../api/User';
import {inertServerErrorToStore} from './common/ErrorActions';

export const signup = (data) => {
    return (dispatch) => {
        return UserApi.signup(data).then(response => {
            if (response.data && response.data.success) {
                dispatch(success(true));
            } else {
                dispatch(inertServerErrorToStore({signUpErr: response.data.message}));
            }
        }).catch(err => {
            dispatch(inertServerErrorToStore({signUpErr: err}));
        });
    };

    function success(signupSuccess) {
        return {type: types.SIGNUP_SUCCESS, signupSuccess}
    }
}

export const login = (data) => {

    return (dispatch) => {

        return UserApi.login(data).then(response => {
            const isLoggedIn =  response.data.authToken ?  true :  false;

            if (isLoggedIn) {
                //save in session storage
                storage.setData('authToken', response.data.authToken);
                storage.setData('userData', JSON.stringify(response.data.user));

                dispatch({type: types.LOGIN, isLoggedIn});

            } else {
                dispatch(inertServerErrorToStore({signInErr: response.data.error || "Invalid email or password."}));
            }
        }).catch(err => {
            dispatch(inertServerErrorToStore({signInErr: 'An error occurred'}));
        });
    }
}

export const checkAuthenticated = (isLoggedIn) => {
    return (dispatch) => {
        dispatch({type: types.CHECK_AUTH, ...isLoggedIn});
    }
}

export const logout = () => {

    storage.removeUserAuthData();

    return (dispatch) => {
        dispatch({type: types.LOGOUT, isLoggedIn: false});
    }
}

export const setUserSettings = () => {
    return (dispatch) => {
        return UserApi.settings().then(response => {
            if (response.data) {
                const settings = response.data;
                storage.setData(JSON.stringify(settings));
                dispatch({type: types.USER_SETTINGS, settings});
            }
        }).catch(inertServerErrorToStore({signInErr: 'An error occurred'}));
    }
}

export const updateDefaultImage = (Oldsettings, newImage) => {
    const settings = {...Oldsettings, brandImage: newImage};
    return (dispatch) => {
        dispatch({type: types.USER_SETTINGS, settings});
    }
}