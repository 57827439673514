import initialState from './InitialState';
import * as types from '../../actions/ActionTypes';

const LoaderReducer = (state = initialState.isLoading, action) => {
    switch(action.type) {
        case types.SET_IS_LOADING:
            return {...state, isLoading: action.status};

        default:
            return state;
    }
}

export default LoaderReducer;