import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Button} from 'reactstrap'
import {Redirect} from "react-router-dom";
import {connect} from 'react-redux';
import {removeItem, addToCart, reloadCart} from '../../../../actions/CartActions';
import storage from '../../../../utils/storage';
import './MiniCart.scss';
import defaultProductImage from "../../../../assets/Default_Product_Image.png";
import PriceFormat from "../../../../utils/PriceFormat";
import {NavDropdown, Table} from "react-bootstrap";

class MiniCart extends React.Component {

    constructor(props) {

        super(props)

        this.state = {
            redirect: null,
            isCartOpen: false,
            isCartEmpty: true,
            items: [],
        }
    }

    componentDidMount() {

        let cartItems = storage.getData("cart");

        if (cartItems) {
            let cartJson = JSON.parse(cartItems);
            this.props.reloadCart(cartJson);
        }
    }

    handleClickCartIcon = () => {

        let isCartEmpty = this.props.cart.count > 0 ? false : true;
        !isCartEmpty && this.setState({isCartOpen: !this.state.isCartOpen, isCartEmpty});
    }

    handleClickRemove = (id, defaultImageId) => {

        this.props.removeItem(id, defaultImageId)
        let isCartEmpty = this.props.cart.count > 0 ? false : true;
        isCartEmpty && this.setState({isCartOpen: false, isCartEmpty});
    }

    handleClickCheckout = (e) => {
        this.setState({redirect: '/my-cart?t=' + Date.now(), isCartOpen: false});
    }

    render() {

        const cartItems = this.props.cart.items || [];
        const isCartEmpty = this.props.cart.count > 0 ? false : true;
        const itemCountText = !isCartEmpty && (this.props.cart.count + ' ' + (this.props.cart.count > 1 ? 'items' : 'item'));
        const basket = (
            <span className="basket">
                <a onClick={this.handleClickCartIcon}>
                    <FontAwesomeIcon icon="cart-plus"/>
                    {!isCartEmpty && <span>{this.props.cart.count}</span> || <span>0</span>}
                </a>
            </span>
        );

        return (
            <NavDropdown disabled={isCartEmpty} autoClose="outside" align="end" onClick={this.handleClickCartIcon} className="mini-cart" title={basket}
                         id="navbarScrollingDropdown">

                {this.state.redirect && <Redirect to={this.state.redirect}/>}

                {!isCartEmpty &&
                    <NavDropdown.Item className="mini-cart-body">
                        <Table borderless>
                            <tbody>
                            <tr>
                                <td className="bottom-border" colSpan={4}>
                                    <div className="top-subtotal">{itemCountText}, <span className="price"><PriceFormat
                                        price={this.props.cart.total}/></span></div>
                                </td>
                            </tr>

                            {cartItems.map((item, key) => {
                                const cartImagePath = item.productImagePath !== null ? item.productImagePath : defaultProductImage;
                                return (
                                    <>
                                        <tr className="bottom-border">
                                            <td>
                                                <a className="product-image" title={item.name} href={"#" + key}>
                                                    <img className="mini-cart-img"
                                                         src={cartImagePath}
                                                         alt="Card image cap"/>
                                                </a>
                                            </td>
                                            <td className="vertical-center">
                                                <p className="product-name">
                                                    <a href={'product/' + item.id} target="_blank">{item.name}</a>
                                                </p>
                                                <span className="price">
                                                    <PriceFormat price={item.price}/>
                                                </span>
                                            </td>
                                            <td className="vertical-center">
                                                {item.cartQty}
                                            </td>
                                            <td className="vertical-center">
                                                <span className="trash text-dark cursor-pointer" onClick={() => this.handleClickRemove(item.id, item.defaultImageId)}>
                                                        <FontAwesomeIcon icon="trash-alt"/>
                                                    </span>
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                            <tr>
                                <td colSpan={4}>
                                    <Button className="btn-checkout" title="Checkout" type="button" color="primary" onClick={this.handleClickCheckout}>
                                        Checkout
                                    </Button>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </NavDropdown.Item>
                    ||
                    <div style={{textAlign:"center"}}>Cart is empty.</div>
                }
            </NavDropdown>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        cart: state.cart,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        removeItem: (productId, defaultImageId) => {
            dispatch(removeItem(productId, defaultImageId))
        },
        addToCart: (product, qty) => {
            dispatch(addToCart(product, qty))
        },
        reloadCart: (cartItems) => {
            dispatch(reloadCart(cartItems))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MiniCart);
