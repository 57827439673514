//Action types of product
export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const PATCH_PRODUCT_LIST = 'PATCH_PRODUCT_LIST';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const CHECK_AUTH = 'CHECK_AUTH';
export const USER_SETTINGS = 'USER_SETTINGS';
export const HTTP_ERROR = 'HTTP_ERROR';

//Cart Action Types
export const ADD_TO_CART  = 'ADD_TO_CART';
export const REMOVE_ITEM  = 'REMOVE_ITEM';
export const CHANGE_QTY = 'CHANGE_QTY';
export const CHECKOUT = 'CHECKOUT';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const CHECKOUT_ERROR = 'CHECKOUT_ERROR';
export const RELOAD_CART = 'RELOAD_CART';

//Popup
export const SET_POPUP_MEATADATA = 'SET_POPUP_MEATADATA';
export const POPUP_PRIMARY_ACTION = 'POPUP_PRIMARY_ACTION';
export const POPUP_TOGGLE_SHOWHIDE = 'POPUP_TOGGLE_SHOWHIDE';

//Product Filter
export const SET_PRODUCT_FILTER_OPTIONS = 'SET_PRODUCT_FILTER_OPTIONS';
export const CLEAR_PRODUCT_FILTER_OPIONS = 'CLEAR_PRODUCT_FILTER_OPIONS';
export const PAGINATE_PRODUCT = 'PAGINATE_PRODUCT';

//Loader
export const SET_IS_LOADING = 'SET_IS_LOADING';