import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'

import moment from 'moment';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import './App.scss';
import './assets/styles/styles.css'
import './fontawesome';
import jwt_decode from "jwt-decode";
import Layout from './components/layout/Layout';
import LayoutRoute from './components/layout/LayoutRoute';
import {checkAuthenticated, setUserSettings} from './actions/UserActions';
import storage from './utils/storage';
import ProductPage from './components/pages/ProductsPage';
import AboutUsPage from './components/pages/AboutUsPage';
import SignupPage from './components/pages/SignupPage';
import PrivacyAndCookiePolicyPage from './components/pages/PrivacyAndCookiePolicyPage';
import * as AccountSettingsPage from './components/pages/AccountSettingsPage';
import * as SidebarContent from './components/layout/sidebar/content';
import OrderListPage from './components/pages/orders/OrderListPage';
import OrderDetailPage from './components/pages/orders/OrderDetailPage';
import ProductDetailPage from './components/pages/ProductDetailPage';
import ContactUsPage from "./components/pages/ContactUsPage";
import AccountStatusPage from "./components/pages/AccountStatusPage";
import TermsAndConditionsPage from "./components/pages/TermsAndConditionsPage";
import NotFoundPage from "./components/pages/NotFoundPage";
import ResetPassword from "./components/pages/user/ResetPasswordForm";
import FAQPage from "./components/pages/FAQPage";
import EmailVerificationForm from "./components/pages/accountSettings/EmailVerificationForm";
import InvoiceListPage from "./components/pages/invoices/InvoiceListPage";
import InvoiceDetailPage from "./components/pages/invoices/InvoiceDetailPage";
import ReceiptListPage from "./components/pages/receipts/ReceiptListPage";
import ReceiptDetailPage from "./components/pages/receipts/ReceiptDetailPage";
import CartPage from './components/pages/cart-page/CartPage';
import ManageImage from "./components/pages/logo/ManageBrands";
import * as Constants from './constants/RouteConstants';
import HomePage from "./components/pages/HomePage";
import BrandsPage from "./components/pages/BrandsPage";

window.moment = moment;

class App extends React.Component {

	constructor(props) {
		super(props);

		const authToken = storage.getAuthToken();
		const isLoggedIn = authToken ? (!this.checkTokenHasExpired(authToken) ? true : false) : false;

		this.props.actions.checkAuthenticated({isLoggedIn});
		isLoggedIn && this.props.actions.setUserSettings();
	}

	checkTokenHasExpired = (token) => {
		let isExpired = false;
		let decodedToken = jwt_decode(token, {complete: true});
		let currentTime = new Date().getTime() / 1000;

		if (decodedToken.exp < currentTime) {
			isExpired = true;

			//Remove  user data
			storage.removeUserAuthData();
		}
		return isExpired;
	}

	render() {
		return (
			<div className="App">

				<Router>
					<Switch>
						<LayoutRoute
							exact
							pageTitle="Login or Create an Account"
							path="/signup"
							layout={Layout}
							noSidebar = {true}
							component = {props => (
								<SignupPage {...props}/>
							)}
						/>
						<LayoutRoute
							exact
							pageTitle="Login or Create an Account"
							path="/login"
							layout={Layout}
							noSidebar = {true}
							component = {props => (
								<SignupPage {...props}/>
							)}
						/>
						<LayoutRoute
							exact
							pageTitle="Privacy and Cookie Policy"
							path="/privacy-and-cookie-policy"
							layout={Layout}
							noSidebar = {true}
							component = {props => (
								<PrivacyAndCookiePolicyPage {...props}/>
							)}
						/>
						<LayoutRoute
							exact
							pageTitle="Contact Us"
							path="/contact-us"
							layout={Layout}
							noSidebar = {true}
							component = {props => (
								<ContactUsPage {...props}/>
							)}
						/>
						<LayoutRoute
							exact
							pageTitle="Brands"
							path="/brands"
							layout={Layout}
							noSidebar = {true}
							component = {props => (
								<BrandsPage {...props}/>
							)}
						/>
						<LayoutRoute
							exact
							pageTitle=""
							path={Constants.ROUTE_HOME}
							layout={Layout}
							noSidebar = {true}
							component = {props => (
								<HomePage {...props}/>
							)}
						/>
						<LayoutRoute
							exact
							pageTitle="Account status"
							path="/account-status"
							layout={Layout}
							noSidebar = {true}
							component = {props => (
								<AccountStatusPage {...props}/>
							)}
						/>
						<LayoutRoute
							exact
							pageTitle="Manage Images"
							path="/manage"
							layout={Layout}
							noSidebar = {true}
							component = {props => (
								<ManageImage {...props}/>
							)}
						/>
						<LayoutRoute
							exact
							pageTitle="Terms and Conditions"
							path="/terms-and-conditions/"
							layout = {Layout}
							noSidebar = {true}
							component = {props => (
								<TermsAndConditionsPage {...props}/>
							)}
						/>
						<LayoutRoute
							exact
							pageTitle=""
							path={Constants.ROUTE_PRODUCTS}
							layout = {Layout}
							component = {props => (
								<ProductPage {...props}/>
							)}
							sidebar = {props => (<SidebarContent.ProductsSidebar {...props}/>)}
						/>
						<LayoutRoute
							exact
							pageTitle="About Us"
							path="/about-us"
							layout = {Layout}
							noSidebar = {true}
							component = {props => (
								<AboutUsPage {...props}/>
							)}
							sidebar = {props => (<SidebarContent.ProductsSidebar {...props}/>)}
						/>
						<LayoutRoute
							exact
							key="p"
							pageTitle="Account Dashboard"
							path="/account-settings"
							requireAuth
							layout = {Layout}
							component = {props => (
								<AccountSettingsPage.Dashboard {...props}/>
							)}
							sidebar = {props => (<SidebarContent.ManageSettingsSidebar {...props}/>)}
						/>
						<LayoutRoute
							exact
							pageTitle="Settings And Preferences"
							path="/account-settings/settings-and-preferences"
							requireAuth
							layout = {Layout}
							component = {props => (
								<AccountSettingsPage.SettingsPreferences {...props}/>
							)}
							sidebar = {props => (<SidebarContent.ManageSettingsSidebar {...props}/>)}
						/>
						<LayoutRoute
							exact
							pageTitle="Account Information"
							path="/account-settings/account-information"
							requireAuth
							layout = {Layout}
							component = {props => (
								<AccountSettingsPage.AccountInformation {...props}/>
							)}
							sidebar = {props => (<SidebarContent.ManageSettingsSidebar {...props}/>)}
						/>
						<LayoutRoute
							exact
							pageTitle="Notifications Preferences"
							path="/account-settings/notifications-preferences"
							requireAuth
							layout = {Layout}
							component = {props => (
								<AccountSettingsPage.NotificationsPreferences {...props}/>
							)}
							sidebar = {props => (<SidebarContent.ManageSettingsSidebar {...props}/>)}
						/>
						<LayoutRoute
                            exact
                            pageTitle='Shopping Cart'
                            path='/my-cart'
                            requireAuth
                            layout={Layout}
                            component={props => (
                                <CartPage {...props}/>
                            )}
                            noSidebar={true}
                        />
						<LayoutRoute
							exact
							pageTitle="Orders"
							path="/my-orders"
							requireAuth
							layout = {Layout}
							component = {props => (
								<OrderListPage {...props}/>
							)}
							sidebar = {props => (<SidebarContent.ManageSettingsSidebar {...props}/>)}
						/>
						<LayoutRoute
							exact
							pageTitle="Invoices"
							path="/my-invoices"
							requireAuth
							layout = {Layout}
							component = {props => (
								<InvoiceListPage {...props}/>
							)}
							sidebar = {props => (<SidebarContent.ManageSettingsSidebar {...props}/>)}
						/>
						<LayoutRoute
							exact
							pageTitle="Receipts"
							path="/my-receipts"
							requireAuth
							layout = {Layout}
							component = {props => (
								<ReceiptListPage {...props}/>
							)}
							sidebar = {props => (<SidebarContent.ManageSettingsSidebar {...props}/>)}
						/>
						<LayoutRoute
							exact
							pageTitle="Order Information"
							path="/my-order/:orderId"
							requireAuth
							layout = {Layout}
							component = {props => (
								<OrderDetailPage {...props}/>
							)}
							sidebar = {props => (<SidebarContent.ManageSettingsSidebar {...props}/>)}
						/>
						<LayoutRoute
							exact
							pageTitle="Invoice Information"
							path="/my-invoice/:invoiceId"
							requireAuth
							layout = {Layout}
							component = {props => (
								<InvoiceDetailPage {...props}/>
							)}
							sidebar = {props => (<SidebarContent.ManageSettingsSidebar {...props}/>)}
						/>
						<LayoutRoute
							exact
							pageTitle="Receipt Information"
							path="/my-receipt/:receiptId"
							requireAuth
							layout = {Layout}
							component = {props => (
								<ReceiptDetailPage {...props}/>
							)}
							sidebar = {props => (<SidebarContent.ManageSettingsSidebar {...props}/>)}
						/>
						<LayoutRoute
							exact
							pageTitle="Product Information"
							path="/product/:productId"
							layout = {Layout}
							component = {props => (
								<ProductDetailPage {...props}/>
							)}
							sidebar = {props => (<SidebarContent.ProductsSidebar {...props}/>)}
						/>

						<LayoutRoute
							exact
							pageTitle="Reset Password"
							path="/reset-password"
							layout = {Layout}
							component = {props => (
								<ResetPassword {...props}/>
							)}
							noSidebar = {true}
						/>

						<LayoutRoute
							exact
							pageTitle="FAQ"
							path="/faq"
							layout = {Layout}
							component = {props => (
								<FAQPage {...props}/>
							)}
							noSidebar = {true}
						/>

						<LayoutRoute
							exact
							pageTitle="Verify Email"
							path="/account-settings/verify-email"
							layout = {Layout}
							component = {props => (
								<EmailVerificationForm {...props}/>
							)}
							noSidebar = {true}
						/>

						<Route component={NotFoundPage}/>
					</Switch>
				</Router>
			</div>
		);
	}
}

const mapDispathToProps = dispatch => {
    return {
        actions: {
			checkAuthenticated: bindActionCreators(checkAuthenticated, dispatch),
			setUserSettings: bindActionCreators(setUserSettings, dispatch),
        }
    }
}
export default connect(null, mapDispathToProps)(App);
