import React, {useState, useEffect} from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import GeneralNotifications from "./GeneralNotifications";
import AuctionNotifications from "./AuctionNotifications";

const NotificationsPreferences = (props) => {

    const [key, setKey] = useState(1);
    const enableNotification = "Cannot enable the notification at the moment!";
    const disableNotification = "Cannot disable the notification at the moment!";

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
            <Tabs
                id="notificationsPreferences"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="1" title="General Notifications">
                    <GeneralNotifications enableNotification = {enableNotification} disableNotification = {disableNotification}/>
                </Tab>
                <Tab eventKey="2" title="Auction Notifications">
                    <AuctionNotifications enableNotification = {enableNotification} disableNotification = {disableNotification}/>
                </Tab>
            </Tabs>
        </>
    )
}

export default NotificationsPreferences;