import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Table, ListGroup, ListGroupItem} from "reactstrap";
import {connect} from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Typography from '@material-ui/core/Typography';
import Bid from './productPage/Bid';
import AddToCart from './productPage/AddToCart';
import Clock from './productPage/Clock';
import {checkProductIsExpired, checkUserStatus} from '../../utils/helper'
import ProductApi from '../../api/Product';
import './ProductDetailPage.scss';
import Websocket from '../../utils/Websocket';
import defaultProductImage from "../../assets/Default_Product_Image.png";
import BidHistory from "./productPage/BidHistory";
import Modal from "../common/Popup";
import {setPopupMetaData} from "../../actions/common/PopupActions";
import AccountSettings from "../../api/AccountSettings"; 
import PriceFormat from "../../utils/PriceFormat";
import { useHistory } from 'react-router-dom';


const ProductDetailPage = (props) => {

    const [product, setProduct] = useState({});
    const [isAuction, setIsAuction] = useState('');
    const [productInfo, setProductInfo] = useState({});
    const [multiWinnerTimes, setMultiWinnerTimes] = useState({});
    const [isMultiWinner, setIsMultiWinner] = useState(false);
    const [noOfWinners, setNoOfWinners] = useState(0);
    const [brandName, setBrandName] = useState('');

    const history = useHistory();

    useEffect(() => {

        window.scrollTo(0, 0);
        AccountSettings.getCustomerData().then(response => {
            const defaultBrand = response.data.brands.find(brand => brand.isDefault === 1);
            setBrandName(defaultBrand.brandName)
            if(response.data.status !== 'ACTIVE'){
                history.push('/account-status');
            }
        }).catch(err => {
            console.error(err);
            if (err.response.status === 403) {
                history.push('/signup');
            }
        });
        setPopupMetaData();
        const id = props.match.params.productId || 0;
        if (id <= 0) {
            return;
        }
        getProduct(id);

    }, []);

    const getProduct = (id) => {
        ProductApi.detail(id).then(response => {
            const apiData = response.data;
            let productData = apiData.product;
            const auctionData = productData.productAuction || {};
            const auctionWinnerTimes = apiData.auctionWinnerTimes || {};

            if (auctionData) {
                productData = {
                    ...productData,
                    currentPrice: auctionData.currentPrice,
                    minBidIncrement: auctionData.minBidIncrement,
                    isMultiWinner: auctionData.isMultiWinner,
                    numOfWinners: auctionData.numOfWinners,
                }
            }
            const sm = productData.sellingMethod === 'AUCTION' ? true : false;
            const mw = auctionData.isMultiWinner || false;
            const nw = auctionData.numOfWinners || 0;
            setIsAuction(sm);
            setProduct(productData);
            setProductInfo(apiData.productInfo);
            setMultiWinnerTimes(auctionWinnerTimes);
            setIsMultiWinner(mw);
            setNoOfWinners(nw);
            productData.deviceColourScheme = productData.deviceLocation.device.colourScheme;

        }).catch(err => {
            console.error(err.response);
        });
    }

    const renderBidCount = () => {
        const bidCount = Number.parseInt(product.productAuction.bidCount || 0);
        const bidLabel = bidCount + (bidCount > 1 ? ' bids' : ' bid');
        return (
            <span>({bidLabel})</span>
        )
    }

    const checkIsProductUpdated = (updatedProduct) => {
        if (updatedProduct && updatedProduct.id === product.id) {
            getProduct(product.id);
        }
    }

    const productImagePath = product.productImagePath !== null ? product.productImagePath : defaultProductImage;

    const bidDetails = (
        <>
            {isAuction ? product.productAuction &&
                <>
                    <PriceFormat price = {product.productAuction.currentPrice}/>
                    <a href="#bidHistory">
                        {renderBidCount()}
                    </a>&nbsp;
                </>
                :
                <></>
            }
        </>
    );

    const setPopupMetaData = () => {
        props.setPopupMetaData({primaryBtnText: '', secondaryBtnText: 'Close', primaryBtn: {action: () => {}} });
    }

    const startAt = window.moment(product.startAt).format('MMMM Do YYYY [at] h:mm:ss a').toString();
    const endAt = window.moment(product.endAt).format('MMMM Do YYYY [at] h:mm:ss a').toString();

    const renderHighestBidder = () => {
        let highestBidder;
        if(product.productAuction.bidCount > 0) {
            const brandName = product.productAuction.bids[0].customer.brand.brandName;
            let mask = "*";
            for (var i = 0; i < (brandName).length - 3 ; i++) {
                mask = mask + "*";
            }
            highestBidder = (brandName).substring(0,1) + mask + (brandName).substring(brandName.length - 1);
        } else {
            highestBidder = "N/A";
        }
        return(
            <span>{highestBidder}</span>
        )
    }

    return (
        <>
            <Websocket onReceiveMessage={checkIsProductUpdated}/>
            <Row className="product-detail-wrap">
                <Col md="7">
                    {
                        checkProductIsExpired(product) ?
                        <Typography variant="h5" align="center" color="textSecondary">This product has been expired.</Typography>
                        :<></>
                    }
                    <br/>
                    <img src={productImagePath} width="100%" alt="product Image"/>
                </Col>
                <Col md="5" className="info">

                    <Row className="row">
                        <Col lg="12">
                            <Typography variant="h6">{product.name}</Typography>
                            <Typography variant="h6" className="price"> { isAuction ? product.productAuction && <PriceFormat price = {product.productAuction.currentPrice}/> : <PriceFormat price = {product.price}/>}</Typography>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12">
                            <ListGroupItem action>
                            {
                                checkProductIsExpired(product) ? <></> :
                                <Clock title = {"Product expires in"} time={product.endAt}/>
                            }
                            {isAuction && product.productAuction && (
                                    <><Typography variant="caption" className="current-price">
                                        Current Price:  <PriceFormat price = {product.productAuction.currentPrice}/>
                                    </Typography>
                                    <br />
                                    <Typography variant="h6">Auction details</Typography>
                                    <br />
                                        <Typography variant="body1">
                                            <Table responsive borderless hover>
                                                    <tbody>
                                                    <tr>
                                                        <td className="auction-details">Start at</td>
                                                        <td className="auction-details"> : </td>
                                                        <td className="auction-details">{startAt}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="auction-details">End at</td>
                                                        <td  className="auction-details"> : </td>
                                                        <td className="auction-details">{endAt}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="auction-details">Start price</td>
                                                        <td className="auction-details"> : </td>
                                                        <td className="auction-details">
                                                            <PriceFormat price = {product.productAuction.initialPrice}/>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="auction-details">Current bid</td>
                                                        <td className="auction-details"> : </td>
                                                        <td className="auction-details">
                                                            {product.productAuction.bidCount > 0 &&
                                                            <Modal
                                                                title = "Bid History"
                                                                opener = {bidDetails}
                                                                body = {<BidHistory
                                                                    bids = {product.productAuction.bids}
                                                                    brandName = {brandName}
                                                                />}
                                                            /> ||
                                                                <>N/A</>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="auction-details">Highest bidder</td>
                                                        <td className="auction-details"> : </td>
                                                        <td className="auction-details">{renderHighestBidder()}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="auction-details">Bid increment</td>
                                                        <td className="auction-details"> : </td>
                                                        <td className="auction-details">
                                                            <PriceFormat price = {product.productAuction.minBidIncrement}/>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Typography>
                                    </>
                            )}
                            </ListGroupItem>
                            {
                                checkProductIsExpired(product) ? <></> :
                                <ListGroupItem className="info__controls">
                                    {isAuction ?
                                        <Bid
                                            userStatus = {checkUserStatus(props.userStatus)}
                                            product = {product}
                                        />
                                        :
                                        <AddToCart
                                            userStatus = {checkUserStatus(props.userStatus)}
                                            product = {product}
                                        />
                                    }
                                </ListGroupItem>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col lg="12">
                    <Typography variant="body1">
                        {product.description}
                    </Typography>
                </Col>
            </Row>
            <br/>
            {isMultiWinner ?
                <>
                    <Row>
                        <Col lg="12">
                            <Typography variant="h6">Advertisement exposure duration</Typography>
                            <br/>
                            <Table border={2} hover style={{width:"50%",textAlign:"center"}}>
                                <tbody>
                                <tr>
                                    <th><Typography variant="body1">Position</Typography></th>
                                    <th><Typography variant="body1">Duration (Secs)</Typography></th>
                                </tr>
                                {multiWinnerTimes.map((time) => {
                                    if(time.id <= noOfWinners) {
                                        return(
                                            <tr>
                                                <td><Typography variant="body1">{time.position}</Typography></td>
                                                <td><Typography variant="body1">{time.duration}</Typography></td>
                                            </tr>
                                        );}
                                })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <br/>
                </>
                :
                <></>
            }
            <Row>
                <Col lg="12">
                    <div>
                        <Nav tabs>
                            <NavItem >
                                <NavLink>More Information</NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab="1">
                            <TabPane tabId="1">
                                <Table responsive borderless>
                                    <tbody>
                                        <tr>
                                            <th width="15%">
                                                Sport <FontAwesomeIcon icon="question-circle" title="Sport"
                                                data-tip="The sport that the tournament is related to."/>
                                                <ReactTooltip/>
                                            </th>
                                            <td width="85%">{productInfo.sport}</td>
                                        </tr>
                                        <tr>
                                            <th width="15%">
                                                Tournament <FontAwesomeIcon icon="question-circle" title="Tournament"
                                                data-tip="The specific tournament where individual athlete or team will compete in."/>
                                                <ReactTooltip/>
                                            </th>
                                            <td width="85%">{productInfo.tournament}</td>
                                        </tr>
                                        <tr>
                                            <th width="15%">
                                                Match <FontAwesomeIcon icon="question-circle" title="Match"
                                                data-tip="The specific match in the tournament that this particular advertising opportunity is related to."/>
                                                <ReactTooltip/>
                                            </th>
                                            <td width="85%">{productInfo.match}</td>
                                        </tr>
                                        <tr>
                                            <th width="15%">
                                                Team <FontAwesomeIcon icon="question-circle" title="Team"
                                                data-tip="The specific team the player wearing the display device(s) belongs to."/>
                                                <ReactTooltip/>
                                            </th>
                                            <td width="85%">{productInfo.team}</td>
                                        </tr>
                                        <tr>
                                            <th width="15%">
                                                Duration <FontAwesomeIcon icon="question-circle" title="Duration"
                                                data-tip="The period during the game when this advertisement will be displayed. For example, 1-5 denotes the ad will be displayed during the first 5 overs in an innings."/>
                                                <ReactTooltip/>
                                            </th>
                                            <td width="85%">{productInfo.matchTeamEvent}</td>
                                        </tr>
                                        <tr>
                                            <th width="15%">
                                                Location <FontAwesomeIcon icon="question-circle" title="Location"
                                                data-tip="The position of the display on the item of clothing or piece of equipment."/>
                                                <ReactTooltip/>
                                            </th>
                                            <td width="85%">{productInfo.deviceLocation}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </TabPane>
                        </TabContent>
                    </div>
                </Col>
            </Row>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
      items: state.items,
      userStatus: state.UserReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPopupMetaData: (metadata) => { dispatch(setPopupMetaData(metadata)) }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ProductDetailPage);