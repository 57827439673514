import axios from 'axios';
import {API_BASE_URL, API_ENDPOINT_PREFIX, API_EMAIL_VERIFICATION} from '../constants';
import httpHeaders from '../utils/httpHeaders';

const URL = API_BASE_URL + API_ENDPOINT_PREFIX + API_EMAIL_VERIFICATION;

class EmailVerification {

    sendVerificationEmail = (data) => {
        let url = URL + '/send-email';
        return axios.post(url, data, httpHeaders.authHeader());
    }

    verifyByPin = (data) => {
        let url = URL + '/pin';
        return axios.post(url, data, httpHeaders.authHeader());
    }

    verifyByToken = (data) => {
        let url = URL + '/token';
        return axios.post(url, data, httpHeaders.authHeader());
    }

    getTokenDetails = (id) => {
        let url = URL + '/token-details/' + id;
        return axios.get(url, httpHeaders.authHeader());
    }
}

export default new EmailVerification();