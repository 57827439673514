import initialState from './InitialState';
import * as types from '../../actions/ActionTypes';

const ErrorReducer = (state = initialState.errorData, action) => {
    switch(action.type) {
        case types.HTTP_ERROR:
            return {...state, ...action.error};

        default:
            return state;
    }
}

export default ErrorReducer;