import axios from 'axios';
import {API_BASE_URL, API_ENDPOINT_PREFIX} from '../constants';

const URL = API_BASE_URL;

class Common {
    industries = () => {
        let url = URL + '/common/industries';
        return axios.get(url);
    }

    countries =  () => {
        let url = URL + '/common/countries';
        return axios.get(url);
    }

    paymentTerms =  () => {
        let url = URL + '/common/payment-terms';
        return axios.get(url);
    }
}

export default new Common();