import initialState from './InitialState';
import * as types from '../../actions/ActionTypes';

const UserReducer =  (state = initialState.user, action) => {
    switch (action.type) {
        case types.SIGNUP:
            return {...state, ...action.user};

        case types.SIGNUP_SUCCESS:
            return {...state, signupSuccess: action.signupSuccess};

        case types.LOGIN:
            return {...state, isLoggedIn: action.isLoggedIn};

        case types.CHECK_AUTH:
            return {...state, isLoggedIn: action.isLoggedIn};

        case types.LOGOUT:
            return {...state, isLoggedIn: action.isLoggedIn};

        case types.USER_SETTINGS:
            return {...state, settings: action.settings};

        default:
            return state;
    }
}

export default UserReducer;