import React, { useState, useEffect, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import { connect } from 'react-redux';
import { primaryAction } from '../../../actions/common/PopupActions';
import BrandImageApi from '../../../api/BrandImage';
import { Input, Label } from "reactstrap";
import { ASPECT_RATIOS } from '../../../constants';

const ImageCropper = (props) => {
    const { imageUri, imagePath, brandId, originalImagePath } = props;
    const [newImageUri, setNewImageUri] = useState(imageUri);
    const [newImagePath, setNewImagePath] = useState(imagePath);
    
    // Cropper 1
    const [crop1, setCrop1] = useState({ x: 0, y: 0 });
    const [zoom1, setZoom1] = useState(1);
    const [croppedAreaPixels1, setCroppedAreaPixels1] = useState({});

    // Cropper 2
    const [crop2, setCrop2] = useState({ x: 0, y: 0 });
    const [zoom2, setZoom2] = useState(1);
    const [croppedAreaPixels2, setCroppedAreaPixels2] = useState({});

    const [defaultImage, setDefaultImage] = useState(false);
    const [colourInverted, setColourInverted] = useState(false);
    const [imageColour, setImageColour] = useState('BKWH'); // Default to 'BKWH'

    const onCropComplete1 = useCallback((area, croppedPixels) => {
        setCroppedAreaPixels1(croppedPixels);
    }, []);

    const onCropComplete2 = useCallback((area, croppedPixels) => {
        setCroppedAreaPixels2(croppedPixels);
    }, []);

    const handleCheck = useCallback((event) => {
        setDefaultImage(event.target.checked);
    }, []);

    const updateImageColor = useCallback(async (colorScheme, invertColor) => {
        try {
            const data = {
                originalImagePath,
                imageColour: colorScheme,
                colourInverted: invertColor
            };

            const response = await BrandImageApi.changeColor(data);

            setNewImageUri(response.data.image);
            setNewImagePath(response.data.imagePath);
        } catch (error) {
            console.error('Error updating image color:', error);
        }
    }, [originalImagePath]);

    const handleColourInvertedCheck = useCallback(async (event) => {
        setColourInverted(event.target.checked);
        await updateImageColor(imageColour, event.target.checked);
    }, [imageColour, updateImageColor]);

    const handleDropdownChange = useCallback(async (event) => {
        setImageColour(event.target.value);
        await updateImageColor(event.target.value, colourInverted);
    }, [colourInverted, updateImageColor]);

    const onSave = useCallback(() => {
        return new Promise(function (resolve, reject) {
            const cropRequest = {
                imagePath: newImagePath,
                cropSizes: [
                    {
                        aspectRatio: ASPECT_RATIOS.RATIO_5_3.name,
                        x: croppedAreaPixels1.x,
                        y: croppedAreaPixels1.y,
                        width: croppedAreaPixels1.width,
                        height: croppedAreaPixels1.height,
                    },
                    {
                        aspectRatio: ASPECT_RATIOS.RATIO_37_19.name,
                        x: croppedAreaPixels2.x,
                        y: croppedAreaPixels2.y,
                        width: croppedAreaPixels2.width,
                        height: croppedAreaPixels2.height,
                    }
                ],
                defaultImage,
                brandId,
                imageColour: imageColour === '' || imageColour === '0' ? 'BKWH' : imageColour,
                colourInverted
            };
            
            BrandImageApi.crop(cropRequest).then(response => {
                const res = response.data;
                if (res && res.success === 1) {
                    resolve({ error: false, message: "The image successfully sent for approval" });
                    window.location.reload();
                } else {
                    reject(res.message);
                }
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }, [newImagePath, croppedAreaPixels1, croppedAreaPixels2, defaultImage, brandId, imageColour, colourInverted]);

    useEffect(() => {
        if (props.popup.activeTab === "3") {
            props.primaryAction({ primaryBtn: { action: () => onSave() } });
        }
    }, [props.popup.activeTab, croppedAreaPixels1, defaultImage, imageColour, colourInverted, onSave]);

    return (
        <>
            <div className="croppers-container">
                <div className="crop-container">
                    <p className="aspect-ratio-label">Aspect ratio - 5/3</p>
                    <Cropper
                        image={newImageUri}
                        crop={crop1}
                        zoom={zoom1}
                        zoomWithScroll={true}
                        aspect={ASPECT_RATIOS.RATIO_5_3.value}
                        onCropChange={setCrop1}
                        onCropComplete={onCropComplete1}
                        onZoomChange={setZoom1}
                    />
                </div>
                <div className="crop-container">
                    <p className="aspect-ratio-label">Aspect ratio - 37/19</p>
                    <Cropper
                        image={newImageUri}
                        crop={crop2}
                        zoom={zoom2}
                        zoomWithScroll={true}
                        aspect={ASPECT_RATIOS.RATIO_37_19.value}
                        onCropChange={setCrop2}
                        onCropComplete={onCropComplete2}
                        onZoomChange={setZoom2}
                    />
                </div>
            </div>
    
            <div className="controls-container">
                <div className="controls">
                    <Slider
                        value={zoom1}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom1"
                        onChange={(e, zoomValue) => setZoom1(zoomValue)}
                    />
                    <p>Zoom</p>
                </div>
                <div className="controls">
                    <Slider
                        value={zoom2}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom2"
                        onChange={(e, zoomValue) => setZoom2(zoomValue)}
                    />
                    <p>Zoom</p>
                </div>
            </div>
    
            <div className="labels">
                <Label defaultImage className="left-aligned">
                    <Input
                        type="checkbox"
                        name="defaultImageCheck"
                        onChange={handleCheck}
                    />
                    &nbsp;&nbsp;Set new image as the default image after the approval
                </Label>

                {/* <Label colourScheme className="center-aligned">
                    <Input
                        type="select"
                        value={imageColour}
                        onChange={handleDropdownChange}
                    >
                        <option value="0">Colour scheme</option>
                        {colourSchemes.map((scheme, key) => (
                            <option key={key} value={scheme.id}>
                                {scheme.name}
                            </option>
                        ))}
                    </Input>
                </Label>
                
                <Label invertColour className="right-aligned">
                    <Input
                        type="checkbox"
                        name="invertColourCheck"
                        onChange={handleColourInvertedCheck}
                    />
                    &nbsp;&nbsp;Invert colours
                </Label> */}
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        primaryAction: (metadata) => { dispatch(primaryAction(metadata)) }
    }
}

const mapStateToProps = state => {
    return {
        popup: state.popup
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageCropper);
