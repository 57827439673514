import axios from 'axios';
import {API_BASE_URL, API_ENDPOINT_PREFIX} from '../constants';

const URL = API_BASE_URL + API_ENDPOINT_PREFIX;
class ContactUs {

    postContactUs = (data) => {
        let url = URL + '/contact-us';
        return axios.post(url, data);
    }
}

export default new ContactUs();