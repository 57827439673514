import axios from 'axios';
import {API_BASE_URL, API_ENDPOINT_PREFIX, API_FAQ} from '../constants';

const URL = API_BASE_URL + API_ENDPOINT_PREFIX + API_FAQ;

class FAQ {
    list = () => {
        return axios.get(URL);
    }

    get = (fAQId) => {
        let url = URL + '/' + fAQId;
        return axios.get(url);
    }
}

export default new FAQ();