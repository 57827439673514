import React, {useRef, useState} from 'react';
import {Form, Button} from 'react-bootstrap';
import Modal from "../../common/Popup";
import ForgotPasswordForm from "./ForgotPasswordForm";
import {setPopupMetaData} from "../../../actions/common/PopupActions";
import {connect, useDispatch} from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import ForgotPasswordApi from "../../../api/ForgotPassword";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";

const SigninForm = (props) => {

    const [showPassword, setShowPassword] = useState(false);

    const togglePassword = () => {
        setShowPassword(!showPassword);
    }

    const [email, setEmail] = useState('');
    const [forgotError, setForgotError] = useState('');
    const [, forceUpdate] = useState();
    const validator = useRef(new SimpleReactValidator({autoForceUpdate:{forceUpdate:forceUpdate}}));
    const dispatch = useDispatch();
    const history = useHistory();
    const updatePopupMetData = (metadata) => dispatch(setPopupMetaData(metadata));

    const handleForgotPasswordClick = () => {
        setForgotError('');
        updatePopupMetData({primaryBtnText: 'Send Instructions', primaryBtn: {action: (userEmail) => onSendEmail(userEmail)}});
    }

    const onSendEmail = (userEmail) => {

        return new Promise(function (resolve, reject) {

            if(!validator.current.allValid()) {

                validator.current.showMessages();
                forceUpdate(1);
                resolve({error: true});
                return;
            } else{

                ForgotPasswordApi.forgotPassword({email: userEmail}).then(response => {
                    if(response.data.success === 1) {

                        const userId = response.data.data;
                        resolve({error: false, message: "Password Reset Instructions has been sent to your email!"});
                        history.push('/reset-password?userId=' + userId);
                    } else {
                        reject(response.data.message);
                        setForgotError(response.data.message);
                    }
                }).catch(err => {
                    reject(err);
                });
            }
        });
    }

    const handleChange = (event) => {

        if(event.target.name === "email") {
            setForgotError('');
            setEmail(event.target.value);
        }
    }

    const forgotPassword = (
        <a onClick={handleForgotPasswordClick} href="#forgotPassword">Forgot Password ?</a>
    );

    return (
        <Form onKeyPress={props.handleEnterKey}>
            <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>Email <span className="requiredStar">*</span></Form.Label>
                <Form.Control name="username" type="email" placeholder="Enter email" onChange={props.handleChange} />
                <Form.Text className="text-danger">
                    {props.validator.message('username', props.data.username, 'required|nullValidate')}
                </Form.Text>
            </Form.Group>

            <Form.Group className="form-group" controlId="formBasicPassword">
                <Form.Label>Password <span className="requiredStar">*</span></Form.Label>
                <div className="d-flex">
                    <Form.Control name="password" type={showPassword ? "text" : "password"} placeholder="Password" onChange={props.handleChange}/>
                    &nbsp;
                    <Button className="float-end border" variant="light" type="button" onClick={togglePassword}>
                        <FontAwesomeIcon icon={showPassword ? "eye" : "eye-slash"}/>
                    </Button>
                </div>
                <Form.Text className="text-danger">
                    {props.validator.message('password', props.data.password, 'required|nullValidate')}
                </Form.Text>
            </Form.Group>
            <Form.Group className="form-group">
                <Modal
                    size = "m"
                    title = "Forgot Password"
                    opener = {forgotPassword}
                    body = {
                        <ForgotPasswordForm
                            userEmail={email}
                            forgotError={forgotError}
                            validator={validator}
                            handleChange={handleChange}
                        />
                    }
                />
            </Form.Group>
            <Button variant="primary" type="button" onClick={props.handleClick} disabled={!props.data.username || !props.data.password}>Login</Button>
        </Form>
    )
}
export default connect(null,null)(SigninForm);