import React from 'react';
import BidsGrid from "../../../Grid/BidsGrid";

const BidHistory = (props) => {

    return (
        <div>
            <BidsGrid {...props}/>
        </div>
    );
}

export default BidHistory;