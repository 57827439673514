import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Modal from '../common/Popup';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import './AddBrands.scss';
import BrandsApi from '../../api/Brands';
import BootstrapDialog from '@mui/material/Dialog';

export default function CustomizedDialogs(props) {

    const { open, brandNames, onClose, onBrandAdded } = props;
    const [brandName, setBrandName] = useState('');
    const [brandDescription, setBrandDescription] = useState('');
    const [isNameExist, setIsNameExist] =  useState(false);
    const [issueInDescription, setIssueInDescription] =  useState(false);

    useEffect(() => {
        if (open) {
            setBrandName('');
            setBrandDescription('');
            setIsNameExist(false);
            setIssueInDescription(false);
        }
    }, [open]);

    const handleBrandNameChange = (e) => {
        setBrandName(e.target.value);
    };

    const handleBrandDescriptionChange = (e) => {
        setBrandDescription(e.target.value);
    };

    const handleGoTo = () => {
        if (brandName.trim() && brandDescription) {
            for (let i = 0; i < brandNames.length; i++) {
                if (brandNames[i].trim().toLowerCase() === brandName.trim().toLowerCase()) {
                    NotificationManager.error('Brand name already exists !');
                    setIsNameExist(true);
                    return;
                }
            }
            BrandsApi.createBrand({ brandName: brandName, description: brandDescription }).then(response => {
                NotificationManager.success('Brand added successfully!');
                onBrandAdded(brandName)
                onClose();
            }).catch(err => {
                NotificationManager.error('Something went wrong while fetching brands.');
                return;
            });
        } else {
            NotificationManager.error('Fill required fields!');
            setIsNameExist(true);
            setIssueInDescription(true);
        }
    };

    const isAddButtonDisabled = !brandName || !brandDescription;

    return (
        <div>
            <BootstrapDialog
                className="customized-dialog"
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <div className='top-row'>
                    <DialogTitle className="dialog-title" id="customized-dialog-title">
                        Add New Brand
                    </DialogTitle>
                    <IconButton
                        className="close-button"
                        aria-label="close"
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <DialogContent dividers>
                    <form>
                        <div>
                            <label htmlFor="brandName">
                                Brand Name
                                <span className='star'>*</span>
                            </label>
                        </div>
                        <div>
                            <input
                                className="text-box "
                                type="text"
                                id="brandName"
                                name="brandName"
                                onChange={handleBrandNameChange}
                                placeholder="ex :- Divolgo"
                                style={{ borderColor: isNameExist ? 'red' : 'black' }}
                            />
                        </div>
                        <div>
                            <label htmlFor="brandDescription">
                                Description
                                <span className='star'>*</span>
                            </label>
                        </div>
                        <div>
                            <input
                                className='text-box'
                                type="text"
                                id="brandDescription"
                                name="brandDescription"
                                onChange={handleBrandDescriptionChange}
                                placeholder="ex :- Sport advertising"
                                style={{ borderColor: issueInDescription ? 'red' : 'black' }}
                            />
                        </div>
                    </form>
                    <DialogContent dividers></DialogContent>
                    <DialogActions>
                        <Button
                            className='new_button'
                            onClick={handleGoTo}
                            disabled={isAddButtonDisabled}
                        >
                            Add
                        </Button>
                        {/* <Button className='new_button' onClick={onClose}>Close</Button> */}
                    </DialogActions>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}
