import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import PriceFormat from "../../../utils/PriceFormat";
import ReceiptAPI from "../../../api/Receipt";

const ReceiptListPage = (props) => {

    const [receipts, setReceipts] = useState([]);
    const [message, setMessage] = useState('');

     useEffect(() => {

         window.scrollTo(0, 0);
         ReceiptAPI.list().then(response => {

             const data = response.data.receipts || [];
             setReceipts(data);

             if(data.length === 0) {
                 setMessage("You don't have any previous receipts to display!");
             }

         }).catch(err => {
             console.warn(err);
         });
     },[]);

    return(
        <div className="order-list-container">
            <table className="main-cart-table table table-hover">
                <thead className="thead-light">
                <tr>
                    <th scope="col">RECEIPT#</th>
                    <th scope="col">DATE</th>
                    <th scope="col">STATUS</th>
                    <th scope="col">TYPE</th>
                    <th scope="col" className="text-end">RECEIPT TOTAL</th>
                    <th scope="col"/>
                </tr>
                </thead>

                <tbody>
                {receipts.map((receipt, key) => {
                    return <tr key={key}>
                        <td>{receipt.receiptNumber}</td>
                        <td>{receipt.createdAtFormatted}</td>
                        <td>{receipt.status.replace(/_/g, ' ')}</td>
                        <td>{receipt.type.replace(/_/g, ' ')}</td>
                        <td className="text-end"><PriceFormat price = {receipt.totalAmount}/></td>
                        <td  className="text-end">
                            <Link to={`/my-receipt/${receipt.id}`}>View</Link>
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
            <div style={{textAlign:'center'}}><b>{message}</b></div>
        </div>
    );
}

export default ReceiptListPage;