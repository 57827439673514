import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmationPopup = ({ show, onClose, onConfirm }) => {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Removal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to remove this brand?</p>
      </Modal.Body>
      <Modal.Footer>
      <Button className='new_button' onClick={onConfirm}>
          Confirm
        </Button>
        <Button className='new_button' onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationPopup;

