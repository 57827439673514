import React from 'react';
import Grid from './Grid';
import {convertToCurrentTimeZone} from "../utils/time";
import AccountSettings from "../api/AccountSettings";
import PriceFormat from "../utils/PriceFormat";

class BidsGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [],
            rows: [],
            brandName: ''
        }
    }

    componentDidMount() {
        AccountSettings.getCustomerData().then(response => {
            this.setState({brandName: response.data.brand.brandName});
        }).catch(err => {
            console.error(err);
        });
        this.loadGrid();
    }

    maskBrandName(brandName) {
        if(brandName === this.props.brandName) {
            return brandName;
        } else {
            let mask = '*';
            for (var i = 0; i < (brandName).length - 3 ; i++) {
                mask = mask + '*';
            }
            return ((brandName).substring(0,1) + mask + (brandName).substring(brandName.length - 1));
        }
    }

    loadGrid () {
        const columns = [
            { headerName: 'Winning Position', field: 'position', sortable: true, cellStyle: { textAlign: 'center' }},
            { headerName: 'Brand Name', field: 'brand', sortable: true,cellStyle: { textAlign: 'left' },tooltipField:"brand" },
            { headerName: 'Bid Amount (USD)', field: 'value', sortable: true, type: 'numericColumn', cellRendererFramework: this.renderBidValue},
            { headerName: 'Created At', field: 'time', sortable: true, cellStyle: { textAlign: 'center' }}
        ]
        const rows = [];

        this.props.bids.map((bid) => {
            rows.push({
            brand: this.maskBrandName(bid.customer.brand.brandName),
            value: bid.bidValue,
            position: (bid.winningPosition > 0 ? bid.winningPosition : (bid.statusLabel === "Highest Bid" ? 1 : '' )),
            time: convertToCurrentTimeZone(bid.createdAt)
        })
        });
        this.setState({columns, rows});
    }

    renderBidValue = (params) => {
        return <PriceFormat price = {params.data.value}/>;
    }

    onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
    }

    render() {
        return(
            <>
                <div
                    className="ag-theme-alpine"
                    style={{
                        height: 'auto',
                        width: 'auto'
                    }}
                >
                    <Grid
                        suppressDragLeaveHidesColumns={true}
                        columnDefs={this.state.columns}
                        rowData={this.state.rows}
                        onFirstDataRendered={this.onFirstDataRendered}
                        pagination
                    />
                </div>
            </>
        );
    }
}

export default BidsGrid;