import * as types from './ActionTypes';
import ProductApi from '../api/Product';
import {inertServerErrorToStore} from './common/ErrorActions';

export const list = (data) => {
    return (dispatch) => {
        return ProductApi.list(data).then(response => {
            if (response.data) {
                let productList = response.data;
                dispatch(success(productList));
            }
        }).catch(err => {
            dispatch(inertServerErrorToStore(err));
        });
    };

    function success(productList) {
        return {type: types.GET_PRODUCT_LIST, productList}
    }
}

export const patch = (products, updatedProduct) => {
    return (dispatch) => {
        const newList = products.content.map(p => p.id !== updatedProduct.id ? p : updatedProduct);
        const productList = {...products, content: newList};
        return dispatch({type: types.PATCH_PRODUCT_LIST, productList});
    }
}