import React, {useEffect, useState} from 'react';
import './Common.scss';
import FAQApi from "../../api/FAQ";
import {Col, Container, Form, Row} from "react-bootstrap";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormTitle from "../common/FormTitle";

const FAQPage = (props) => {

    const [fAQs, setFAQs] = useState([]);
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        FAQApi.list().then(response => {
            const data = response.data.data;
            if(data) {
                setFAQs(data);
            }
        }).catch(err => {
            console.log(err);
        });
    },[]);

    return (
        <Form>
            <FormTitle>Frequently Asked Questions</FormTitle>
            <div>
                <span>&nbsp;</span>
            </div>
            <Container>
                {fAQs.map((faq, key) => {
                    return (
                        <div style={{paddingTop:"5px", paddingBottom:"10px"}}>
                            <Row>
                                <Col>
                                    <Accordion className="webstore-accordion" expanded={expanded === 'panel' + key} onChange={handleChange('panel' + key)}>
                                        <AccordionSummary
                                            className="accordion-heading"
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={"panel" + key + "bh-content"}
                                            id={"panel" + key +"bh-header"}
                                        >
                                            <Typography sx={{ width: '100%', flexShrink: 0 }}>
                                                <span className="accordion-heading-text">{faq.question}</span>
                                            </Typography>
                                            <Typography sx={{color: 'text.secondary'}}/>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                {faq.answer}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </Col>
                            </Row>
                        </div>
                    );
                })}
            </Container>
        </Form>
    );
}

export default FAQPage;

