import * as types from '../ActionTypes';

export const  setPopupMetaData = (metadata) => {
    return {type: types.SET_POPUP_MEATADATA, metadata};
}

export const primaryAction = (data) => {
    return {type: types.POPUP_PRIMARY_ACTION, data};
}

export const toggleShowHide = (data) => {
    return {type: types.POPUP_TOGGLE_SHOWHIDE, data};

}