import React, {useState, useEffect} from "react";
import {Row, Col, Badge, FormGroup} from "reactstrap";
import {Redirect, useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {addToCart} from "../../actions/CartActions";
import {list, patch} from "../../actions/ProductActions";
import {clearProductFilters, productPagination} from "../../actions/common/FilterActions";
import BlockUi from "../common/BlockUi";
import ProductCards from "./productPage/ProductCard";
import Paginator from "../common/Paginator";
import Websocket from "../../utils/Websocket";
import "./ProductsPage.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ProductsPage = (props) => {

    const {data, products} = props;
    const history = useHistory();
    const [redirect, setRedirect] = useState("");
    const [isLoading, setIsLoading] = useState("");
    const [keyword, setKeyword] = useState([]);
    const [latsPagination, setLastPagination] = useState(null);
    const [lastFilters, setLastFilters] = useState(null);

    useEffect(() => {
        let options = {...props.filters, ...props.pagination};

        if (
            JSON.stringify(latsPagination) == JSON.stringify(props.pagination) &&
            JSON.stringify(lastFilters) == JSON.stringify(props.filters)
        ) {
            return;
        }

        setIsLoading(true);
        setLastPagination(props.pagination);
        setLastFilters(props.filters);

        if (lastFilters == null && latsPagination == null) {
            return;
        }

        props.getProductList(options);
    }, [props.filters, props.pagination]);

    useEffect(() => {
      window.scrollTo(0, 0);
      setIsLoading(false);
    }, [props.products]);

    const handleClickAddToCart = (product) => {
        if (props.user.isLoggedIn) {
            props.addToCart(product);
        } else {
            history.push("/login");
        }
    };

    const showDetailPage = (productId) => {
        setRedirect("/product/" + productId);
    };

    const handleChangePage = (event, page) => {
        props.setPagination({page});
    };

    const patchProductList = (updatedProduct) => {
        props.patchProductList(data, updatedProduct);
    };

    const hasFilteringApplied = () => {
        return Object.values(props.filters).some((x) => x != null && x != "" && x != 0);
    };

    const handleClearFilters = () => {
        props.cleaFilters();
        setKeyword("");
    };

    const renderSearchFilter = (child) => {
        return (
            <Col xs="12" className="filter-item-wrap">
                <FormGroup>{child}</FormGroup>
            </Col>
        );
    };

    return (
        <div>

            {hasFilteringApplied() &&
            renderSearchFilter(
                <h5>
                    <Badge
                        onClick={handleClearFilters}
                        className="btn-clear-filters"
                        color="secondary"
                        pill
                    >
                        <FontAwesomeIcon icon="times-circle"/>
                        &nbsp;&nbsp;Clear Search
                    </Badge>
                    <br/>
                    <br/>
                    Search results for '{props.filters.keyword}'
                </h5>
            )}

            {redirect && <Redirect to={redirect}/>}

            <Websocket onReceiveMessage={patchProductList}/>

            <BlockUi className="" tag="div" blocking={isLoading} message={"Loading"}>
                <Row>
                    {products &&
                    products.map((product, key) => {
                        return (
                            <ProductCards
                                key={key}
                                // size = '3'
                                className="no-right-padding"
                                title={product.name}
                                description={product.description}
                                handleClickAddToCart={handleClickAddToCart}
                                handleClickImage={showDetailPage}
                                product={product}
                                userStatus={props.userStatus}
                            />
                        );
                    })}

                    {data && data.totalElements == 0 && (
                        <Col md="12" className="text-center">
                            <h6>There are no Products.</h6>
                        </Col>
                    )}
                </Row>
            </BlockUi>

            {data.totalPages > 0 && (
                <Row className="toolbar row">
                    <Col md={{size: "auto", offset: 3}} xs="12">
                        <Paginator
                            page={props.pagination.page}
                            pageCount={data.totalPages}
                            onChange={handleChangePage}
                        />
                    </Col>
                </Row>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        items: state.items,
        filters: state.filters,
        pagination: state.pagination,
        data: state.products,
        products: state.products.content,
        userStatus: state.UserReducer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        cleaFilters: () => {
            dispatch(clearProductFilters());
        },
        getProductList: (filters) => {
            dispatch(list(filters));
        },
        patchProductList: (d, updatedProduct) => {
            dispatch(patch(d, updatedProduct));
        },
        addToCart: (product) => {
            dispatch(addToCart(product));
        },
        setPagination: (options) => {
            dispatch(productPagination(options));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage);
