import initialState from './InitialState';
import * as types from '../../actions/ActionTypes';

const ProductReducer =  (state = initialState.productList, action) => {
    switch (action.type) {
        case types.GET_PRODUCT_LIST:
            return {...state, ...action.productList};

        case types.PATCH_PRODUCT_LIST:
            return {...state, ...action.productList};

        default:
            return state;
    }
}

export default ProductReducer;