import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {Button, Table} from 'reactstrap';
import {changeQty, checkout, checkoutError, checkoutSuccess, removeItem} from '../../../actions/CartActions';
import {setPopupMetaData} from '../../../actions/common/PopupActions';
import AccountSettingsApi from '../../../api/AccountSettings';
import PaypalAPI from '../../../api/Paypal';
import Common from '../../../api/Common';
import infoIcon from '../../../assets/icons/info.png';
import PriceFormat from '../../../utils/PriceFormat';
import BlockUi from '../../common/BlockUi';
import PaypalButton from '../../common/PaypalButton';
import Modal from '../../common/Popup';
import CartItem from './components/CartItem';
import './CartPage.scss';
import {NotificationManager} from 'react-notifications';
import * as Constants from '../../../constants/RouteConstants';

const CartPage = ({
                      cart,
                      userStatus,
                      setPopupMetaData,
                      checkout,
                      removeItem,
                      changeQty,
                      checkoutSuccess,
                      checkoutError
                  }) => {

    const [dueDate, setDueDate] = useState('');
    const [paymentTermId, setPaymentTermId] = useState(undefined);
    const [initialOptions, setInitialOptions] = useState({});
    const [isPayPal, setIsPayPal] = useState(userStatus.paymentMethod === 'PAYPAL');
    const [isCheckoutSuccess, setCheckoutSuccess] = useState(!!(cart.checkout && cart.checkout.success === 1));
    const [isLoading, setLoading] = useState(true);
    const [items, setItems] = useState(cart.items || []);
    const [isCartEmpty, setCartEmpty] = useState(cart.count <= 0);
    const [paypalError, setPaypalError] = useState('');
    const [error, setError] = useState(false);
    const [addressStatus, setAddressStatus] = useState(1);
    const [paymentTermStatus, setPaymentTermStatus] = useState(true);
    const [orderInfo, setOrderInfo] = useState({
        orderIdFormatted: cart.checkout.orderIdFormatted,
        orderId: cart.checkout.orderId
    });
    const history = useHistory();

    const handleChangeQty = (itemId, qty, defaultImageId) => {
        if (qty < 1) {
            removeItem(itemId, defaultImageId);
        } else {
            changeQty(itemId, qty, defaultImageId);
        }
    };

    const handleRemoveItem = (itemId, defaultImageId) => {
        removeItem(itemId, defaultImageId);
    };

    const handleClickContinue = () => {
        history.push(Constants.ROUTE_PRODUCTS);
    };

    useEffect(() => {
        setLoading(true);

        setPopupMetaData({
            primaryBtnText: 'Yes',
            secondaryBtnText: 'No',
            primaryBtn: {action: () => handleCheckout(cart)}
        });

        setItems(cart.items);

        setCartEmpty(cart.count <= 0);

        const renderDueDate = () => {
            if (userStatus.paymentMethod !== 'PAYPAL') {
                AccountSettingsApi.getCustomerData()
                    .then((response) => {
                        const paymentTermId = response.data.paymentTermId;

                        Common.paymentTerms()
                            .then((res) => {
                                const paymentTerms = res.data;
                                paymentTerms.forEach((item) => {
                                    if (item.id === paymentTermId) {
                                        const date = new Date();
                                        date.setDate(date.getDate() + item.noOfDays);
                                        const newDate = window.moment(date).format('DD/MM/YYYY').toString();
                                        setDueDate(newDate);
                                    }
                                });
                                setLoading(false);
                            })
                            .catch((err) => {
                                console.log(err);
                                setError(true);
                                setLoading(false);
                            });
                        setPaymentTermId(paymentTermId);
                    })
                    .catch((err) => {
                        console.log(err);
                        setError(true);
                        setLoading(false);
                    });
            } else {
                setLoading(false);
            }
        };
        renderDueDate();
    }, [cart, checkout, checkoutSuccess]);

    useEffect(() => {
        setLoading(true);

        if (userStatus.paymentMethod) {
            setIsPayPal(userStatus.paymentMethod === 'PAYPAL');
        }

        const fetchPaypalInfo = () => {
            PaypalAPI.getPaypalAccountDetails()
                .then((response) => {
                    const jsonData = response.data.data;
                    setInitialOptions({
                        'client-id': jsonData.clientId,
                        currency: jsonData.currency,
                        intent: jsonData.intent,
                        'data-client-token': jsonData.clientToken
                    });
                    setLoading(false);
                })
                .catch((err) => {
                    setPaypalError('Cannot display the payment options, Please contact the administrator!');
                    setLoading(false);
                });
        };

        if (userStatus.paymentMethod === 'PAYPAL') {
            fetchPaypalInfo();
        }
    }, [userStatus.paymentMethod]);

    useEffect(() => {
        setLoading(true);
        if (cart.checkout.orderId !== '') {
            setOrderInfo({
                orderIdFormatted: cart.checkout.orderIdFormatted,
                orderId: cart.checkout.orderId
            });
        }
        setLoading(false);
    }, [cart.checkout]);

    useEffect(() => {
        if (userStatus.addressStatus) {
            setAddressStatus(userStatus.addressStatus);
        }
        if (userStatus.paymentMethod) {
            if (userStatus.paymentMethod === 'PAYPAL') {
                setPaymentTermStatus(true);
            } else if (paymentTermId !== undefined) {
                setPaymentTermStatus((paymentTermId > 0));
            }
        }
    },[userStatus, paymentTermId]);

    //console.log(paymentTermStatus);

    const handleCheckout = (cartData) => {
        setLoading(true);
        checkout(cartData);
        setCheckoutSuccess(true);
        setLoading(false);
    }

    const handlePayPalError = (error) => {
        if (error.status) {
            if (error.status === 400) {
                setIsPayPal(false);
            } else if (error.status === 406) {
                checkoutError(error.data);
            }
            NotificationManager.error(error.data.message);
        }
    }

    const paypalCheckout = (data) => {

        setLoading(true);

        const items = cart.items || [];
        let total = 0.0;

        const orderItems = items.map((item) => {
            const unitPrice = item.cartQty * item.price;
            total = total + unitPrice;
            return ({
                productId: item.id,
                qty: item.cartQty,
                productPrice: parseFloat(unitPrice),
                brandImageId: item.defaultImageId,
                bidId: null,
                imageColour: item.imageColour,
                colourInverted: item.colourInverted
            });
        });

        const body = {
            cartData: {
                cartItems: orderItems,
                total: total
            },
            paypalData: {
                paypalOrderId: data.paypalOrderId,
                payerId: data.payerId,
                paymentId: data.paymentId,
            }
        };

        PaypalAPI.createOrder(body).then((response) => {
            const data = response.data;
            if (data.success === 1) {
                setOrderInfo({orderIdFormatted: data.data.orderIdFormatted, orderId: data.data.id});
                checkoutSuccess(data);
                setCheckoutSuccess(true);
            } else {
                checkoutError(data);
                NotificationManager.error(data.message);
            }
            setLoading(false);
        });
    };

    const RenderErrorButtons = (message) => {
        return (
            <div className="paypal-error-container">
                <h5 className="text-danger paypal-error-text">{message}</h5>
            </div>
        );
    }

    return (
        <BlockUi className='block-ui' tag='div' blocking={isLoading} message='loading'>

            {!isCheckoutSuccess && isCartEmpty && <p className='text-center'>Cart is empty.</p>}

            {isCartEmpty && isCheckoutSuccess && (
                <div className='alert-success'>
                    <div className='alert checkout-success' role='alert'>
                        <h4 className='alert-heading'>Thank you for your order.</h4>
                        <hr/>
                        <div>
                            Your order has been placed successfully with Divolgo.
                            <br/>
                            <p>
                                {userStatus.paymentMethod === 'PAYPAL' &&
                                    <span>
                                        Your PayPal payment is processing, and it will take few minutes to
                                        complete.&nbsp;
                                    </span>
                                }
                                Your order number is :{' '}
                                <span className='font-weight-bold'>
                                    {orderInfo.orderIdFormatted}
                                </span>
                            </p>
                            <p>
                                <Link className='btn btn-primary' to={'/my-order/' + orderInfo.orderId}>
                                    View Your Order
                                </Link>
                                <Link className='btn btn-primary float-end' to={Constants.ROUTE_PRODUCTS}>
                                    Continue Shopping
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            )}

            {!isCartEmpty && (

                <div className='main-cart-container'>
                    {(!isLoading && !error && (addressStatus !== 1)) && (
                        <div>
                            <h4 className='alert-heading text-danger'
                                style={{textAlign: 'center'}}>
                                Please add your business information before checkout!
                            </h4>
                            <br/>
                        </div>
                    )}
                    {(!isLoading && !error && paymentTermStatus === false) && (
                        <div>
                            <h4 className='alert-heading text-danger'
                                style={{textAlign: 'center'}}>
                                Please contact an admin and add your payment term before checkout!
                            </h4>
                            <br/>
                        </div>
                    )}
                    {error && (
                        <div>
                            <h4 className='alert-heading text-danger'
                                style={{textAlign: 'center'}}>
                                Some thing went wrong. Please contact an administrator.
                            </h4>
                            <br/>
                        </div>
                    )}

                    <table className='main-cart-table table table-hover'>
                        <thead className='thead-light'>
                        <tr>
                            <th scope='col' width='10%'/>
                            <th scope='col' width='40%'>
                                PRODUCT
                            </th>
                            <th scope='col' width='15%' className='text-end'>
                                BRAND IMAGE
                            </th>
                            <th scope='col' width='10%' className='text-end'>
                                UNIT PRICE
                            </th>
                            <th scope='col' width='10%' className='text-center'>
                                QTY
                            </th>
                            <th scope='col' width='10%' className='text-end'>
                                SUBTOTAL
                            </th>
                            <th scope='col' width='5%'/>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item, key) => {
                            return (
                                <CartItem
                                    key={key}
                                    handleChangeQty={handleChangeQty}
                                    handleRemoveItem={handleRemoveItem}
                                    {...item}
                                />
                            );
                        })}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colSpan='4' className='text-end'>
                                <h4>Grand Total</h4>
                            </td>
                            <td colSpan='2' className='text-end'>
                                <h4>
                                    <PriceFormat price={cart.total}/>
                                </h4>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='6'>
                                <div className='bottom-buttons'>
                                    <div className='cart-button'>
                                        <Button onClick={handleClickContinue}>
                                            Continue Shopping
                                        </Button>
                                    </div>
                                    <div className='paypal-card-buttons'>
                                        {isPayPal ? (
                                            paypalError !== '' ? (
                                                RenderErrorButtons(paypalError)
                                            ) : (
                                                Object.keys(initialOptions).length === 0 &&
                                                initialOptions.constructor === Object ? (
                                                    <div className='payment-buttons'>
                                                        <div className='loader' color='primary' disabled>
                                                            {navigator.onLine ? (
                                                                <>
                                                                    <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'/>
                                                                    <span style={{marginLeft: '1em'}}>Loading...</span>
                                                                </>
                                                            ) : (
                                                                <span style={{marginLeft: '1em', color: 'red'}}>Please check your internet connection!</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <PaypalButton
                                                        initialOptions={initialOptions}
                                                        paypalCheckout={paypalCheckout}
                                                        handlePayPalError={handlePayPalError}
                                                        setIsLoading={setLoading}
                                                    />
                                                )
                                            )
                                        ) : ((addressStatus === 1 && paymentTermStatus && !error) &&
                                            <Modal
                                                size='m'
                                                title='Confirm Checkout'
                                                centered={true}
                                                backdrop='static'
                                                opener={
                                                    <div className='cart-button proceed-button'>
                                                        <Button
                                                            color='primary'
                                                        >
                                                            Proceed to Checkout
                                                        </Button>
                                                    </div>
                                                }
                                                body={
                                                    <Table borderless>
                                                        <tbody>
                                                        <tr>
                                                            <td>
                                                                <img
                                                                    src={infoIcon}
                                                                    width='20px'
                                                                    alt='warning icon'
                                                                />
                                                            </td>
                                                            <td>
                                                                {userStatus.paymentMethod ===
                                                                'PAYPAL' ? (
                                                                    <h6>
                                                                        You are about to
                                                                        complete the checkout.
                                                                        The total amount of{' '}
                                                                        <PriceFormat
                                                                            price={
                                                                                cart.total
                                                                            }
                                                                        />{' '}
                                                                        will be deducted
                                                                        immediately from your
                                                                        Paypal account
                                                                        automatically.
                                                                    </h6>
                                                                ) : (
                                                                    <h6>
                                                                        You are about to
                                                                        complete the checkout.
                                                                        The invoice for the
                                                                        total amount of{' '}
                                                                        <PriceFormat
                                                                            price={
                                                                                cart.total
                                                                            }
                                                                        />{' '}
                                                                        will be sent to your
                                                                        registered email
                                                                        address. Your payment is
                                                                        due on or
                                                                        before {dueDate}.
                                                                    </h6>
                                                                )}
                                                                <br/>
                                                                <h6>
                                                                    Are you sure you want to
                                                                    continue with the
                                                                    checkout?
                                                                </h6>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </Table>
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            )}
        </BlockUi>
    );
};

const mapStateToProps = (state) => {
    return {
        cart: state.cart,
        userStatus: state.UserReducer.settings
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPopupMetaData: (metadata) => {
            dispatch(setPopupMetaData(metadata));
        },
        removeItem: (itemId, defaultImageId) => {
            dispatch(removeItem(itemId, defaultImageId));
        },
        changeQty: (itemId, qty, defaultImageId) => {
            dispatch(changeQty(itemId, qty, defaultImageId));
        },
        checkout: (cart) => {
            dispatch(checkout(cart));
        },
        checkoutSuccess: (cartData) => {
            dispatch(checkoutSuccess(cartData));
        },
        checkoutError: (cartData) => {
            dispatch(checkoutError(cartData));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CartPage);